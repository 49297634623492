/**
 * @file documentAnnotatorDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 22, 2022 11:27
 */

// -------------------------------------------------------------------
// document.setters - to setup for annotatorEditor
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_SET = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_SET';
export function annotationGroupSet(annotationGroup) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_SET,
        payload: {
            annotationGroup,
        },
    };
}

export const DOCUMENT_ANNOTATOR_BIOCURATIONS_SET = 'DOCUMENT_ANNOTATOR_BIOCURATIONS_SET';
export function biocurationsSet(biocurations) {
    return {
        type: DOCUMENT_ANNOTATOR_BIOCURATIONS_SET,
        payload: {
            biocurations,
        },
    };
}

// -------------------------------------------------------------------
// editAnnotation
// -------------------------------------------------------------------
export const ANNOTATION_EDIT_OPEN_DIALOG_SET = 'ANNOTATION_EDIT_OPEN_DIALOG_SET';
export function annotationEditOpenDialogSet(value) {
    return {
        type: ANNOTATION_EDIT_OPEN_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// annotatePublication
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATE_PUBLICATION_REQUEST = 'DOCUMENT_ANNOTATE_PUBLICATION_REQUEST';
export function publicationAnnotateRequest(annotatorId, pubId, navigate) {
    return {
        type: DOCUMENT_ANNOTATE_PUBLICATION_REQUEST,
        payload: {
            annotatorId,
            pubId,
            navigate,
        },
    };
}

export const DOCUMENT_ANNOTATE_PUBLICATION_SUCCESS = 'DOCUMENT_ANNOTATE_PUBLICATION_SUCCESS';
export function publicationAnnotateSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATE_PUBLICATION_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATE_PUBLICATION_FAILURE = 'DOCUMENT_ANNOTATE_PUBLICATION_FAILURE';
export function publicationAnnotateFailure(error) {
    return {
        type: DOCUMENT_ANNOTATE_PUBLICATION_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotateSupplement
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST = 'DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST';
export function supplementAnnotateRequest(annotatorId, supId, navigate) {
    return {
        type: DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST,
        payload: {
            annotatorId,
            supId,
            navigate,
        },
    };
}

export const DOCUMENT_ANNOTATE_SUPPLEMENT_SUCCESS = 'DOCUMENT_ANNOTATE_SUPPLEMENT_SUCCESS';
export function supplementAnnotateSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATE_SUPPLEMENT_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATE_SUPPLEMENT_FAILURE = 'DOCUMENT_ANNOTATE_SUPPLEMENT_FAILURE';
export function supplementAnnotateFailure(error) {
    return {
        type: DOCUMENT_ANNOTATE_SUPPLEMENT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation - current/new annotation
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_SET = 'DOCUMENT_ANNOTATOR_ANNOTATION_SET';
export function annotationSet(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_SET,
        payload: {
            annotation,
        },
    };
}

// -------------------------------------------------------------------
// document.annotationGroup.delete
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_REQUEST';
export function annotationGroupDeleteRequest(annotationGroupId) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_REQUEST,
        payload: annotationGroupId,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_SUCCESS';
export function annotationGroupDeleteSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_FAILURE';
export function annotationGroupDeleteFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotation.annotationFind - finds single annotation for editing
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_FIND_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_FIND_REQUEST';
export function annotationFindRequest(annotationId) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_FIND_REQUEST,
        payload: annotationId,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_FIND_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_FIND_SUCCESS';
export function annotationFindSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_FIND_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_FIND_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_FIND_FAILURE';
export function annotationFindFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotationGroup.find
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_REQUEST';
export function annotationGroupFindRequest(annotationGroupId) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_REQUEST,
        payload: annotationGroupId,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_SUCCESS';
export function annotationGroupFindSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_FAILURE';
export function annotationGroupFindFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}


// -------------------------------------------------------------------
// document.annotationGroup.new
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_REQUEST';
export function annotationGroupNewRequest(annotationGroupId, curatedText, pmId, pubId, parentDocType, parentId) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_REQUEST,
        payload: {
            annotationGroupId,
            curatedText,
            pmId,
            pubId,
            parentDocType,
            parentId,
        },
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_SUCCESS';
export function annotationGroupNewSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_FAILURE';
export function annotationGroupNewFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotationGroup.reset
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_RESET = 'DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_RESET';
export function annotationGroupReset() {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_RESET,
    };
}

// -------------------------------------------------------------------
// annotation.updateField - updates redux from local state onBlur
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_UPDATE_FIELD = 'DOCUMENT_ANNOTATOR_ANNOTATION_UPDATE_FIELD';
export function annotationFieldUpdate(annotationId, fieldName, fieldValue) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_UPDATE_FIELD,
        payload: {
            annotationId,
            fieldName,
            fieldValue
        }
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_OBJ_STATE_UPDATE = 'DOCUMENT_ANNOTATOR_ANNOTATION_OBJ_STATE_UPDATE';
export function annotationObjStateUpdate(annotationObj) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_OBJ_STATE_UPDATE,
        payload: annotationObj,
    };
}


// -------------------------------------------------------------------
// annotation.delete
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_REQUEST';
export function annotationDeleteRequest(annotationGroup, annotationId) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_REQUEST,
        payload: {
            annotationGroup,
            annotationId,
        },
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_SUCCESS';
export function annotationDeleteSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_FAILURE';
export function annotationDeleteFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.new
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATION_NEW_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATION_NEW_REQUEST';
export function annotationNewRequest(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_NEW_REQUEST,
        payload: {
            annotation,
        },
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_NEW_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATION_NEW_SUCCESS';
export function annotationNewSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_NEW_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATION_NEW_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATION_NEW_FAILURE';
export function annotationNewFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATION_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.submitGeneSymbol
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_REQUEST = 'DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_REQUEST';
export function geneSymbolSubmitRequest(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_REQUEST,
        payload: annotation,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_SUCCESS = 'DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_SUCCESS';
export function geneSymbolSubmitSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_FAILURE = 'DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_FAILURE';
export function geneSymbolSubmitFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.submitGeneDisease
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_REQUEST = 'DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_REQUEST';
export function diseaseSubmitRequest(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_REQUEST,
        payload: annotation,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_SUCCESS = 'DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_SUCCESS';
export function diseaseSubmitSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_FAILURE = 'DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_FAILURE';
export function diseaseSubmitFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.submitAnnotationType
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST = 'DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST';
export function annotationTypeSubmitRequest(annotationId, annotationType) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST,
        payload: {
            annotationId,
            annotationType,
        }
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS = 'DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS';
export function annotationTypeSubmitSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE = 'DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE';
export function annotationTypeSubmitFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.submitClassificationCode
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST = 'DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST';
export function classificationCodeSubmitRequest(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST,
        payload: annotation,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS = 'DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS';
export function classificationCodeSubmitSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE = 'DOCUMENT_ANNOTATOR_CLASSIFICATION_CODE_DISEASE_FAILURE';
export function classificationCodeSubmitFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.save
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_REQUEST = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_REQUEST';
export function annotationSaveRequest(annotation, notify = false) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_REQUEST,
        payload: {
            annotation,
            notify,
        }
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_SUCCESS = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_SUCCESS';
export function annotationSaveSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_FAILURE = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_FAILURE';
export function annotationSaveFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.saveAnnotationJson
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_REQUEST = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_REQUEST';
export function annotationJsonSaveRequest(annotationJson) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_REQUEST,
        payload: annotationJson,
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_SUCCESS = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_SUCCESS';
export function annotationJsonSaveSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_FAILURE = 'DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_FAILURE';
export function annotationJsonSaveFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotations.save - saves an array of revised annotations
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST = 'DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST';
export function annotationsSaveRequest(annotations, notify = false) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST,
        payload: {
            annotations,
            notify,
        }
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_SUCCESS = 'DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_SUCCESS';
export function annotationsSaveSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_FAILURE = 'DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_FAILURE';
export function annotationsSaveFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.variantObj.state.update - updates local state
// to redux state
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_VARIANT_OBJ_STATE_UPDATE = 'DOCUMENT_ANNOTATOR_VARIANT_OBJ_STATE_UPDATE';
export function variantObjStateUpdate(variantObj, annotationId) {
    return {
        type: DOCUMENT_ANNOTATOR_VARIANT_OBJ_STATE_UPDATE,
        payload: {
            variantObj,
            annotationId,
        }
    };
}

// -------------------------------------------------------------------
// document.annotation.variantObjDelete 
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_REQUEST = 'DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_REQUEST';
export function variantObjDeleteRequest(annotationId, variantObjId) {
    return {
        type: DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_REQUEST,
        payload: {
            annotationId,
            variantObjId,
        },
    };
}

export const DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_SUCCESS = 'DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_SUCCESS';
export function variantObjDeleteSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_FAILURE = 'DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_FAILURE';
export function variantObjDeleteFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.newVariantObj
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_REQUEST = 'DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_REQUEST';
export function variantObjNewRequest(annotation) {
    return {
        type: DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_REQUEST,
        payload: {
            annotation,
        },
    };
}

export const DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_SUCCESS = 'DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_SUCCESS';
export function variantObjNewSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_FAILURE = 'DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_FAILURE';
export function variantObjNewFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.annotation.saveVariantObj [deprecated?]
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_REQUEST = 'DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_REQUEST';
export function variantObjSaveRequest(annotation, variantObj) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_REQUEST,
        payload: {
            annotation,
            variantObj,
        },
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_SUCCESS = 'DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_SUCCESS';
export function variantObjSaveSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_FAILURE = 'DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_FAILURE';
export function variantObjSaveFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantObj.variantRefFind - returns reference variant used to
//            provide a gDot and other elements for a variantObj
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST = 'DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST';
export function variantValidatorFindRequest(cDot, genomeBuild, transcript, annotationId, variantId) {
    return {
        type: DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST,
        payload: {
            cDot,
            genomeBuild,
            transcript,
            annotationId,
            variantId,
        },
    };
}

export const DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_SUCCESS = 'DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_SUCCESS';
export function variantValidatorFindSuccess(payload) {
    return {
        type: DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_SUCCESS,
        payload,
    };
}

export const DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_FAILURE = 'DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_FAILURE';
export function variantValidatorFindFailure(error) {
    return {
        type: DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}