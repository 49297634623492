/**
 * @file: asserts.js
 * @description: asserts if a passed parameter is null,
 *               undefined, and checks if it is of the proper type.
 *               asserts parameters.
 *               asserts that a parameter is a function.
 *
 * @copyright 2018 veriome labs, llc - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package
 *
 * https://npm.io/package/dash-assert
 * 
 * @author: don michael
 * @since: Wednesday, 19th December 2018 1:17:22 pm
 */
import _ from 'lodash';

/**
 * identifies the type of an object
 * returns type of complex objects in proper case:
 *      Employee - custom Employee object with a constructor
 *      Array
 *      Object - any javascript object
 *
 * returns primitive types in lower case:
 *      string
 *      boolean
 *      number
 * @param {*} obj
 */
export function getObjType(obj) {
    let objType = null;

    if (obj !== null) {
        objType = typeof obj;

        if (objType === 'object') {
            if (obj.constructor) {
                if (obj.constructor.name) {
                    // special case for mongoDb guids, to use camel-case
                    if (objType === 'ObjectID') {
                        objType = 'ObjectId';
                    } else {
                        objType = obj.constructor.name;
                    }
                }
            }
        }
    }

    return objType;
}

/**
 * asserts a parameter
 * @param {} name the name of the object
 * @param {*} obj the actual object instance
 * @param {*} requiredType - optional required type to match
 */
export function assertParam(name, obj, requiredType) {

    if (_.isNil(obj)) {
        const newError = new Error(
            `argument: ${name} must be defined.`
        );
        throw newError;
    }

    if (requiredType) {
        // check a specific type
        const objType = getObjType(obj);

        if (objType !== requiredType) {
            // / console.error(`incorrect type => name: ${name} is type: ${objType}. requiredType: ${requiredType} value: ${obj}`);
            const newError = new Error(
                `argument: ${name} is type: ${objType}. requiredType: ${requiredType}. ${new Error().stack}`
            );
            throw newError;
        }

    }
}

/**
 * asserts a function
 * @param {} name
 * @param {*} obj
 */
export function assertFunc(name, obj) {
    if (_.isNil(obj)) {
        const newError = new Error(
            `argument: ${name} must be defined.`
        );
        throw newError;
    }

    if (!_.isFunction(obj)) {
        const newError = new Error(
            `argument: ${name} must be a function.`
        );
        throw newError;
    }
}