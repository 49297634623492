import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import { getToken } from 'src/auth/authUtilities';
import { stringExists } from 'src/@common/utilities';

import {
    TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST,
    generateAnnotationsSuccess,
    generateAnnotationsFailure,
} from 'src/store/actions/tableAnnotator/tableAnnotatorDb.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/tableAnnotator/generateAnnotations';


    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            annotationType: payload.annotationType,
            classificationCode: payload.classificationCode,
            dataRows: payload.dataRows,
            fieldsRow: payload.fieldsRow,
            globalFields: payload.globalFields,
            groupingMethod: payload.groupingMethod,
            groupsRow: payload.groupsRow,
            tableId: payload.tableId,
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* createRequestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            if (stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.generalInfoMessage.options)
                );
            }

            yield put(generateAnnotationsSuccess(resp.payload));

        } catch (error) {
            yield put(generateAnnotationsFailure(error));
            handleSagaErrors(error);
        } finally {
            yield put(dataLoadingSet(false));
        }
    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* createRequestMonitor() {
    yield takeLatest(TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST, createRequestSaga);
}
