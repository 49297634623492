/**
 * @file RenderFields.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 01, 2022 16:36
 */
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';

import { arrayExists, objectExists } from 'src/@common/utilities';

import ListItemField from 'src/components/fieldComponents/VeriomeListItemField';
import RenderVariantObjFields from './RenderVariantObjFields';

// utility function
function RenderVariantObjArray(props) {
    const {
        variantObjArray,
    } = props;

    return (
        <>
            {
                arrayExists(variantObjArray) &&
                variantObjArray.map((variantObj, index) => {
                    return <RenderVariantObjFields
                        key={index}
                        index={index}
                        variantObj={variantObj}
                    />
                })
            }
        </>
    );
}

RenderVariantObjArray.propTypes = {
    variantObjArray: PropTypes.array.isRequired,
};

function RenderFields(props) {
    const {
        fields,
    } = props;

    return (
        <>
            {
                objectExists(fields) &&
                Object.keys(fields).map((fieldName, index) => {

                    let fieldLabel = fields[fieldName].label;
                    let fieldValue = fields[fieldName].value;

                    switch (fieldName) {

                        case 'variantObj':
                            return <RenderVariantObjFields
                                key={index}
                                index={index}
                                variantObj={fields[fieldName]}
                            />
                        case 'variantObjArray':
                            return <RenderVariantObjArray
                                key={index}
                                variantObjArray={fields[fieldName]}
                            />;
                        default:

                            return <ListItem key={index}>
                                <ListItemField
                                    label={fieldLabel}
                                    value={fieldValue}
                                />
                            </ListItem>
                    }
                })
            }
        </>
    );
}

RenderFields.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default RenderFields;