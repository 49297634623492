/**
 * @file auth.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 09, 2021 20:02
 */
import {
    removeToken,
    saveToken
} from 'src/auth/authUtilities';

import * as Actions from 'src/store/actions/auth.actions';

const initialState = {
    // general
    authStage: 'pw',  // pw, otp

    // login form
    serverError: '',

    // otp 2fa form
    errorExists: false,
    serverMessage: '',
    otpAuthUrl: '',

    // authenticated user
    account: {},
    user: {},
    nodeEnv: '',
    authenticated: false,
};

const authReducer = (state = initialState, action) => {
    let account = null;
    let authObj = null;
    let authenticated = null;
    let nodeEnv = null;
    let otpAuthUrl = null;
    let user = null;

    switch (action.type) {

        // -------------------------------------------------------------------
        // setters
        // -------------------------------------------------------------------
        case Actions.AUTH_STAGE_SET: {
            return {
                ...state,
                authStage: action.payload,
            };
        }

        // -------------------------------------------------------------------
        // login.password
        // -------------------------------------------------------------------
        case Actions.AUTH_LOGIN_PASSWORD_REQUEST: {
            return {
                ...state,
                account: {},
                user: {},
                nodeEnv: '',
                authenticated: false,
                userPhoneNumber: '',
                authStage: 'pw',
            };
        }

        case Actions.AUTH_LOGIN_PASSWORD_SUCCESS: {
            authObj = action.payload.authObj;
            nodeEnv = action.payload.nodeEnv;

            if (authObj.action === 'authenticate') {

                account = {
                    accountName: action.payload.authObj.account.accountName,
                    subscriptions: action.payload.authObj.account.subscriptions,
                };

                authenticated = action.payload.authObj.authenticated;

                user = action.payload.authObj.user;

                saveToken(action.payload.authObj.token, 'auth')

                return {
                    ...state,
                    account,
                    authenticated,
                    nodeEnv,
                    user,
                };

            } else if (authObj.action === 'otpSetup') {

                otpAuthUrl = action.payload.authObj.otpAuthUrl;
                authenticated = action.payload.authObj.authenticated;

                saveToken(action.payload.authObj.token, 'identity')

                return {
                    ...state,
                    authenticated,
                    nodeEnv,
                    otpAuthUrl,
                };

            } else if (authObj.action === 'otpLogin') {

                saveToken(action.payload.authObj.token, 'identity')
                authenticated = action.payload.authObj.authenticated;

                return {
                    ...state,
                    authenticated,
                    nodeEnv,
                };

            } else {
                console.error('authReducer.unknown authObj.action', authObj.action)
                return {
                    ...state,
                };
            }
        }

        case Actions.AUTH_LOGIN_PASSWORD_FAILURE: {

            return {
                ...state,
                account: {},
                user: {},
                nodeEnv: '',
                authenticated: false,
                userPhoneNumber: '',
            };
        }

        // -------------------------------------------------------------------
        // login.token
        // -------------------------------------------------------------------
        case Actions.AUTH_LOGIN_TOKEN_REQUEST: {
            return {
                ...state,
                account: {},
                user: {},
                nodeEnv: '',
                authenticated: false,
            };
        }

        case Actions.AUTH_LOGIN_TOKEN_SUCCESS: {

            account = {
                accountName: action.payload.authObj.account.accountName,
                subscriptions: action.payload.authObj.account.subscriptions,
            };

            nodeEnv = action.payload.nodeEnv;
            authenticated = action.payload.authObj.authenticated;

            user = action.payload.authObj.user;

            saveToken(action.payload.authObj.token, 'auth');

            return {
                ...state,
                account,
                authenticated,
                nodeEnv,
                user,
            };

        }

        case Actions.AUTH_LOGIN_TOKEN_FAILURE: {
            removeToken('auth');

            return {
                ...state,
                account: {},
                user: {},
                authenticated: false,
            };
        }

        // -------------------------------------------------------------------
        // logout
        // -------------------------------------------------------------------
        case Actions.AUTH_LOGOUT_REQUEST: {
            removeToken('auth');
            removeToken('identity');

            return {
                ...state,
                account: {},
                user: {},
                authenticated: false,
                authStage: 'pw',
            };
        }

        // -------------------------------------------------------------------
        // otpSetup
        // -------------------------------------------------------------------
        case Actions.AUTH_OTP_SETUP_REQUEST: {
            return {
                ...state,
                serverMessage: '',
                otpAuthUrl: '',
            };
        }

        case Actions.AUTH_OTP_SETUP_SUCCESS: {

            otpAuthUrl = action.payload.authObj.qrcImage;
            saveToken(action.payload.authObj.token, 'identity');

            return {
                ...state,
                otpAuthUrl,
            };

        }

        case Actions.AUTH_OTP_SETUP_FAILURE: {
            return {
                ...state,
                serverMessage: '',
                otpAuthUrl: '',
            };
        }

        // -------------------------------------------------------------------
        // otpSubmit
        // -------------------------------------------------------------------
        case Actions.AUTH_OTP_SUBMIT_REQUEST: {
            return {
                ...state,
                errorExists: false,
                serverMessage: '',
            };
        }

        case Actions.AUTH_OTP_SUBMIT_SUCCESS: {
            account = {
                accountName: action.payload.authObj.account.accountName,
                subscriptions: action.payload.authObj.account.subscriptions,
            };

            nodeEnv = action.payload.nodeEnv;
            authenticated = action.payload.authObj.authenticated;

            user = action.payload.authObj.user;

            saveToken(action.payload.authObj.token, 'auth');
            removeToken('identity');

            return {
                ...state,
                account,
                authenticated,
                nodeEnv,
                user,
                errorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMsg,
            };

        }

        case Actions.AUTH_OTP_SUBMIT_FAILURE: {
            return {
                ...state,
                serverMessage: '',
            };
        }

        default: {
            return state;
        }
    }
};

export default authReducer;
