/**
 * @file utilities.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author https://github.com/selbekk/calidators/blob/master/src/utilities.js
 * @since Aug 24, 2019 19:13
 */
// import warning from 'warning';

// http://emailregex.com/
// https://github.com/selbekk/calidators/blob/master/src/isEmail.js
const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// http://phoneregex.com/
const PHONE_NUMBER_REGEXP =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

// http://regexlib.com/Search.aspx?k=us+zip+code&c=-1&m=-1&ps=20
const ZIP_CODE_REGEXP = /^\d{5}(-\d{4})?$/;

export const INVALID_TYPE = 'Invalid type, see console for details.';

const NEGATIVE_REGEXP = /^-/;

export const Types = Object.freeze({
    array: Array,
    boolean: Boolean,
    function: Function,
    null: null,
    number: Number,
    object: Object,
    string: String,
    undefined,
});

export const getType = (value) => {
    return Object.entries({
        array: isArray,
        boolean: isBoolean,
        function: isFunction,
        null: isNull,
        number: isNumber,
        object: isObject,
        string: isString,
        undefined: isUndefined,
    }).reduce((type, [key, isType]) => (type !== false || !isType(value) ? type : Types[key]), false);
};

export function functionToTag(func) {
    return Function.prototype.toString.call(func);
}

export function objectToTag(obj) {
    return Object.prototype.toString.call(obj);
}

export function typeToString(type) {
    return isFunction(type) ?
        functionToTag(type).match(/^function (\w+)/)[1] :
        objectToTag(type).match(/^\[object (\w+)/)[1];
}

export function isInvalidType(value, validTypes) {
    const type = getType(value);
    const isValid = validTypes.includes(type);

    // warning(
    //     isValid,
    //     'Invalid value type "%s", expected value type%s: %s',
    //     typeToString(type),
    //     validTypes.length > 1 ? 's' : '',
    //     validTypes.map(typeToString).join(', '),
    // );

    const nodeEnv = import.meta.env.NODE_ENV;

    return !isValid && nodeEnv && nodeEnv !== 'production' ? INVALID_TYPE : null;
}

export function isArray(value) {
    return Array.isArray(value);
}

export function isBoolean(value) {
    const typeOf = typeof value;

    return typeOf === 'boolean' || isObjectLike(value) && objectToTag(value) === '[object Boolean]';
}

export function isEmptyString(value) {
    return isString(value) && value.trim().length === 0;
}

export function isFunction(value) {
    return typeof value === 'function';
}

export function isNil(value) {
    return isNull(value) || isUndefined(value);
}

export function isNull(value) {
    return value === null;
}

export function isObjectLike(value) {
    return !isNull(value) && typeof value === 'object';
}

export function isNumber(value) {
    const typeOf = typeof value;

    return (
        (typeOf === 'number' || isObjectLike(value) && objectToTag(value) === '[object Number]') &&
        !Number.isNaN(value)
    );
}

export function isObject(value) {
    if (!isObjectLike(value) || objectToTag(value) !== '[object Object]') {
        return false;
    }

    const prototype = Object.getPrototypeOf(Object(value));

    if (isNull(prototype)) {
        return true;
    }

    const Ctor =
        Object.prototype.hasOwnProperty.call(prototype, 'constructor') && prototype.constructor;

    return isFunction(Ctor) && Ctor instanceof Ctor && functionToTag(Ctor) === functionToTag(Object);
}

export function isString(value) {
    const typeOf = typeof value;

    return typeOf === 'string' || isObjectLike(value) && objectToTag(value) === '[object String]';
}

export function isUndefined(value) {
    return value === undefined;
}

export function toNumber(value) {
    return isNumber(value) || isString(value) && !isEmptyString(value) ? Number(value) : NaN;
}

export function toString(value) {
    return isArray(value) || isObject(value) ? JSON.stringify(value) : `${value}`;
}

export function whenValueIs(value, { lessThan, equalTo, greaterThan }, model) {
    if (isNumber(value) || isString(value)) {
        value = `${value}`.trim();
        model = `${model}`.trim();

        const valueIsNegative = NEGATIVE_REGEXP.test(value);
        const modelIsNegative = NEGATIVE_REGEXP.test(model);

        if (valueIsNegative && !modelIsNegative) {
            return lessThan;
        }

        if (!valueIsNegative && modelIsNegative) {
            return greaterThan;
        }

        value = value.replace(NEGATIVE_REGEXP, '');
        model = model.replace(NEGATIVE_REGEXP, '');

        let [valInt, valDec = ''] = value.split('.');
        let [modInt, modDec = ''] = model.split('.');
        const intLength = Math.max(valInt.length, modInt.length);
        const decLength = Math.max(valDec.length, modDec.length);

        valInt = valInt.padStart(intLength, '0');
        modInt = modInt.padStart(intLength, '0');

        valDec = valDec.padEnd(decLength, '0');
        modDec = modDec.padEnd(decLength, '0');

        value = `${valInt}.${valDec}`;
        model = `${modInt}.${modDec}`;

        if (value === model) {
            return equalTo;
        }

        return (valueIsNegative && modelIsNegative ? value > model : value < model) ?
            lessThan :
            greaterThan;
    }

    return isInvalidType(value, [Types.number, Types.string]);
}

export function isEmail(value) {
    if (isString(value)) {
        return EMAIL_REGEXP.test(value);
    }

    return isInvalidType(value, [Types.string]);
}

export function isPhoneNumber(value) {
    if (isString(value)) {
        return PHONE_NUMBER_REGEXP.test(value);
    }

    return isInvalidType(value, [Types.string]);
}

export function isZipCode(value) {
    if (isString(value)) {
        return ZIP_CODE_REGEXP.test(value);
    }

    return isInvalidType(value, [Types.string]);
}
