/**
 * @file layouts.SidebarMenu.items.jsx
 * @description items for production release
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 13:23
 */
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import MoveUpTwoToneIcon from '@mui/icons-material/MoveUpTwoTone';

const menuItems = [
    {
        heading: 'General',
        items: [
            {
                name: 'Dashboard',
                icon: AnalyticsTwoToneIcon,
                link: '/dashboard'
            },
            {
                name: 'Annotations',
                icon: ModeTwoToneIcon,
                link: '/annotations'
            },
            {
                name: 'Documents',
                icon: PictureAsPdfTwoToneIcon,
                link: '/dashboards',
                items: [
                    {
                        name: 'Search',
                        link: 'document/search',
                        badge: '',
                    },
                    {
                        name: 'Upload',
                        link: 'document/upload',
                        items: [
                            {
                                name: 'Publication',
                                link: 'document/upload/publication',
                                badge: '',
                            },
                            {
                                name: 'Supplement',
                                link: 'document/upload/supplement',
                                badge: ''
                            },
                        ]
                    },
                ]
            },
            {
                name: 'Gene Models',
                icon: AccountTreeTwoToneIcon,
                link: '/geneModel/search'
            },
            {
                name: 'Variants',
                icon: MoveUpTwoToneIcon,
                link: '/variant/search'
            },
        ]
    },
];

export default menuItems;
