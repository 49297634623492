/**
 * @file documentAnnotator.variantValidatorFind.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 24, 2023 14:04
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import store from 'src/store';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import { getToken } from 'src/auth/authUtilities';
import { arrayExists, objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

import {
    DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST,
    variantValidatorFindSuccess,
    variantValidatorFindFailure,
} from 'src/store/actions/documentAnnotator/documentAnnotatorDb.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/documentAnnotator/variant/validator/find';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            cDot: payload.cDot,
            genomeBuild: payload.genomeBuild,
            transcript: payload.transcript,
            annotationId: payload.annotationId,
            variantId: payload.variantId
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* createRequestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            if (stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.staticInfoMessage.options)
                );
            }

            if (objectExists(resp.payload)) {

                if (objectKeyExists(resp.payload, 'variantValidatorObj') &&
                    objectExists(resp.payload.variantValidatorObj)) {

                    const variantValidatorObj = resp.payload.variantValidatorObj;

                    if (objectKeyExists(variantValidatorObj, 'validationWarnings') &&
                        arrayExists(variantValidatorObj.validationWarnings)) {

                        variantValidatorObj.validationWarnings.forEach((item) => {

                            // note: had to use store.dispatch here because
                            // yield caused a reserved-word syntax error
                            store.dispatch(enqueueMessage(
                                item,
                                standardMessages.staticInfoMessage.options)
                            );

                        });
                    }
                }
            }

            yield put(variantValidatorFindSuccess(resp.payload));

        } catch (error) {
            yield put(variantValidatorFindFailure(error));
            handleSagaErrors(error);
        } finally {
            yield put(dataLoadingSet(false));
        }
    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* createRequestMonitor() {
    yield takeLatest(DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST, createRequestSaga);
}
