/**
 * @file variantDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 10:38
 */

// -------------------------------------------------------------------
// used for testing
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_CLASSIFICATION_SET = 'VARIANT_GENE_DISEASE_CLASSIFICATION_SET';
export function geneDiseaseClassificationSet(value) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_SET,
        payload: value,
    };
}

export const VARIANT_SET = 'VARIANT_SET';
export function variantSet(value) {
    return {
        type: VARIANT_SET,
        payload: value,
    };
}

export const VARIANT_DISEASE_SET = 'VARIANT_DISEASE_SET';
export function variantDiseaseSet(value) {
    return {
        type: VARIANT_DISEASE_SET,
        payload: value,
    };
}

export const VARIANT_DISEASE_APPROVAL_SET = 'VARIANT_DISEASE_APPROVAL_SET';
export function variantDiseaseApprovalSet(objId, isChecked) {
    return {
        type: VARIANT_DISEASE_APPROVAL_SET,
        payload: {
            objId,
            value: isChecked,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_SET = 'VARIANT_DISEASE_CLASSIFICATION_SET';
export function variantDiseaseClassificationSet(value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_SET,
        payload: value,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_APPROVAL_SET = 'VARIANT_DISEASE_CLASSIFICATION_APPROVAL_SET';
export function variantDiseaseClassificationApprovalSet(objId, isChecked) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_APPROVAL_SET,
        payload: {
            objId,
            value: isChecked,
        },
    };
}

export const VARIANT_PATHOGENICITY_CATEGORIES_SET = 'VARIANT_PATHOGENICITY_CATEGORIES_SET';
export function pathogenicityCategoriesSet(value) {
    return {
        type: VARIANT_PATHOGENICITY_CATEGORIES_SET,
        payload: value,
    };
}

export const VARIANT_CLASSIFICATION_RULES_SET = 'VARIANT_CLASSIFICATION_RULES_SET';
export function classificationRulesSet(value) {
    return {
        type: VARIANT_CLASSIFICATION_RULES_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// resets
// -------------------------------------------------------------------
export const VARIANT_DB_RESET_STATE = 'VARIANT_DB_RESET_STATE';
export function variantResetDbState() {
    return {
        type: VARIANT_DB_RESET_STATE,
    };
}

export const VARIANT_RESET = 'VARIANT_RESET';
export function variantReset() {
    return {
        type: VARIANT_RESET,
        payload: {}
    };
}

export const VARIANTS_RESET = 'VARIANTS_RESET';
export function variantsReset() {
    return {
        type: VARIANTS_RESET,
        payload: {}
    };
}

export const VARIANT_DISEASE_RESET = 'VARIANT_DISEASE_RESET';
export function variantDiseaseReset() {
    return {
        type: VARIANT_DISEASE_RESET,
    };
}

export const VARIANT_VIEW_RESET = 'VARIANT_VIEW_RESET';
export function variantViewReset() {
    return {
        type: VARIANT_VIEW_RESET,
    };
}

export const VARIANT_DISEASE_CLASSIFICATIONS_RESET = 'VARIANT_DISEASE_CLASSIFICATIONS_RESET';
export function variantDiseaseClassificationsReset() {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_RESET,
        payload: {
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_RESET = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_RESET';
export function variantDiseaseClassificationEvidenceReset() {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_RESET,
        payload: {
        }
    };
}

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const VARIANT_ID_SET = 'VARIANT_ID_SET';
export function variantIdSet(value) {
    return {
        type: VARIANT_ID_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// form mode indicators
// -------------------------------------------------------------------
export const VARIANT_FORM_MODE_SET = 'VARIANT_FORM_MODE_SET';
export function variantFormModeSet(value) {
    return {
        type: VARIANT_FORM_MODE_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variants.delete
// -------------------------------------------------------------------
export const VARIANTS_DELETE_REQUEST = 'VARIANTS_DELETE_REQUEST';
export function variantsDeleteRequest(searchMethod, searchTerm, variantIds) {
    return {
        type: VARIANTS_DELETE_REQUEST,
        payload: {
            searchMethod,
            searchTerm,
            variantIds,
        },
    };
}

export const VARIANTS_DELETE_SUCCESS = 'VARIANTS_DELETE_SUCCESS';
export function variantsDeleteSuccess(payload) {
    return {
        type: VARIANTS_DELETE_SUCCESS,
        payload,
    };
}

export const VARIANTS_DELETE_FAILURE = 'VARIANTS_DELETE_FAILURE';
export function variantsDeleteFailure(error) {
    return {
        type: VARIANTS_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantValidatorFind - returns reference variant used to
//        provide a gDot and other elements for a variant
// -------------------------------------------------------------------
export const VARIANT_VARIANT_VALIDATOR_FIND_REQUEST = 'VARIANT_VARIANT_VALIDATOR_FIND_REQUEST';
export function variantValidatorFindRequest(cDot, genomeBuild, transcript, annotationId, variantId) {
    return {
        type: VARIANT_VARIANT_VALIDATOR_FIND_REQUEST,
        payload: {
            cDot,
            genomeBuild,
            transcript,
            annotationId,
            variantId,
        },
    };
}

export const VARIANT_VARIANT_VALIDATOR_FIND_SUCCESS = 'VARIANT_VARIANT_VALIDATOR_FIND_SUCCESS';
export function variantValidatorFindSuccess(payload) {
    return {
        type: VARIANT_VARIANT_VALIDATOR_FIND_SUCCESS,
        payload,
    };
}

export const VARIANT_VARIANT_VALIDATOR_FIND_FAILURE = 'VARIANT_VARIANT_VALIDATOR_FIND_FAILURE';
export function variantValidatorFindFailure(error) {
    return {
        type: VARIANT_VARIANT_VALIDATOR_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variant.search
// -------------------------------------------------------------------
export const VARIANT_SEARCH_REQUEST = 'VARIANT_SEARCH_REQUEST';
export function variantSearchRequest(searchMethod, searchTerm) {
    return {
        type: VARIANT_SEARCH_REQUEST,
        payload: {
            searchMethod,
            searchTerm,
        },
    };
}

export const VARIANT_SEARCH_SUCCESS = 'VARIANT_SEARCH_SUCCESS';
export function variantSearchSuccess(payload) {
    return {
        type: VARIANT_SEARCH_SUCCESS,
        payload,
    };
}

export const VARIANT_SEARCH_FAILURE = 'VARIANT_SEARCH_FAILURE';
export function variantSearchFailure(error) {
    return {
        type: VARIANT_SEARCH_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variants.refresh
// -------------------------------------------------------------------
export const VARIANTS_REFRESH_SET = 'VARIANTS_REFRESH_SET';
export function variantsRefreshSet(value) {
    return {
        type: VARIANTS_REFRESH_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variant.find
// -------------------------------------------------------------------
export const VARIANT_FIND_REQUEST = 'VARIANT_FIND_REQUEST';
export function variantFindRequest(variantId, navigate) {
    return {
        type: VARIANT_FIND_REQUEST,
        payload: {
            variantId,
            navigate,
        },
    };
}

export const VARIANT_FIND_SUCCESS = 'VARIANT_FIND_SUCCESS';
export function variantFindSuccess(results) {
    return {
        type: VARIANT_FIND_SUCCESS,
        payload: results,
    };
}

export const VARIANT_FIND_FAILURE = 'VARIANT_FIND_FAILURE';
export function variantFindFailure(error) {
    return {
        type: VARIANT_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variant.updateField
// -------------------------------------------------------------------
export const VARIANT_UPDATE_FIELD = 'VARIANT_UPDATE_FIELD';
export function variantUpdateField(fieldName, fieldValue) {
    return {
        type: VARIANT_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// variant.new
// -------------------------------------------------------------------
export const VARIANT_NEW_REQUEST = 'VARIANT_NEW_REQUEST';
export function variantNewRequest(cDot, genomeBuild, transcript, navigate) {
    return {
        type: VARIANT_NEW_REQUEST,
        payload: {
            cDot,
            transcript,
            genomeBuild,
            navigate,
        },
    };
}

export const VARIANT_NEW_SUCCESS = 'VARIANT_NEW_SUCCESS';
export function variantNewSuccess(variant) {
    return {
        type: VARIANT_NEW_SUCCESS,
        payload: variant,
    };
}

export const VARIANT_NEW_FAILURE = 'VARIANT_NEW_FAILURE';
export function variantNewFailure(error) {
    return {
        type: VARIANT_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variant.new.updateFields - updates all formFields
// -------------------------------------------------------------------
export const VARIANT_NEW_UPDATE_FIELDS = 'VARIANT_NEW_UPDATE_FIELDS';
export function variantNewUpdateFields(formFields) {
    return {
        type: VARIANT_NEW_UPDATE_FIELDS,
        payload: formFields,
    };
}

// -------------------------------------------------------------------
// variant.variantUpdate
// -------------------------------------------------------------------
export const VARIANT_UPDATE_REQUEST = 'VARIANT_UPDATE_REQUEST';
export function variantUpdateRequest(variant, notify) {
    return {
        type: VARIANT_UPDATE_REQUEST,
        payload: {
            variant,
            notify,
        },
    };
}

export const VARIANT_UPDATE_SUCCESS = 'VARIANT_UPDATE_SUCCESS';
export function variantUpdateSuccess(payload) {
    return {
        type: VARIANT_UPDATE_SUCCESS,
        payload,
    };
}

export const VARIANT_UPDATE_FAILURE = 'VARIANT_UPDATE_FAILURE';
export function variantUpdateFailure(error) {
    return {
        type: VARIANT_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variant.view
// -------------------------------------------------------------------
export const VARIANT_VIEW_REQUEST = 'VARIANT_VIEW_REQUEST';
export function variantViewRequest(variantId) {
    return {
        type: VARIANT_VIEW_REQUEST,
        payload: {
            variantId,
        },
    };
}

export const VARIANT_VIEW_SUCCESS = 'VARIANT_VIEW_SUCCESS';
export function variantViewSuccess(variantView) {
    return {
        type: VARIANT_VIEW_SUCCESS,
        payload: variantView,
    };
}

export const VARIANT_VIEW_FAILURE = 'VARIANT_VIEW_FAILURE';
export function variantViewFailure(error) {
    return {
        type: VARIANT_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.updateField
// -------------------------------------------------------------------
export const VARIANT_DISEASE_UPDATE_FIELD = 'VARIANT_DISEASE_UPDATE_FIELD';
export function variantDiseaseUpdateField(fieldName, fieldValue) {
    return {
        type: VARIANT_DISEASE_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// variantDisease.delete - deletes single variantDisease
// -------------------------------------------------------------------
export const VARIANT_DISEASE_DELETE_REQUEST = 'VARIANT_DISEASE_DELETE_REQUEST';
export function variantDiseaseDeleteRequest(objId, pageMap, variantDiseaseId) {
    return {
        type: VARIANT_DISEASE_DELETE_REQUEST,
        payload: {
            objId,
            pageMap,
            variantDiseaseId,
        },
    };
}

export const VARIANT_DISEASE_DELETE_SUCCESS = 'VARIANT_DISEASE_DELETE_SUCCESS';
export function variantDiseaseDeleteSuccess(payload) {
    return {
        type: VARIANT_DISEASE_DELETE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_DELETE_FAILURE = 'VARIANT_DISEASE_DELETE_FAILURE';
export function variantDiseaseDeleteFailure(error) {
    return {
        type: VARIANT_DISEASE_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.find - finds single variantDisease
// -------------------------------------------------------------------
export const VARIANT_DISEASE_FIND_REQUEST = 'VARIANT_DISEASE_FIND_REQUEST';
export function variantDiseaseFindRequest(variantDiseaseId) {
    return {
        type: VARIANT_DISEASE_FIND_REQUEST,
        payload: {
            variantDiseaseId,
        },
    };
}

export const VARIANT_DISEASE_FIND_SUCCESS = 'VARIANT_DISEASE_FIND_SUCCESS';
export function variantDiseaseFindSuccess(payload) {
    return {
        type: VARIANT_DISEASE_FIND_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_FIND_FAILURE = 'VARIANT_DISEASE_FIND_FAILURE';
export function variantDiseaseFindFailure(error) {
    return {
        type: VARIANT_DISEASE_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.insert - inserts single variantDisease
// -------------------------------------------------------------------
export const VARIANT_DISEASE_INSERT_REQUEST = 'VARIANT_DISEASE_INSERT_REQUEST';
export function variantDiseaseInsertRequest(objId, pageMap, geneDiseaseId, variantId) {
    return {
        type: VARIANT_DISEASE_INSERT_REQUEST,
        payload: {
            objId,
            pageMap,
            geneDiseaseId,  // a new variantDisease is created from existing geneDisease
            variantId,
        },
    };
}

export const VARIANT_DISEASE_INSERT_SUCCESS = 'VARIANT_DISEASE_INSERT_SUCCESS';
export function variantDiseaseInsertSuccess(payload) {
    return {
        type: VARIANT_DISEASE_INSERT_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_INSERT_FAILURE = 'VARIANT_DISEASE_INSERT_FAILURE';
export function variantDiseaseInsertFailure(error) {
    return {
        type: VARIANT_DISEASE_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.update
// -------------------------------------------------------------------
export const VARIANT_DISEASE_UPDATE_REQUEST = 'VARIANT_DISEASE_UPDATE_REQUEST';
export function variantDiseaseUpdateRequest(variantDisease, notify) {
    return {
        type: VARIANT_DISEASE_UPDATE_REQUEST,
        payload: {
            variantDisease,
            notify,
        },
    };
}

export const VARIANT_DISEASE_UPDATE_SUCCESS = 'VARIANT_DISEASE_UPDATE_SUCCESS';
export function variantDiseaseUpdateSuccess(payload) {
    return {
        type: VARIANT_DISEASE_UPDATE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_UPDATE_FAILURE = 'VARIANT_DISEASE_UPDATE_FAILURE';
export function variantDiseaseUpdateFailure(error) {
    return {
        type: VARIANT_DISEASE_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationCalculate 
// -------------------------------------------------------------------
export const VARIANT_CLASSIFICATION_CALCULATE_REQUEST = 'VARIANT_CLASSIFICATION_CALCULATE_REQUEST';
export function variantDiseaseClassificationCalculateRequest(variantDiseaseId) {
    return {
        type: VARIANT_CLASSIFICATION_CALCULATE_REQUEST,
        payload: {
            variantDiseaseId,
        },
    };
}

export const VARIANT_CLASSIFICATION_CALCULATE_SUCCESS = 'VARIANT_CLASSIFICATION_CALCULATE_SUCCESS';
export function variantDiseaseClassificationCalculateSuccess(payload) {
    return {
        type: VARIANT_CLASSIFICATION_CALCULATE_SUCCESS,
        payload,
    };
}

export const VARIANT_CLASSIFICATION_CALCULATE_FAILURE = 'VARIANT_CLASSIFICATION_CALCULATE_FAILURE';
export function variantDiseaseClassificationCalculateFailure(error) {
    return {
        type: VARIANT_CLASSIFICATION_CALCULATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.delete - deletes single variantDiseaseClassification
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_DELETE_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_DELETE_REQUEST';
export function variantDiseaseClassificationDeleteRequest(objId, pageMap, variantDiseaseClassificationId) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_DELETE_REQUEST,
        payload: {
            objId,
            pageMap,
            variantDiseaseClassificationId,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_DELETE_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_DELETE_SUCCESS';
export function variantDiseaseClassificationDeleteSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_DELETE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_DELETE_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_DELETE_FAILURE';
export function variantDiseaseClassificationDeleteFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassifications.initialQuery - 
// finds variantDiseaseClassifcations and geneDiseaseClassifications
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_REQUEST = 'VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_REQUEST';
export function variantDiseaseClassificationsInitialQueryRequest(variantDiseaseId) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_REQUEST,
        payload: {
            variantDiseaseId,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_SUCCESS';
export function variantDiseaseClassificationsInitialQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_FAILURE = 'VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_FAILURE';
export function variantDiseaseClassificationsInitialQueryFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassifications.pageQuery - 
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_REQUEST = 'VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_REQUEST';
export function variantDiseaseClassificationsPageQueryRequest(pageMap, pageNo, queryObj) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_REQUEST,
        payload: {
            pageMap,
            pageNo,
            queryObj,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_SUCCESS';
export function variantDiseaseClassificationsPageQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_FAILURE = 'VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_FAILURE';
export function variantDiseaseClassificationsPageQueryFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.insert - inserts single variantDiseaseClassification
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_INSERT_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_INSERT_REQUEST';
export function variantDiseaseClassificationInsertRequest(
    objId,
    pageMap,
    geneDiseaseClassificationId,
    variantDiseaseId,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_INSERT_REQUEST,
        payload: {
            objId,
            pageMap,
            geneDiseaseClassificationId,  // a new variantDiseaseClassification is created from existing geneDiseaseClassification
            variantDiseaseId,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_INSERT_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_INSERT_SUCCESS';
export function variantDiseaseClassificationInsertSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_INSERT_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_INSERT_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_INSERT_FAILURE';
export function variantDiseaseClassificationInsertFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.update - updates single variantDiseaseClassification
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_UPDATE_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_UPDATE_REQUEST';
export function variantDiseaseClassificationUpdateRequest(
    variantDiseaseClassification,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_UPDATE_REQUEST,
        payload: {
            variantDiseaseClassification,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_UPDATE_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_UPDATE_SUCCESS';
export function variantDiseaseClassificationUpdateSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_UPDATE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_UPDATE_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_UPDATE_FAILURE';
export function variantDiseaseClassificationUpdateFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.updateField
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_UPDATE_FIELD = 'VARIANT_DISEASE_CLASSIFICATION_UPDATE_FIELD';
export function variantDiseaseClassificationUpdateField(fieldName, fieldValue) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationAnnotations.find
// -------------------------------------------------------------------
// export const VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST';
// export function variantDiseaseClassificationAnnotationsFindRequest(
//     variantDiseaseClassificationId,
// ) {
//     return {
//         type: VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST,
//         payload: {
//             variantDiseaseClassificationId,
//         }
//     };
// }

// export const VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS';
// export function variantDiseaseClassificationAnnotationsFindSuccess(payload) {
//     return {
//         type: VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS,
//         payload,
//     };
// }

// export const VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE';
// export function variantDiseaseClassificationAnnotationsFindFailure(error) {
//     return {
//         type: VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE,
//         payload: JSON.stringify(error),
//     };
// }

// -------------------------------------------------------------------
// variantDisease.geneDiseaseAnnotationsFind
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_REQUEST = 'VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_REQUEST';
export function geneDiseaseAnnotationsFindRequest(geneDiseaseId, variantId) {
    return {
        type: VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_REQUEST,
        payload: {
            geneDiseaseId,
            variantId,
        }
    };
}

export const VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_SUCCESS = 'VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_SUCCESS';
export function geneDiseaseAnnotationsFindSuccess(payload) {
    return {
        type: VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_SUCCESS,
        payload,
    };
}

export const VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_FAILURE = 'VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_FAILURE';
export function geneDiseaseAnnotationsFindFailure(error) {
    return {
        type: VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.geneDiseaseClassificationEvidence.find
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST = 'VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST';
export function geneDiseaseClassificationAnnotationsFindRequest(geneDiseaseClassificationId, variantId) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST,
        payload: {
            geneDiseaseClassificationId,
            variantId,
        }
    };
}

export const VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS = 'VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS';
export function geneDiseaseClassificationAnnotationsFindSuccess(payload) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS,
        payload,
    };
}

export const VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE = 'VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE';
export function geneDiseaseClassificationAnnotationsFindFailure(error) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.insert
// -------------------------------------------------------------------
export const VARIANT_CLASSIFICATION_EVIDENCE_INSERT_REQUEST = 'VARIANT_CLASSIFICATION_EVIDENCE_INSERT_REQUEST';
export function classificationEvidenceInsertRequest(classificationEvidence, notify) {
    return {
        type: VARIANT_CLASSIFICATION_EVIDENCE_INSERT_REQUEST,
        payload: {
            classificationEvidence,
            notify,
        }
    };
}

export const VARIANT_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS = 'VARIANT_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS';
export function classificationEvidenceInsertSuccess(payload) {
    return {
        type: VARIANT_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS,
        payload,
    };
}

export const VARIANT_CLASSIFICATION_EVIDENCE_INSERT_FAILURE = 'VARIANT_CLASSIFICATION_EVIDENCE_INSERT_FAILURE';
export function classificationEvidenceInsertFailure(error) {
    return {
        type: VARIANT_CLASSIFICATION_EVIDENCE_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.find
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_FIND_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_FIND_REQUEST';
export function variantDiseaseClassificationFindRequest(variantDiseaseClassificationId) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_FIND_REQUEST,
        payload: {
            variantDiseaseClassificationId,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_FIND_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_FIND_SUCCESS';
export function variantDiseaseClassificationFindSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_FIND_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_FIND_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_FIND_FAILURE';
export function variantDiseaseClassificationFindFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseaseClassification.view
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_VIEW_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_VIEW_REQUEST';
export function variantDiseaseClassificationViewRequest(variantDiseaseClassificationId) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_VIEW_REQUEST,
        payload: {
            variantDiseaseClassificationId,
        },
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_VIEW_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_VIEW_SUCCESS';
export function variantDiseaseClassificationViewSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_VIEW_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_VIEW_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_VIEW_FAILURE';
export function variantDiseaseClassificationViewFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassification.view
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_REQUEST = 'VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_REQUEST';
export function geneDiseaseClassificationViewRequest(geneDiseaseClassificationId) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_REQUEST,
        payload: {
            geneDiseaseClassificationId,
        },
    };
}

export const VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_SUCCESS = 'VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_SUCCESS';
export function geneDiseaseClassificationViewSuccess(payload) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_SUCCESS,
        payload,
    };
}

export const VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_FAILURE = 'VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_FAILURE';
export function geneDiseaseClassificationViewFailure(error) {
    return {
        type: VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseases.initialQuery - finds variantDiseases
// associated with a variantId
// -------------------------------------------------------------------
export const VARIANT_DISEASES_INITIAL_QUERY_REQUEST = 'VARIANT_DISEASES_INITIAL_QUERY_REQUEST';
export function variantDiseasesInitialQueryRequest(variantId, pageSize) {
    return {
        type: VARIANT_DISEASES_INITIAL_QUERY_REQUEST,
        payload: {
            variantId,
            pageSize,
        },
    };
}

export const VARIANT_DISEASES_INITIAL_QUERY_SUCCESS = 'VARIANT_DISEASES_INITIAL_QUERY_SUCCESS';
export function variantDiseasesInitialQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASES_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASES_INITIAL_QUERY_FAILURE = 'VARIANT_DISEASES_INITIAL_QUERY_FAILURE';
export function variantDiseasesInitialQueryFailure(error) {
    return {
        type: VARIANT_DISEASES_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseases.pageQuery 
// -------------------------------------------------------------------
export const VARIANT_DISEASES_PAGE_QUERY_REQUEST = 'VARIANT_DISEASES_PAGE_QUERY_REQUEST';
export function variantDiseasesPageQueryRequest(pageObj, queryObj) {
    return {
        type: VARIANT_DISEASES_PAGE_QUERY_REQUEST,
        payload: {
            pageObj,
            queryObj,
        },
    };
}

export const VARIANT_DISEASES_PAGE_QUERY_SUCCESS = 'VARIANT_DISEASES_PAGE_QUERY_SUCCESS';
export function variantDiseasesPageQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASES_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASES_PAGE_QUERY_FAILURE = 'VARIANT_DISEASES_PAGE_QUERY_FAILURE';
export function variantDiseasesPageQueryFailure(error) {
    return {
        type: VARIANT_DISEASES_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseases.delete - deletes selected diseases from a variant
// -------------------------------------------------------------------
export const VARIANT_DISEASES_DELETE_REQUEST = 'VARIANT_DISEASES_DELETE_REQUEST';
export function variantDiseasesDeleteRequest(variantId, variantDiseaseIds) {
    return {
        type: VARIANT_DISEASES_DELETE_REQUEST,
        payload: {
            variantId,
            variantDiseaseIds,
        },
    };
}

export const VARIANT_DISEASES_DELETE_SUCCESS = 'VARIANT_DISEASES_DELETE_SUCCESS';
export function variantDiseasesDeleteSuccess(payload) {
    return {
        type: VARIANT_DISEASES_DELETE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASES_DELETE_FAILURE = 'VARIANT_DISEASES_DELETE_FAILURE';
export function variantDiseasesDeleteFailure(error) {
    return {
        type: VARIANT_DISEASES_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDiseasesBoard.updateField - updates arrays in the diseases kanban board
// -------------------------------------------------------------------
// export const VARIANT_DISEASES_BOARD_UPDATE_FIELD = 'VARIANT_DISEASES_BOARD_UPDATE_FIELD';
// export function diseasesBoardUpdateField(fieldName, fieldValue) {
//     return {
//         type: VARIANT_DISEASES_BOARD_UPDATE_FIELD,
//         payload: {
//             fieldName,
//             fieldValue,
//         },
//     };
// }

// -------------------------------------------------------------------
// variantDiseaseClassificationsBoard.updateField - updates arrays in the diseases kanban board
// -------------------------------------------------------------------
export const VARIANT_CLASSIFICATIONS_BOARD_UPDATE_FIELD = 'VARIANT_CLASSIFICATIONS_BOARD_UPDATE_FIELD';
export function classificationsBoardUpdateField(fieldName, fieldValue) {
    return {
        type: VARIANT_CLASSIFICATIONS_BOARD_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// variant.geneDiseaseView
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_VIEW_REQUEST = 'VARIANT_GENE_DISEASE_VIEW_REQUEST';
export function geneDiseaseViewRequest(geneDiseaseId) {

    return {
        type: VARIANT_GENE_DISEASE_VIEW_REQUEST,
        payload: {
            geneDiseaseId,
        },
    };
}

export const VARIANT_GENE_DISEASE_VIEW_SUCCESS = 'VARIANT_GENE_DISEASE_VIEW_SUCCESS';
export function geneDiseaseViewSuccess(payload) {
    return {
        type: VARIANT_GENE_DISEASE_VIEW_SUCCESS,
        payload,
    };
}

export const VARIANT_GENE_DISEASE_VIEW_FAILURE = 'VARIANT_GENE_DISEASE_VIEW_FAILURE';
export function geneDiseaseViewFailure(error) {
    return {
        type: VARIANT_GENE_DISEASE_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variant.variantDiseaseView
// -------------------------------------------------------------------
export const VARIANT_DISEASE_VIEW_REQUEST = 'VARIANT_DISEASE_VIEW_REQUEST';
export function variantDiseaseViewRequest(variantDiseaseId) {

    return {
        type: VARIANT_DISEASE_VIEW_REQUEST,
        payload: {
            variantDiseaseId,
        },
    };
}

export const VARIANT_DISEASE_VIEW_SUCCESS = 'VARIANT_DISEASE_VIEW_SUCCESS';
export function variantDiseaseViewSuccess(payload) {
    return {
        type: VARIANT_DISEASE_VIEW_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_VIEW_FAILURE = 'VARIANT_DISEASE_VIEW_FAILURE';
export function variantDiseaseViewFailure(error) {
    return {
        type: VARIANT_DISEASE_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence.approval.set
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET';
export function classificationEvidenceApprovalSet(_id, value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET,
        payload: {
            _id,
            value,
        }
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence.initialQuery
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST';
export function classificationEvidenceInitialQueryRequest(
    variantDiseaseClassificationId,
    pageSize,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST,
        payload: {
            variantDiseaseClassificationId,
            pageSize,
        }
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS';
export function classificationEvidenceInitialQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE';
export function classificationEvidenceInitialQueryFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence.pageQuery
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST';
export function variantDiseaseClassificationEvidencePageQueryRequest(
    pageMap,
    pageNo,
    queryObj,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST,
        payload: {
            pageMap,
            pageNo,
            queryObj,
        }
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS';
export function variantDiseaseClassificationEvidencePageQuerySuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE';
export function variantDiseaseClassificationEvidencePageQueryFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence.delete
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST';
export function variantDiseaseClassificationEvidenceDeleteRequest(
    objId,
    pageMap,
    evidenceId,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST,
        payload: {
            objId,
            pageMap,
            evidenceId,
        }
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS';
export function variantDiseaseClassificationEvidenceDeleteSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE';
export function variantDiseaseClassificationEvidenceDeleteFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence.insert
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST';
export function variantDiseaseClassificationEvidenceInsertRequest(
    objId,
    pageMap,
    queryObj,
) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST,
        payload: {
            objId,
            pageMap,
            queryObj,
        }
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS';
export function variantDiseaseClassificationEvidenceInsertSuccess(payload) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS,
        payload,
    };
}

export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE';
export function variantDiseaseClassificationEvidenceInsertFailure(error) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}
