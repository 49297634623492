/**
 * @file auth.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 09, 2021 20:01
 */
// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const AUTH_STAGE_SET = 'AUTH_STAGE_SET';
export function authStageSet(value) {
    return {
        type: AUTH_STAGE_SET,
        payload: value,
    };
}

export const AUTH_SET_AUTHENTICATION_DATA = 'AUTH_SET_AUTHENTICATION_DATA';
export function setAuthenticationData(authData) {
    return {
        type: AUTH_SET_AUTHENTICATION_DATA,
        payload: authData,
    };
}

export const AUTH_SET_AUTHENTICATED = 'AUTH_SET_AUTHENTICATED';
export function setAuthenticated(value) {
    return {
        type: AUTH_SET_AUTHENTICATED,
        payload: value,
    };
}

// -------------------------------------------------------------------
// login
// -------------------------------------------------------------------
export const AUTH_LOGIN_PASSWORD_REQUEST = 'AUTH_LOGIN_PASSWORD_REQUEST';
export function loginPasswordRequest(userName, password, navigate) {
    return {
        type: AUTH_LOGIN_PASSWORD_REQUEST,
        payload: {
            userName,
            password,
            navigate,
        },
    };
}

export const AUTH_LOGIN_PASSWORD_SUCCESS = 'AUTH_LOGIN_PASSWORD_SUCCESS';
export function loginPasswordSuccess(authPackage) {
    return {
        type: AUTH_LOGIN_PASSWORD_SUCCESS,
        payload: authPackage,
    };
}

export const AUTH_LOGIN_PASSWORD_FAILURE = 'AUTH_LOGIN_PASSWORD_FAILURE';
export function loginPasswordFailure(error) {
    return {
        type: AUTH_LOGIN_PASSWORD_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// login-token
// -------------------------------------------------------------------
export const AUTH_LOGIN_TOKEN_REQUEST = 'AUTH_LOGIN_TOKEN_REQUEST';
export function loginTokenRequest(authToken, navigate) {
    return {
        type: AUTH_LOGIN_TOKEN_REQUEST,
        payload: {
            authToken,
            navigate
        }
    };
}

export const AUTH_LOGIN_TOKEN_SUCCESS = 'AUTH_LOGIN_TOKEN_SUCCESS';
export function loginTokenSuccess(payload) {
    return {
        type: AUTH_LOGIN_TOKEN_SUCCESS,
        payload,
    };
}

export const AUTH_LOGIN_TOKEN_FAILURE = 'AUTH_LOGIN_TOKEN_FAILURE';
export function loginTokenFailure(error) {
    return {
        type: AUTH_LOGIN_TOKEN_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// logout
// -------------------------------------------------------------------
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export function authLogout() {
    return {
        type: AUTH_LOGOUT_REQUEST,
    };
}

// -------------------------------------------------------------------
// otpSetup
// -------------------------------------------------------------------
export const AUTH_OTP_SETUP_REQUEST = 'AUTH_OTP_SETUP_REQUEST';
export function otpSetupRequest() {
    return {
        type: AUTH_OTP_SETUP_REQUEST,
        payload: {
        }
    };
}

export const AUTH_OTP_SETUP_SUCCESS = 'AUTH_OTP_SETUP_SUCCESS';
export function otpSetupSuccess(payload) {
    return {
        type: AUTH_OTP_SETUP_SUCCESS,
        payload,
    };
}

export const AUTH_OTP_SETUP_FAILURE = 'AUTH_OTP_SETUP_FAILURE';
export function otpSetupFailure(error) {
    return {
        type: AUTH_OTP_SETUP_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// otpSubmit.request
// -------------------------------------------------------------------
export const AUTH_OTP_SUBMIT_REQUEST = 'AUTH_OTP_SUBMIT_REQUEST';
export function otpSubmitRequest(otpToken, navigate) {

    return {
        type: AUTH_OTP_SUBMIT_REQUEST,
        payload: {
            otpToken,
            navigate,
        }
    };
}

export const AUTH_OTP_SUBMIT_SUCCESS = 'AUTH_OTP_SUBMIT_SUCCESS';
export function otpSubmitSuccess(payload) {
    return {
        type: AUTH_OTP_SUBMIT_SUCCESS,
        payload,
    };
}

export const AUTH_OTP_SUBMIT_FAILURE = 'AUTH_OTP_SUBMIT_FAILURE';
export function otpSubmitFailure(error) {
    return {
        type: AUTH_OTP_SUBMIT_FAILURE,
        payload: JSON.stringify(error),
    };
}

