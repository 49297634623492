/**
 * @file AnnotationViewContainer.js
 * @description provides a view-only form of a single annotation
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 01, 2022 10:56
 */
import { useSelector } from 'react-redux';

import AnnotationViewForm from './AnnotationViewForm';
import { objectExists } from '../../@common/utilities';

function AnnotationViewContainer() {

    // start: redux state --------------------------
    const annotationViewState = useSelector((state) => {
        return state.annotationView;
    });

    const { annotationViewData } = annotationViewState;
    // end: redux state -----------------------------

    return (
        <>
            {objectExists(annotationViewData) &&
                <AnnotationViewForm
                    annotationViewData={annotationViewData}
                />
            }
        </>
    )
}

export default AnnotationViewContainer;