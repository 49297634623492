/**
 * @file standardMessages.js
 * @description standard ui messages
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Aug 22, 2019 17:08
 */

const standardMessages = {

    staticInfoMessage: {
        options: {
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
        }
    },

    generalErrorMessage: {
        options: {
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        }
    },

    generalInfoMessage: {
        options: {
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'info',
            preventDuplicate: true,
        }
    },

    loginFailure: {
        message: 'Unsuccessful login.',
        options: {
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'warning',
            preventDuplicate: true,
        }
    },

    networkError: {
        message: 'Network Error: lost server connection.',
        options: {
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        },
    },

    serverError: {
        message: 'There was an unexpected error on the server.',
        options: {
            autoHideDuration: 4000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        },
    },

    notAuthorized: {
        message: 'Security Error: not authorized.',
        options: {
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        },
    },

    successfulSave: {
        message: 'Saved',
        options: {
            autoHideDuration: 1000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'success',
        },
    },

    successfulDelete: {
        message: 'Deleted',
        options: {
            autoHideDuration: 1000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'success',
            preventDuplicate: true,
        },
    },

    unsuccessfulDelete: {
        message: 'Not deleted!',
        options: {
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        },
    },

    unsuccessfulSave: {
        message: 'Not saved!',
        options: {
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'bottom', // top bottom
                horizontal: 'right', // left center right
            },
            variant: 'error',
            preventDuplicate: true,
        },
    },
};

export default standardMessages;
