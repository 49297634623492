/**
 * @file loginPassword.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 05, 2023 14:28
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import { objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import {
    AUTH_LOGIN_PASSWORD_REQUEST,
    loginPasswordSuccess,
    loginPasswordFailure,
} from 'src/store/actions/auth.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';

function callApi(payload) {
    const url = '/auth/login/password';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            credentials: {
                userName: payload.userName,
                password: payload.password,
            },
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga(action) {
    try {
        yield put(dataLoadingSet(true));

        const resp = yield call(callApi, action.payload);

        yield put(dataLoadingSet(false));
        yield put(loginPasswordSuccess(resp.payload));

        if (stringExists(resp.payload.serverMessage)) {
            yield put(enqueueMessage(
                resp.payload.serverMessage,
                standardMessages.generalInfoMessage.options)
            );
        }

        // route to next auth page
        if (objectExists(resp) &&
            objectKeyExists(resp, 'payload') &&
            objectExists(resp.payload) &&
            objectKeyExists(resp.payload, 'authObj') &&
            objectExists(resp.payload.authObj)) {

            const authObj = resp.payload.authObj;
            let targetPath = null;

            if (objectKeyExists(authObj, 'action') &&
                stringExists(authObj.action)) {

                if (authObj.action === 'authenticate') {

                    targetPath = `/dashboard`;
                    return action.payload.navigate(targetPath);

                } else if (authObj.action === 'otpSetup') {

                    targetPath = `/auth/otpSetup`;
                    return action.payload.navigate(targetPath);

                } else if (authObj.action === 'otpLogin') {

                    targetPath = `/auth/otpLogin`;
                    return action.payload.navigate(targetPath);

                } else {
                    console.error('loginPassword.saga. unknown authObj.action', authObj.action);
                }

            }

        } else {
            console.error('loginPassword.saga. missing resp.payload.authObj', resp.payload)
        }

    } catch (error) {

        yield put(dataLoadingSet(false));
        yield put(loginPasswordFailure(error));

        if (stringExists(error.response.data.payload.serverMessage)) {
            yield put(enqueueMessage(
                error.response.data.payload.serverMessage,
                standardMessages.serverError.options)
            );
        }
    }
}

export default function* requestMonitor() {
    yield takeLatest(AUTH_LOGIN_PASSWORD_REQUEST, requestSaga);
}
