/**
 * @file DistributionChart.js
 * @description
 *
 * https://apexcharts.com/docs/react-charts/
 * 
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jan 09, 2022 20:39
 */
import _ from 'lodash';

import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactApexChart from 'react-apexcharts';

import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import { objectDeepCopy, objectExists } from 'src/@common/utilities';

function DistributionChart(props) {
    const {
        chartConfig,
    } = props;

    const theme = useTheme();

    const [awaitRender, setAwaitRender] = useState(true);
    const [config, setConfig] = useState(true);

    useEffect(() => {
        // copy redux state into local state
        // to initalize the form fields
        // (using redux directly for form input is way too slow)
        let localObj = {};

        if (objectExists(chartConfig)) {
            localObj = objectDeepCopy(chartConfig);
            setConfig(localObj);
        }
    }, [chartConfig]);

    _.setWith(config, 'options.colors', [
        theme.palette.secondary.main,
    ]);

    useEffect(() => {
        setAwaitRender(false);
    }, []);

    if (awaitRender) {
        return null;
    }

    return (
        <Paper elevation={3} className="ml-10 mt-10 mb-10 pt-10 pl-5 pr-5">
            <ReactApexChart
                legend={config.options.legend}
                options={config.options}
                series={config.series}
                type={config.options.chart.type}
                height={config.options.chart.height}
                width={config.options.chart.width}
                title={config.options.chart.title}
            />
        </Paper>
    );
}


DistributionChart.propTypes = {
    chartConfig: PropTypes.object.isRequired,
};

export default memo(DistributionChart);
