/**
 * @file initFromVariantValidatorObj.js
 * @description initializes a variantObj from a reference variant
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 24, 2023 09:42
 */
import { arrayExists, objectDeepCopy, objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

// helper func
function initVariantObj(variantObj, variantValidatorObj) {

    // inits the variantObj in-place using the variantValidatorObj
    if (objectExists(variantObj) &&
        objectExists(variantValidatorObj)) {

        // fill-in empty chromosome
        if (objectKeyExists(variantObj, 'chromosome') &&
            !stringExists(variantObj.chromosome)) {

            if (objectKeyExists(variantValidatorObj, 'chromosome') &&
                stringExists(variantValidatorObj.chromosome)) {
                variantObj.chromosome = variantValidatorObj.chromosome;
            }
        }

        // fill-in empty geneSymbol
        if (objectKeyExists(variantObj, 'geneSymbol') &&
            !stringExists(variantObj.geneSymbol)) {

            if (objectKeyExists(variantValidatorObj, 'geneSymbols') &&
                arrayExists(variantValidatorObj.geneSymbols)) {
                variantObj.geneSymbol = variantValidatorObj.geneSymbols[0];
            }
        }

        if (objectKeyExists(variantObj, 'gDotObj')) {

            if (objectKeyExists(variantValidatorObj, 'gDotObj')) {

                if (objectKeyExists(variantValidatorObj.gDotObj, 'hg19') &&
                    objectExists(variantValidatorObj.gDotObj.hg19)) {

                    variantObj.gDotObj.hg19 = objectDeepCopy(variantValidatorObj.gDotObj.hg19);
                }

                if (objectKeyExists(variantValidatorObj.gDotObj, 'hg38') &&
                    objectExists(variantValidatorObj.gDotObj.hg38)) {

                    variantObj.gDotObj.hg38 = objectDeepCopy(variantValidatorObj.gDotObj.hg38);
                }

            }
        }

        if (objectKeyExists(variantObj, 'pDot')) {

            if (objectKeyExists(variantValidatorObj, 'pDot') &&
                stringExists(variantValidatorObj.pDot)) {

                variantObj.pDot = variantValidatorObj.pDot;
            }
        }

        if (objectKeyExists(variantValidatorObj, 'metadata')) {
            variantObj.metadata = objectDeepCopy(variantValidatorObj.metadata);
        }
    }
}

function initFromVariantValidatorObj(
    targetAnnotationId,
    targetVariantObjId,
    variantValidatorObj,
    annotationGroup) {

    let annotationGroupCopy = objectDeepCopy(annotationGroup);

    if (objectExists(annotationGroupCopy)) {

        if (stringExists(targetAnnotationId) &&
            stringExists(targetVariantObjId) &&
            objectExists(variantValidatorObj)) {

            // find the target annotationObj in the annotationGroup
            const targetAnnotationObj = annotationGroupCopy.annotationObjs.find((item) => {

                if (objectKeyExists(item, 'annotation') &&
                    objectExists(item.annotation)) {
                    return item.annotation._id === targetAnnotationId;
                } else {
                    return false;
                }

            });

            if (objectExists(targetAnnotationObj)) {

                // find the annotation in the targetAnnotationObj
                if (objectKeyExists(targetAnnotationObj, 'annotation') &&
                    objectExists(targetAnnotationObj.annotation)) {

                    let targetAnnotation = targetAnnotationObj.annotation;

                    // the target variantObj may exist as: 
                    // 1.  as a single object ie, annotation.fields.variantObj
                    // 2.  as an element in annotation.fields.variantObjArray.variantObj

                    if (objectKeyExists(targetAnnotation, 'fields') &&
                        objectExists(targetAnnotation.fields)) {

                        // look for variantObj as an element in fields.variantObjArray
                        if (objectKeyExists(targetAnnotation.fields, 'variantObjArray')) {

                            const foundIndex = targetAnnotation.fields.variantObjArray.findIndex((item) => {
                                return item._id === targetVariantObjId;
                            });

                            if (foundIndex !== -1) {
                                initVariantObj(targetAnnotation.fields.variantObjArray[foundIndex], variantValidatorObj);
                                targetAnnotationObj.dirty = true;
                            }

                        } else {
                            // look for variantObj as fields.variantObj
                            if (objectKeyExists(targetAnnotation.fields, 'variantObj') &&
                                objectExists(targetAnnotation.fields.variantObj)) {

                                initVariantObj(targetAnnotation.fields.variantObj, variantValidatorObj);
                                targetAnnotationObj.dirty = true;
                            }
                        }

                    }
                }
            }
        }
    }

    return annotationGroupCopy;
}

export default initFromVariantValidatorObj;