/**
 * @file getUrl.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 20, 2023 15:52
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import { getToken } from 'src/auth/authUtilities';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import {
    helpDocViewerOpenDialogSet,
} from 'src/store/actions/helpDocViewer.actions';

import {
    HELP_DOC_VIEWER_GET_URL_REQUEST,
    getUrlSuccess,
    getUrlFailure,
} from 'src/store/actions/helpDocViewer.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/helpDocViewer/getUrl';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            applicationName: payload.applicationName,
            fileName: payload.fileName,
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            yield put(dataLoadingSet(false));
            yield put(getUrlSuccess(resp.payload));
            yield put(helpDocViewerOpenDialogSet(true));

        } catch (error) {
            yield put(getUrlFailure(error));
            handleSagaErrors(error);
        } finally {
            yield put(dataLoadingSet(false));
        }

    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* requestMonitor() {
    yield takeLatest(HELP_DOC_VIEWER_GET_URL_REQUEST, requestSaga);
}
