/**
 * @file loginToken.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 05, 2022 18:29
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import {
    AUTH_LOGIN_TOKEN_REQUEST,
    loginTokenSuccess,
    loginTokenFailure,
} from 'src/store/actions/auth.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
// import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(payload) {
    const url = '/auth/login/token';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            authToken: payload.authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga(action) {
    try {
        yield put(dataLoadingSet(true));

        const resp = yield call(callApi, action.payload);

        yield put(dataLoadingSet(false));
        yield put(loginTokenSuccess(resp.payload));

    } catch (error) {

        yield put(dataLoadingSet(false));
        yield put(loginTokenFailure(error));

        const targetPath = `/auth/signin`;
        return action.payload.navigate(targetPath);
    }
}

export default function* requestMonitor() {
    yield takeLatest(AUTH_LOGIN_TOKEN_REQUEST, requestSaga);
}
