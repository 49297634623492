/**
 * @file documentRoutes.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 09, 2023 17:08
 */
import { lazy } from 'react';
import Loader from './Loader';

const DocumentAnnotator = Loader(
    lazy(() => import('src/content/DocumentAnnotator/Annotator/AnnotatorContainer'))
);

const DocumentSearch = Loader(
    lazy(() => import('src/content/Document/Search/SearchContainer'))
);

const DocumentUploadPublication = Loader(
    lazy(() => import('src/content/Document/UploadPublication/UploadPublicationContainer'))
);

const DocumentUploadSupplement = Loader(
    lazy(() => import('src/content/Document/UploadSupplement/UploadSupplementContainer'))
);

const TableAnnotator = Loader(
    lazy(() => import('src/content/TableAnnotator/TableAnnotatorContainer'))
);

const documentRoutes = [
    {
        path: '',
        element: <DocumentSearch />
    },
    {
        path: 'search',
        element: <DocumentSearch />
    },
    {
        path: 'annotate',
        element: <DocumentAnnotator />
    },
    {
        path: 'tableAnnotate/:annotatorId/:tableId',
        element: <TableAnnotator />
    },
    {
        path: 'upload/publication',
        element: <DocumentUploadPublication />
    },
    {
        path: 'upload/supplement',
        element: <DocumentUploadSupplement />
    },
];

export default documentRoutes;