/**
 * @file shared.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 01, 2022 17:27
 */

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const ANNOTATION_ID_SET = 'ANNOTATION_ID_SET';
export function annotationIdSet(annotationId) {
    return {
        type: ANNOTATION_ID_SET,
        payload: annotationId,
    };
}

export const SHARED_DOCTYPE_DOCID_SET = 'SHARED_DOCTYPE_DOCID_SET';
export function docTypeDocIdSet(docType, docId) {
    return {
        type: SHARED_DOCTYPE_DOCID_SET,
        payload: {
            docType,
            docId,
        },
    };
}

export const ANNOTATION_VIEW_DIALOG_OPEN_SET = 'ANNOTATION_VIEW_DIALOG_OPEN_SET';
export function openAnnotationViewerDialogSet(value) {
    return {
        type: ANNOTATION_VIEW_DIALOG_OPEN_SET,
        payload: value,
    };
}
