/**
 * @file veriomeservices.main.jsx
 * @description application entry point
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 *  https://stackoverflow.com/questions/71668256/deprecation-notice-reactdom-render-is-no-longer-supported-in-react-18
 * 
 * @author don michael
 * @since Sep 14, 2023 21:39
 */
import 'src/index.css';

import ReactDOM from 'react-dom';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';

import { Provider } from 'react-redux';

import store from 'src/store';
import App from 'src/App';
import AppErrorBoundry from 'src/errorBoundry/AppErrorBoundry';

import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import Status500 from 'src/content/pages/Status/Status500';

// console.log('mode: ', import.meta.env.MODE);

// eslint-disable-next-line
ReactDOM.render(
    <AppErrorBoundry fallback={<Status500 />}>
        <HelmetProvider>
            <Provider store={store}>
                <SidebarProvider>
                    <BrowserRouter>
                        <ScrollTop />
                        <App />
                    </BrowserRouter>
                </SidebarProvider>
            </Provider>
        </HelmetProvider>
    </AppErrorBoundry>
    , document.getElementById('root')
);

serviceWorker.unregister();
