import { combineReducers } from '@reduxjs/toolkit';

import auth from 'src/store/reducers/auth/auth.reducer';

// viewers & editors
import annotationEditor from 'src/store/reducers/annotationEditor.reducer';
import annotationPdfViewer from 'src/store/reducers/annotator/annotationPdfViewer.reducer';

import annotationSearchDb from 'src/store/reducers/annotationSearch/annotationSearchDb.reducer';
import annotationSearchPages from 'src/store/reducers/annotationSearch/annotationSearchPages.reducer';

import annotationView from 'src/store/reducers/annotationView.reducer';

// dashboard
import dashboard from 'src/store/reducers/dashboard.reducer';

// document
import documentDb from 'src/store/reducers/document/documentDb.reducer';
import documentPages from 'src/store/reducers/document/documentPages.reducer';

// documentAnnotator
import documentAnnotatorDb from 'src/store/reducers/documentAnnotator/documentAnnotatorDb.reducer';
import documentAnnotatorPages from 'src/store/reducers/documentAnnotator/documentAnnotatorPages.reducer';

// helpDocViewer
import helpDocViewer from 'src/store/reducers/helpDocViewer.reducer';

// geneModel
import geneModelPages from 'src/store/reducers/geneModel/geneModelPages.reducer';
import geneModelDb from 'src/store/reducers/geneModel/geneModelDb.reducer';

import global from 'src/store/reducers/global.reducer';

// shared
import shared from 'src/store/reducers/shared.reducer';

// tableAnnotator
import tableAnnotatorDb from 'src/store/reducers/tableAnnotator/tableAnnotatorDb.reducer';
import tableAnnotatorPages from 'src/store/reducers/tableAnnotator/tableAnnotatorPages.reducer';

// sessionAnnotations
import sessionAnnotationsDb from 'src/store/reducers/sessionAnnotations/sessionAnnotationsDb.reducer';
import sessionAnnotationsPages from 'src/store/reducers/sessionAnnotations/sessionAnnotationsPages.reducer';

// variant
import variantPages from 'src/store/reducers/variant/variantPages.reducer';
import variantDb from 'src/store/reducers/variant/variantDb.reducer';

const rootReducer = combineReducers({
    auth,

    // // viewers & editors
    annotationEditor,
    annotationPdfViewer,

    annotationSearchDb,
    annotationSearchPages,

    annotationView,

    // dashboard
    dashboard,

    // document
    documentDb,
    documentPages,

    // documentAnnotator
    documentAnnotatorDb,
    documentAnnotatorPages,

    // helpDocViewer
    helpDocViewer,

    // geneModel  
    geneModelPages,
    geneModelDb,

    global,
    shared,

    // tableAnnotator
    tableAnnotatorDb,
    tableAnnotatorPages,

    // sessionAnnotations
    sessionAnnotationsDb,
    sessionAnnotationsPages,

    // variant 
    variantPages,
    variantDb,
});

export default rootReducer;
