/**
 * @file annotationView.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 08, 2023 10:11
 */

// -------------------------------------------------------------------
// for testing
// -------------------------------------------------------------------
export const ANNOTATION_VIEW_DATA_SET = 'ANNOTATION_VIEW_DATA_SET';
export function annotationViewDataSet(annotationViewData) {
    return {
        type: ANNOTATION_VIEW_DATA_SET,
        payload: annotationViewData,
    };
}

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const ANNOTATION_VIEW_ANNOTATION_ID_SET = 'ANNOTATION_VIEW_ANNOTATION_ID_SET';
export function annotationIdSet(annotationId) {
    return {
        type: ANNOTATION_VIEW_ANNOTATION_ID_SET,
        payload: annotationId,
    };
}

export const ANNOTATION_VIEW_DIALOG_OPEN_SET = 'ANNOTATION_VIEW_DIALOG_OPEN_SET';
export function annotationViewOpenDialogSet(value) {
    return {
        type: ANNOTATION_VIEW_DIALOG_OPEN_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// annotationView.annotationViewCreate.request
// -------------------------------------------------------------------
export const ANNOTATION_VIEW_CREATE_REQUEST = 'ANNOTATION_VIEW_CREATE_REQUEST';
export function annotationViewCreateRequest(annotationId) {
    return {
        type: ANNOTATION_VIEW_CREATE_REQUEST,
        payload: annotationId,
    };
}

export const ANNOTATION_VIEW_CREATE_SUCCESS = 'ANNOTATION_VIEW_CREATE_SUCCESS';
export function annotationViewCreateSuccess(payload) {
    return {
        type: ANNOTATION_VIEW_CREATE_SUCCESS,
        payload,
    };
}

export const ANNOTATION_VIEW_CREATE_FAILURE = 'ANNOTATION_VIEW_CREATE_FAILURE';
export function annotationViewCreateFailure(error) {
    return {
        type: ANNOTATION_VIEW_CREATE_FAILURE,
        payload: error,
    };
}

