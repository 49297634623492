/**
 * @file rootSaga.js
 * @description grand root saga
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 23, 2019 21:13
 */
import { all } from 'redux-saga/effects';

import annotationEditorSagas from 'src/store/sagas/annotationEditor/annotationEditorRoot.saga';
import annotationSearchSagas from 'src/store/sagas/annotationSearch/annotationSearchRoot.saga';
import annotationViewSagas from 'src/store/sagas/annotationView/annotationViewRoot.saga';
import annotatorSagas from 'src/store/sagas/annotator/annotatorRoot.saga';
import authSagas from 'src/store/sagas/auth/authRoot.saga';
import dashboardSagas from 'src/store/sagas/dashboard/dashboardRoot.saga';
import documentSagas from 'src/store/sagas/document/documentRoot.saga';
import documantAnnotatorSagas from 'src/store/sagas/documentAnnotator/documentAnnotatorRoot.saga';
import helpDocViewerSagas from 'src/store/sagas/helpDocViewer/helpDocViewerRoot.saga';
import geneModelSagas from 'src/store/sagas/geneModel/geneModelRoot.saga';
import sharedSagas from 'src/store/sagas/shared/sharedRoot.saga';
import sessionAnnotationsSagas from 'src/store/sagas/sessionAnnotations/sessionAnnotationsRoot.saga';
import tableAnnotatorSagas from 'src/store/sagas/tableAnnotator/tableAnnotatorRoot.saga';
import variantSagas from 'src/store/sagas/variant/variantRoot.saga';

/**
 * register all sagas
 */
export default function* rootSaga() {
    yield all([
        annotationEditorSagas(),
        annotationSearchSagas(),
        annotationViewSagas(),
        annotatorSagas(),
        authSagas(),
        dashboardSagas(),
        documentSagas(),
        documantAnnotatorSagas(),
        helpDocViewerSagas(),
        geneModelSagas(),
        sharedSagas(),
        tableAnnotatorSagas(),
        sessionAnnotationsSagas(),
        variantSagas(),
    ]);
}
