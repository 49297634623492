/**
 * @file otpSubmit.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 11, 2023 20:31
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import { getToken } from 'src/auth/authUtilities';

import standardMessages from 'src/services/notifications/standardMessages';

import { stringExists } from 'src/@common/utilities';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import {
    AUTH_OTP_SUBMIT_REQUEST,
    otpSubmitSuccess,
    otpSubmitFailure,
} from 'src/store/actions/auth.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';

function callApi(identityToken, payload) {
    const url = '/auth/otpSubmit';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            identityToken,
            otpToken: payload.otpToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga(action) {

    const identityToken = getToken('identity');

    if (identityToken) {

        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, identityToken, action.payload);
            yield put(dataLoadingSet(false));
            yield put(otpSubmitSuccess(resp.payload));

            if (stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.generalInfoMessage.options)
                );
            }

            const targetPath = `/dashboard`;
            return action.payload.navigate(targetPath);

        } catch (error) {

            yield put(dataLoadingSet(false));
            yield put(otpSubmitFailure(error));

            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.payload &&
                stringExists(error.response.data.payload.serverMessage)) {

                yield put(enqueueMessage(
                    error.response.data.payload.serverMessage,
                    standardMessages.serverError.options)
                );
            }
        }
    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* requestMonitor() {
    yield takeLatest(AUTH_OTP_SUBMIT_REQUEST, requestSaga);
}
