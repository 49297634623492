/**
 * @file tableAnnotatorRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 03, 2022 15:09
 */
import { all, fork } from 'redux-saga/effects';

import tableAnnotate from './tableAnnotate.saga';
import tableAnnotationsFind from './tableAnnotationsFind.saga';
import deleteTableAnnotations from './deleteTableAnnotations.saga';
import generateAnnotations from './generateAnnotations.saga';
import submitAnnotationType from './submitAnnotationType.saga';
import submitClassificationCode from './submitClassificationCode.saga';
import submitTableSettings from './submitTableSettings.saga';

export default function* rootSaga() {
    yield all([

        fork(tableAnnotate),
        fork(tableAnnotationsFind),
        fork(deleteTableAnnotations),
        fork(generateAnnotations),
        fork(submitAnnotationType),
        fork(submitClassificationCode),
        fork(submitTableSettings),

    ]);
}