/**
 * @file dashboardRoutes.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 19, 2023 17:31
 */
import { lazy } from 'react';

import Loader from './Loader';

// Dashboards
const Dashboard = Loader(
    lazy(() => import('src/content/Dashboard/DashboardContainer'))
);

const dashboardRoutes = [
    {
        path: '',
        element: <Dashboard />
    },
];

export default dashboardRoutes;