/**
 * @file sessionAnnotationsRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 07, 2023 10:48
 */
import { all, fork } from 'redux-saga/effects';

import sessionAnnotationsDelete from 'src/store/sagas/sessionAnnotations/sessionAnnotationsDelete.saga';
import sessionAnnotationsFindMetadata from 'src/store/sagas/sessionAnnotations/sessionAnnotationsFindMetadata.saga';
import sessionAnnotationsInitialQuery from 'src/store/sagas/sessionAnnotations/sessionAnnotationsInitialQuery.saga';
import sessionAnnotationsPageQuery from 'src/store/sagas/sessionAnnotations/sessionAnnotationsPageQuery.saga';
import sessionAnnotationsRefresh from 'src/store/sagas/sessionAnnotations/sessionAnnotationsRefresh.saga';

export default function* rootSaga() {
    yield all([

        // annotations table initial query
        fork(sessionAnnotationsDelete),
        fork(sessionAnnotationsFindMetadata),
        fork(sessionAnnotationsInitialQuery),
        fork(sessionAnnotationsPageQuery),
        fork(sessionAnnotationsRefresh),

    ]);
}