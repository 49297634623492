/**
 * @file geneModelDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 02, 2022 15:54
 */
import { objectDeepCopy, isDiff, stringExists } from 'src/@common/utilities';
import * as Actions from 'src/store/actions/geneModel/geneModelDb.actions';

const initialState = {

    dataLoaded: false,

    disease: {}, // new geneDisease

    geneModel: {},
    geneModels: [],
    geneModelId: null,

    geneModelView: {},

    geneDisease: {},
    geneDiseases: [],
    geneDiseaseId: null,

    // geneModel.new
    geneSymbol: '',

    classificationSchema: {},
    classificationCodes: [],

    geneDiseaseClassification: {},
    geneDiseaseClassifications: [],
    geneDiseaseClassificationsRefresh: false,

    // geneDisease.classification.evidence 
    geneDiseaseClassificationEvidenceObj: {
        countObj: {},
        pageNo: 1,
        pageObjects: [],
        pageMap: {},
        pageSize: 20,
        queryObj: {},
    },

    // charts
    geneChartData: {},
    geneDiseaseChartData: {},

    // support
    geneDiseaseSchema: {},
    geneModelSchema: {},

    // dirty data indicators
    geneDiseaseClassificationDirty: false,
    geneModelDirty: false,
    geneDiseaseDirty: false,

    // page mode indicators ('edit' or 'new')
    geneModelFormMode: '',
    geneDiseaseFormMode: '',
    geneDiseaseClassificationFormMode: '',

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const geneModelDbReducer = (state = initialState, action) => {

    let fieldName = null;
    let fieldValue = null;
    let objCopy = null;
    let objIndex = null;
    let _id = null;
    let geneModelClone = null;
    let objId = null;
    let tmpObj = {};
    let tmpStateObj = null;
    let tmpServerObj = null;
    let tmpPageMap = null;

    switch (action.type) {

        // testing and development setters
        // -------------------------------------------------------------------

        case Actions.GENE_MODEL_SET: {
            return {
                ...state,
                geneModel: action.payload,
            };
        }

        case Actions.GENE_DISEASE_SET: {
            return {
                ...state,
                geneDisease: action.payload,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_SET: {
            return {
                ...state,
                geneDiseaseClassification: action.payload,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_CODES_SET: {
            return {
                ...state,
                classificationCodes: action.payload,
            };
        }

        // setters
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_ID_SET: {
            return {
                ...state,
                geneModelId: action.payload,
            };
        }

        // form mode indicators
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_FORM_MODE_SET: {
            return {
                ...state,
                geneModelFormMode: action.payload,
            };
        }

        case Actions.GENE_DISEASE_FORM_MODE_SET: {
            return {
                ...state,
                geneDiseaseFormMode: action.payload,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_FORM_MODE_SET: {
            return {
                ...state,
                geneDiseaseClassificationFormMode: action.payload,
            };
        }

        // geneModel.find
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_FIND_REQUEST:
            return {
                ...state,
            };

        case Actions.GENE_MODEL_FIND_SUCCESS:
            return {
                ...state,
                geneModelDirty: false,
                geneModel: action.payload.geneModel,
                geneModelSchema: action.payload.geneModelSchema,
            };

        case Actions.GENE_MODEL_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // geneModel.new
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_GENE_SYMBOL_RESET: {
            return {
                ...state,
                geneSymbol: '',
            };
        }

        case Actions.GENE_MODEL_GENE_SYMBOL_UPDATE_FIELD: {
            return {
                ...state,
                geneSymbol: action.payload,
            };
        }

        case Actions.GENE_MODEL_NEW_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_MODEL_NEW_SUCCESS: {
            return {
                ...state,
                geneModelId: action.payload.geneModelId,
                geneModelDirty: false,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_MODEL_NEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneModel.search
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_SEARCH_REQUEST:
            return {
                ...state,
            };

        case Actions.GENE_MODEL_SEARCH_SUCCESS:

            return {
                ...state,
                geneModels: action.payload.geneModels,
            };

        case Actions.GENE_MODEL_SEARCH_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // geneModel.geneModelUpdate
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_MODEL_UPDATE_SUCCESS: {
            return {
                ...state,
                geneModel: action.payload.geneModel,
                geneModelDirty: false,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_MODEL_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneModels.delete
        // -------------------------------------------------------------------
        case Actions.GENE_MODELS_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_MODELS_DELETE_SUCCESS: {
            return {
                ...state,
                geneModels: action.payload.geneModels,
            };
        }

        case Actions.GENE_MODELS_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneModel.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                geneModelClone = objectDeepCopy(state.geneModel);

                switch (fieldName) {
                    default:
                        geneModelClone[fieldName] = fieldValue;

                        return {
                            ...state,
                            geneModel: geneModelClone,
                            geneModelDirty: true,
                        };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // geneModel.view
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_VIEW_REQUEST:
            return {
                ...state,
            };

        case Actions.GENE_MODEL_VIEW_SUCCESS:
            return {
                ...state,
                geneModelView: action.payload,
            };


        case Actions.GENE_MODEL_VIEW_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // geneDisease.classification.evidence.initialQuery
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS: {

            tmpObj = {
                pageNo: action.payload.pageNo,
                pageObjects: action.payload.pageObjects,
                pageMap: action.payload.pageMap,
                pageSize: action.payload.pageSize, // informational only. not changed here.
                queryObj: action.payload.queryObj,
                countObj: action.payload.countObj,
            };

            return {
                ...state,
                geneDiseaseClassificationEvidenceObj: objectDeepCopy(tmpObj),
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classification.evidence.pageQuery
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS: {

            tmpObj = objectDeepCopy(state.geneDiseaseClassificationEvidenceObj);

            tmpObj.pageNo = action.payload.pageNo;
            tmpObj.pageObjects = action.payload.pageObjects;

            return {
                ...state,
                geneDiseaseClassificationEvidenceObj: objectDeepCopy(tmpObj),
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classificationEvidence.approval.set
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET: {

            fieldValue = action.payload.value;
            _id = action.payload._id;

            objCopy = objectDeepCopy(state.geneDiseaseClassificationEvidenceObj);

            objIndex = objCopy.pageObjects.findIndex((item) => {
                return item._id === _id;
            });

            if (objIndex !== -1) {
                objCopy.pageObjects[objIndex].approved = fieldValue;

                // if approved, move the _id as the new evidenceId
                if (fieldValue === true) {
                    objCopy.pageObjects[objIndex].evidenceId = objCopy.pageObjects[objIndex]._id;
                }

                return {
                    ...state,
                    geneDiseaseClassificationEvidenceObj: objCopy,
                };

            } else {

                return {
                    ...state,
                };
            }

        }

        // geneDisease.classificationEvidence.delete
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS: {

            tmpStateObj = objectDeepCopy(state.geneDiseaseClassificationEvidenceObj);
            tmpServerObj = objectDeepCopy(action.payload.evidenceObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                geneDiseaseClassificationEvidenceObj: tmpStateObj,
            };

        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classificationEvidence.insert
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.geneDiseaseClassificationEvidenceObj);

            tmpServerObj = objectDeepCopy(action.payload.evidenceObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                geneDiseaseClassificationEvidenceObj: tmpStateObj,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classificationEvidence.reset
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_EVIDENCE_RESET: {
            return {
                ...state,
                geneDiseaseClassificationEvidenceObj: {
                    pageNo: 1,
                    pageObjects: [],
                    pageMap: {},
                    pageSize: 20,
                    queryObj: {},
                    countObj: {},
                },
            };
        }

        // geneDisease.qualifyingAnnotations.find
        // -------------------------------------------------------------------
        // case Actions.GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST: {
        //     return {
        //         ...state,
        //         qualifyingAnnotations: [],
        //     };
        // }

        // case Actions.GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS: {
        //     return {
        //         ...state,
        //         qualifyingAnnotations: action.payload.qualifyingAnnotations,
        //     };
        // }

        // case Actions.GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE: {
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };
        // }

        // geneDisease.find - finds a single geneDisease
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_FIND_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_FIND_SUCCESS: {
            return {
                ...state,
                geneDisease: action.payload.geneDisease,
                geneDiseaseSchema: action.payload.geneDiseaseSchema,
                geneDiseaseDirty: false,
                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case Actions.GENE_DISEASE_RESET: {
            return {
                ...state,
                geneDisease: {},
                geneDiseaseId: null,
                geneDiseaseClassification: {},
                geneDiseaseClassificationEvidenceObj: {},
                geneDiseaseDirty: false,
                geneDiseaseClassificationDirty: false,
            };
        }

        // geneDisease.new - creates a geneDisease dialog
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_NEW_REQUEST: {
            return {
                geneDiseaseId: null,
                ...state,
            };
        }

        case Actions.GENE_DISEASE_NEW_SUCCESS: {
            return {
                ...state,
                geneModelId: action.payload.geneModelId,
                geneDiseaseId: action.payload.geneDiseaseId,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_NEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.new.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_NEW_UPDATE_FIELD: {
            fieldValue = action.payload.fieldValue;

            switch (fieldName) {
                default:
                    return {
                        ...state,
                        disease: fieldValue,
                    };
            }
        }

        // geneDisease.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                objCopy = null;

                switch (fieldName) {

                    default:
                        objCopy = objectDeepCopy(state.geneDisease);
                        objCopy[fieldName] = fieldValue;

                        return {
                            ...state,
                            geneDisease: objCopy,
                            geneDiseaseDirty: true,
                        };
                }

            }


            return {
                ...state,
            };
        }

        // geneDisease.update - returns updated geneDisease
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_UPDATE_SUCCESS: {
            return {
                ...state,
                geneDisease: action.payload.geneDisease,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseases.find - gets all geneDiseases for a single geneModel
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASES_FIND_REQUEST: {
            return {
                ...state,
                geneDiseases: [],
            };
        }

        case Actions.GENE_DISEASES_FIND_SUCCESS: {
            return {
                ...state,
                geneDiseases: action.payload.geneDiseases,
            };
        }

        case Actions.GENE_DISEASES_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseases.delete
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASES_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASES_DELETE_SUCCESS: {
            return {
                ...state,
                geneDiseases: action.payload.geneDiseases,
            };
        }

        case Actions.GENE_DISEASES_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // classifications.delete
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATIONS_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATIONS_DELETE_SUCCESS: {
            return {
                ...state,
                classifications: action.payload.classifications,
                geneDiseaseClassificationsRefresh: true,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATIONS_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassifications.find
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATIONS_FIND_REQUEST: {
            return {
                ...state,
                geneDiseaseClassifications: [],
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATIONS_FIND_SUCCESS: {
            return {
                ...state,
                geneDiseaseClassificationsRefresh: false,
                geneDiseaseClassifications: action.payload.geneDiseaseClassifications,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATIONS_FIND_FAILURE: {
            return {
                ...state,
                geneDiseaseClassificationsRefresh: false,
                error: action.payload,
            };
        }

        // geneDiseaseClassifications.refresh
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATIONS_REFRESH_SET: {
            return {
                ...state,
                geneDiseaseClassificationsRefresh: action.payload,
            };
        }

        // geneDisease.classification.reset
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_RESET: {
            return {
                ...state,
                geneDiseaseClassification: {},
                geneDiseaseClassificationEvidenceObj: {},
                geneDiseaseClassificationDirty: false,
                geneDiseaseClassificationFormMode: '',
            };
        }

        // geneDiseaseClassification.delete
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_DELETE_SUCCESS: {
            return {
                ...state,
                dataLoaded: true,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.find
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_FIND_REQUEST: {
            return {
                ...state,
                geneDiseaseClassification: {},
                geneDiseaseClassificationDirty: false,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_FIND_SUCCESS: {
            return {
                ...state,
                dataLoaded: true,
                geneDiseaseClassificationFormMode: 'edit',
                geneDiseaseClassification: action.payload.geneDiseaseClassification,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.insert
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_INSERT_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_INSERT_SUCCESS: {

            return {
                ...state,
                geneDiseaseClassification: action.payload.geneDiseaseClassification,
                geneDiseaseClassificationDirty: false,
                classificationCodes: action.payload.classificationCodes,
                classificationSchema: action.payload.classificationSchema,
                geneDiseaseClassificationsRefresh: true,

                serverErrorExists: action.payload.errorExits,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_INSERT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.update
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_UPDATE_SUCCESS: {

            return {
                ...state,
                geneDiseaseClassification: action.payload.geneDiseaseClassification,
                geneDiseaseClassificationDirty: false,
                geneDiseaseClassificationsRefresh: true,

                serverErrorExists: action.payload.errorExits,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.update.field - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                if (isDiff(state.geneDiseaseClassification[fieldName], fieldValue)) {

                    objCopy = null;

                    switch (fieldName) {

                        default:
                            objCopy = objectDeepCopy(state.geneDiseaseClassification);
                            objCopy[fieldName] = fieldValue;

                            return {
                                ...state,
                                geneDiseaseClassification: objCopy,
                                geneDiseaseClassificationDirty: true,
                            };
                    }

                } else {
                    return {
                        ...state,
                    };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // geneDiseaseClassification.new
        // -------------------------------------------------------------------
        case Actions.GENE_DISEASE_CLASSIFICATION_NEW_REQUEST: {
            return {
                ...state,
                classification: {},
                classificationCodes: [],
                geneDiseaseClassificationDirty: false,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_NEW_SUCCESS: {
            return {
                ...state,
                geneDiseaseClassification: action.payload.geneDiseaseClassification,
                classificationCodes: action.payload.classificationCodes,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_NEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.annotationDistribCharts
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_DISTRIB_CHARTS_REQUEST: {
            return {
                ...state,
                geneChartData: {},
                geneDiseaseChartData: {},
            };
        }

        case Actions.ANNOTATION_DISTRIB_CHARTS_SUCCESS: {
            return {
                ...state,
                geneChartData: action.payload.geneChartData,
                geneDiseaseChartData: action.payload.geneDiseaseChartData,
            };
        }

        case Actions.ANNOTATION_DISTRIB_CHARTS_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        /************* */
        default: {
            return state;
        }
    }
}

export default geneModelDbReducer;