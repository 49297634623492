/**
 * @file utilities.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 * 
 * https://www.npmjs.com/package/deep-object-diff
 * 
 * @author don michael
 * @since Jan 04, 2022 18:19
 */
import _ from "lodash";

import { isNumber } from 'src/@common/validatorUtilities';

export function arrayDeepCopy(arr) {
    let arrayCopy = null;

    if (arr) {
        arrayCopy = _.cloneDeep(arr)
    }

    return arrayCopy;
}

export function arrayExists(arr) {
    let exists = false;

    if (arr) {
        if (arr.length > 0) {
            exists = true;
        }
    }

    return exists;
}

export function commaDelimitedStringToArray(stringToParse) {
    let stringArray = [];

    if (stringExists(stringToParse)) {
        let splitArray = stringToParse.split(',');
        stringArray = splitArray.map((item) => {
            return item.trim();
        });
    }

    return stringArray;
}

export function filterArrayByString(mainArr, searchText) {
    if (searchText === '') {
        return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
}

export function objectDeepCopy(obj) {
    let objCopy = null;

    if (obj) {
        objCopy = _.cloneDeep(obj)
    }

    return objCopy;
}

export function objectExists(obj) {
    // object must exist and have keys
    let objExists = false;

    if (_.isObject(obj)) {
        const keys = Object.keys(obj);
        if (keys) {
            if (keys.length > 0) {
                objExists = true;
            }
        }
    }

    return objExists;
}

export function objectIsEmpty(obj) {
    let isEmpty = true;

    if (obj !== null && obj !== undefined) {
        const keys = Object.keys(obj);
        if (keys) {
            if (keys.length > 0) {
                isEmpty = false;
            }
        }
    }

    return isEmpty;
}

export function objectKeyExists(obj, key) {
    let exists = false;

    if (obj) {
        const hasProperty = Object.prototype.hasOwnProperty.call(obj, key);

        if (hasProperty) {
            exists = true;
        }
    }

    return exists;
}

/**
 * returns only the values that have been changed in the updated object
 */
export function objectUpdatedDiff(originalObj, updatedObj) {
    // https://stackoverflow.com/questions/31683075/how-to-do-a-deep-comparison-between-2-objects-with-lodash

    let diff = {};

    // find properties that are different
    const diffProperties = Object.keys(originalObj).reduce((result, key) => {
        const hasProperty = Object.prototype.hasOwnProperty.call(updatedObj, key);
        if (!hasProperty) {
            result.push(key);
        } else if (_.isEqual(originalObj[key], updatedObj[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(updatedObj));

    if (arrayExists(diffProperties)) {
        // create an object containing the changed properties
        diffProperties.forEach((fieldName) => {
            diff[fieldName] = updatedObj[fieldName];
        });
    }
    return diff;
}

export function isDiff(originalValue, updatedValue) {

    let isDifferent = false;

    if (_.isObject(originalValue)) {

        const diff = objectUpdatedDiff(originalValue, updatedValue);
        if (objectExists(diff)) {
            isDifferent = true;
        }

    } else {

        if (originalValue !== updatedValue) {
            isDifferent = true;
        }
    }

    return isDifferent;
}

export function numberExists(number) {
    let exists = false;

    if (number) {

        if (isNumber(number)) {
            exists = true;
        }

    }

    return exists;
}

export function semicolonDelimitedStringToArray(stringToParse) {
    let stringArray = [];

    if (stringExists(stringToParse)) {
        let splitArray = stringToParse.split(';');
        stringArray = splitArray.map((item) => {
            return item.trim();
        });
    }

    return stringArray;
}

export function stringExists(str) {
    let exists = false;

    if (str) {
        if (typeof str === 'string') {
            if (str !== '') {
                exists = true;
            }
        } else {
            throw new Error(`${str} must be a string`);
        }
    }

    return exists;
}

export function formatTextWrap(text, maxLineLength) {
    //https://codereview.stackexchange.com/questions/171832/text-wrapping-function-in-javascript
    const words = text.replace(/[\r\n]+/g, ' ').split(' ');
    let lineLength = 0;

    // use functional reduce, instead of for loop 
    return words.reduce((result, word) => {
        if (lineLength + word.length >= maxLineLength) {
            lineLength = word.length;
            return result + `\n${word}`; // don't add spaces upfront
        } else {
            lineLength += word.length + (result ? 1 : 0);
            return result ? result + ` ${word}` : `${word}`; // add space only when needed
        }
    }, '');
}