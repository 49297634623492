/**
 * @file annotationSearchPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 06, 2023 17:59
 */

// -------------------------------------------------------------------
// annotationSearch.setup
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_ANNOTATOR_ID_SET = 'ANNOTATION_SEARCH_ANNOTATOR_ID_SET';
export function annotationSearchAnnotatorIdSet(annotatorId) {
    return {
        type: ANNOTATION_SEARCH_ANNOTATOR_ID_SET,
        payload: annotatorId,
    };
}

export const ANNOTATION_SEARCH_DOC_ID_SET = 'ANNOTATION_SEARCH_DOC_ID_SET';
export function annotationSearchDocIdSet(docId) {
    return {
        type: ANNOTATION_SEARCH_DOC_ID_SET,
        payload: docId,
    };
}

export const ANNOTATION_SEARCH_DOC_TYPE_SET = 'ANNOTATION_SEARCH_DOC_TYPE_SET';
export function annotationSearchDocTypeSet(docType) {
    return {
        type: ANNOTATION_SEARCH_DOC_TYPE_SET,
        payload: docType,
    };
}

export const ANNOTATION_SEARCH_METHOD_SET = 'ANNOTATION_SEARCH_METHOD_SET';
export function searchMethodSet(searchMethod) {
    return {
        type: ANNOTATION_SEARCH_METHOD_SET,
        payload: searchMethod,
    };
}

export const ANNOTATION_SEARCH_TERM_SET = 'ANNOTATION_SEARCH_TERM_SET';
export function searchTermSet(searchTerm) {
    return {
        type: ANNOTATION_SEARCH_TERM_SET,
        payload: searchTerm,
    };
}

// -------------------------------------------------------------------
// annotationSearch.annotatorEditor
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_ANNOTATION_EDITOR_DIALOG_OPEN = 'ANNOTATION_SEARCH_ANNOTATION_EDITOR_DIALOG_OPEN';
export function annotationEditorDialogOpenSet(value) {
    return {
        type: ANNOTATION_SEARCH_ANNOTATION_EDITOR_DIALOG_OPEN,
        payload: value,
    };
}