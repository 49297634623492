/**
* @file authRoot.saga.js
* @description login root saga
*
* @copyright veriome, llc. - all rights reserved.
* This file is subject to the terms and conditions defined in
* file 'LICENSE.md', which is part of this source code package.
*
* @author don michael
* @since Mon Sep 23 2019
*/
import { all, fork } from 'redux-saga/effects';

import loginPassword from 'src/store/sagas/auth/loginPassword.saga';
import loginToken from 'src/store/sagas/auth/loginToken.saga';
import otpSubmit from 'src/store/sagas/auth/otpSubmit.saga';
import otpSetup from 'src/store/sagas/auth/otpSetup.saga';

export default function* rootSaga() {
    yield all([
        fork(loginPassword),
        fork(loginToken),
        fork(otpSubmit),
        fork(otpSetup),
    ]);
}
