
/**
 * @file store.index.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
 * 
 * @author don michael
 * @since Sep 04, 2023 13:28
 */
import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
} from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // ignore these action types
                ignoredActions: [
                    'AUTH_LOGIN_PASSWORD_REQUEST',
                    'AUTH_LOGIN_TOKEN_REQUEST',
                    'ANNOTATION_VIEW_CREATE_REQUEST',
                    'DOCUMENT_ANNOTATE_PUBLICATION_REQUEST',
                    'GENE_MODEL_NEW_REQUEST',
                    'GENE_MODEL_VIEW_REQUEST',
                    'GENE_DISEASE_NEW_REQUEST',
                    'DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST',
                    'AUTH_OTP_SUBMIT_REQUEST',
                    'TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST',
                    'VARIANT_DISEASES_INITIAL_QUERY_REQUEST',
                    'VARIANT_FIND_REQUEST',
                    'VARIANT_NEW_REQUEST',
                    'VARIANT_VIEW_REQUEST',
                ],
            },
        }).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga);

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
