/**
 * @file AuthLayout.index.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 06, 2023 19:08
 */
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {
    Box,
    Divider,
    // Link,
    Typography,
    Stack,
    styled
} from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';

const Content = styled(Box)(
    () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
    ({ theme }) => `
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0 0 0 500px;
    }
    width: 100%;
    display: flex;
    align-items: center;
`
);

const SidebarContent = styled(Box)(
    ({ theme }) => `
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 500px;
  background: ${theme.colors.gradients.blue3};
`
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

const DividerWrapper = styled(Divider)(
    ({ theme }) => `
      background: ${theme.colors.alpha.trueWhite[10]};
`
);

const AuthLayout = ({ children }) => {

    return (
        <Content>
            <SidebarWrapper
                sx={{
                    display: { xs: 'none', md: 'inline-block' },
                }}
            >
                <Scrollbar>
                    <SidebarContent>

                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <img
                                className="w-64"
                                src="/static/images/logo/vlogo.png"
                                alt="logo"
                            />
                            <TypographyPrimary
                                align="center"
                                variant="h3"
                                sx={{
                                    mt: 15,
                                    mb: 4,
                                    px: 8
                                }}
                            >
                                {'Veriome™'}
                            </TypographyPrimary>
                        </Stack>

                        <DividerWrapper
                            sx={{
                                mt: 3,
                                mb: 4
                            }}
                        />
                        <Box>
                            <TypographyPrimary
                                variant="h3"
                                sx={{
                                    mb: 3
                                }}
                            >
                                {'Empowered Gene Variant Analysis'}
                            </TypographyPrimary>

                        </Box>
                    </SidebarContent>
                </Scrollbar>
            </SidebarWrapper>

            <MainContent>

                {children || <Outlet />}

            </MainContent>

        </Content >

    );
};

AuthLayout.propTypes = {
    children: PropTypes.node
};

export default AuthLayout;
