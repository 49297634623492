/**
 * @file authUtilities.js
 * @description auth utilities. dealing mostly with authToken in localStorage
 *
 * @copyright veriome labs, llc - all rights reserved.
 * unauthorized copying of this file, via any medium, is strictly prohibited.
 * proprietary and confidential.
 *
 * @author don michael
 * @since 11/30/17 at 12:47 PM
 */
import store from 'src/store';
import { arrayExists, objectKeyExists, stringExists } from 'src/@common/utilities';

const authTokenName = 'veriome-authToken';
const identityTokenName = 'veriome-identityToken';

// authToken
export function saveToken(token, tokenType) {
    switch (tokenType) {
        case 'auth':
            localStorage.setItem(authTokenName, token);
            break;
        case 'identity':
            localStorage.setItem(identityTokenName, token);
            break;
        default:
            console.error('saveToken: unknown tokenType')
            break;
    }
}

export function getToken(tokenType) {
    let token = '';

    switch (tokenType) {
        case 'auth':
            try {
                token = localStorage.getItem(authTokenName);
            } catch (err) {
                console.error(err);
                removeToken('auth');
            }
            break;
        case 'identity':
            try {
                token = localStorage.getItem(identityTokenName);
            } catch (err) {
                console.error(err);
                removeToken('identity');
            }
            break;
        default:
            console.error('getToken: unknown tokenType')
            break;
    }

    return token;
}

export function removeToken(tokenType) {

    switch (tokenType) {
        case 'auth':
            localStorage.removeItem(authTokenName);
            break;
        case 'identity':
            localStorage.removeItem(identityTokenName);
            break;
        default:
            console.error('removeToken: unknown tokenType')
            break;
    }

}

export function userHasRole(role) {
    // uses redux state to determine user.roles.
    // must be used carefully to ensure the user.roles
    // exist in redux when the function is used
    let hasRole = false;

    if (stringExists(role)) {

        let authState = store.getState().auth;

        if (objectKeyExists(authState, 'user')) {

            if (arrayExists(authState.user.roles)) {

                const existingRole = authState.user.roles.find((item) => {
                    return item === role;
                });

                if (existingRole) {
                    hasRole = true;
                }
            }
        }
    }

    return hasRole;
}