/**
 * @file variantPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 10:40
 */

// -------------------------------------------------------------------
// variantResetPagesState
// -------------------------------------------------------------------
export const VARIANT_PAGES_RESET_STATE = 'VARIANT_PAGES_RESET_STATE';
export function variantResetPagesState() {
    return {
        type: VARIANT_PAGES_RESET_STATE,
    };
}

// -------------------------------------------------------------------
// variant.search.session
// -------------------------------------------------------------------
export const VARIANT_SEARCH_SESSION_SET = 'VARIANT_SEARCH_SESSION_SET';
export function variantSearchSessionSet(filterText, searchMethod, searchTerm) {
    return {
        type: VARIANT_SEARCH_SESSION_SET,
        payload: {
            filterText,
            searchMethod,
            searchTerm,
        },
    };
}

// -------------------------------------------------------------------
// variant.search
// -------------------------------------------------------------------
export const VARIANT_SEARCH_UPDATE_FIELD = 'VARIANT_SEARCH_UPDATE_FIELD';
export function variantSearchUpdateField(fieldName, fieldValue) {
    return {
        type: VARIANT_SEARCH_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// variantDisease.classification.classificationType
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_TYPE_SET = 'VARIANT_DISEASE_CLASSIFICATION_TYPE_SET';
export function variantDiseaseClassificationTypeSet(value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_TYPE_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variantDisease.classification dialog (with evidence)
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_DIALOG_SET = 'VARIANT_DISEASE_CLASSIFICATION_DIALOG_SET';
export function variantDiseaseClassificationDialogSet(value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationEvidence dialog
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DIALOG_SET = 'VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DIALOG_SET';
export function variantDiseaseClassificationEvidenceDialogSet(value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variantDisease.classificationView dialog
// -------------------------------------------------------------------
export const VARIANT_DISEASE_CLASSIFICATION_VIEW_DIALOG_SET = 'VARIANT_DISEASE_CLASSIFICATION_VIEW_DIALOG_SET';
export function variantDiseaseClassificationViewDialogSet(value) {
    return {
        type: VARIANT_DISEASE_CLASSIFICATION_VIEW_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variantDisease.new dialog
// -------------------------------------------------------------------
export const VARIANT_DISEASE_NEW_DIALOG_SET = 'VARIANT_DISEASE_NEW_DIALOG_SET';
export function variantDiseaseNewDialogSet(value) {
    return {
        type: VARIANT_DISEASE_NEW_DIALOG_SET,
        payload: value,
    };
}

export const VARIANT_CLASSIFICATION_VIEW_OPEN_DIALOG_SET = 'VARIANT_CLASSIFICATION_VIEW_OPEN_DIALOG_SET';
export function classificationViewDialogSet(value) {
    return {
        type: VARIANT_CLASSIFICATION_VIEW_OPEN_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// geneDisease.annotations dialog
// -------------------------------------------------------------------
export const VARIANT_GENE_DISEASE_ANNOTATIONS_DIALOG_SET = 'VARIANT_GENE_DISEASE_ANNOTATIONS_DIALOG_SET';
export function geneDiseaseAnnotationsDialogSet(value) {
    return {
        type: VARIANT_GENE_DISEASE_ANNOTATIONS_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationAnnotations dialog
// -------------------------------------------------------------------
export const VARIANT_GENE_CLASSIFICATION_EVIDENCE_DIALOG_SET = 'VARIANT_GENE_CLASSIFICATION_EVIDENCE_DIALOG_SET';
export function geneDiseaseClassificationAnnotationsDialogSet(value) {
    return {
        type: VARIANT_GENE_CLASSIFICATION_EVIDENCE_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variant.diseaseView dialog
// -------------------------------------------------------------------
export const VARIANT_DISEASE_VIEW_DIALOG_SET = 'VARIANT_DISEASE_VIEW_DIALOG_SET';
export function diseaseViewOpenDialogSet(value) {
    return {
        type: VARIANT_DISEASE_VIEW_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variant.new
// -------------------------------------------------------------------
export const VARIANT_NEW_DIALOG_SET = 'VARIANT_NEW_DIALOG_SET';
export function variantNewDialogSet(value) {
    return {
        type: VARIANT_NEW_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// variant.view
// -------------------------------------------------------------------
export const VARIANT_VIEW_DIALOG_SET = 'VARIANT_VIEW_DIALOG_SET';
export function variantViewDialogSet(value) {
    return {
        type: VARIANT_VIEW_DIALOG_SET,
        payload: value,
    };
}