/**
 * @file Markerio.js
 * @description external user visual feedback tool.
 *              integrates with jira.
 * 
 * https://marker.io/
 * https://help.marker.io/en/articles/8434854-integrating-marker-io-in-a-react-next-js-13-environment
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Nov 28, 2023 18:06
 */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import markerSDK from '@marker.io/browser';

export default function MarkerComponent() {
    // start: redux state --------------------------
    const authState = useSelector((state) => {
        return state.auth;
    });

    const { authenticated } = authState;
    // end: redux state -----------------------------

    useEffect(() => {
        if (authenticated === true) {
            markerSDK.loadWidget({
                project: '65667f0f7d819ae88490a8ee',
            });

            if (window.Marker) {
                window.Marker.show();
            }

        }
    }, [authenticated]);

    return null;
}