/**
 * @file logger.js
 * @description client-side logger.
 *              ships logs to server
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 22, 2022 16:00
 */
import axios from 'axios';

function shipLog(logPacket) {
    const authToken = localStorage.getItem('veriome-authToken');

    if (authToken) {

         
        const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

        const config = {
            method: 'post',
            baseURL: baseUrl,
            url: '/logger/shipClientLog',
            headers: {
                Authorization: authToken,
            },
            data: {
                logPacket,
            },
        };

        axios(config)
            .then(() => {
            })
            .catch((err) => {
                console.error(err);
            });
    }
}

/**
 * logger
 */
function logger(message = '', metadata = {}) {
    // eslint-disable-next-line no-undef
    const nodeEnv = process.env.NODE_ENV;

    metadata.timestamp = new Date();

    if (nodeEnv !== 'production') {
        // don't delete this console.log!
        console.log(message, metadata);
    }

    let logPacket = {
        service: 'client.logger',
        nodeEnv,
        msg: message,
        metadata,
        timestamp: new Date().toISOString(),
    };

    shipLog(logPacket);
}

export default logger;
