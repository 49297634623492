/**
 * @file sessionAnnotationsPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 06, 2023 17:59
 */

// -------------------------------------------------------------------
// sessionAnnotations.setupFields
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_SETUP_FIELDS_SET = 'SESSION_ANNOTATIONS_SETUP_FIELDS_SET';
export function sessionAnnotationsSetupFieldsSet(fieldName, value) {
    return {
        type: SESSION_ANNOTATIONS_SETUP_FIELDS_SET,
        payload: {
            fieldName,
            value,
        },
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.annotatorEditor
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_ANNOTATION_EDITOR_DIALOG_OPEN = 'SESSION_ANNOTATIONS_ANNOTATION_EDITOR_DIALOG_OPEN';
export function annotationEditorDialogOpenSet(value) {
    return {
        type: SESSION_ANNOTATIONS_ANNOTATION_EDITOR_DIALOG_OPEN,
        payload: value,
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.dialog
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_DIALOG_SET = 'SESSION_ANNOTATIONS_DIALOG_SET';
export function sessionAnnotationsDialogSet(value) {
    return {
        type: SESSION_ANNOTATIONS_DIALOG_SET,
        payload: value,
    };
}