/**
 * @file variantObjInject.js
 * @description finds the targetAnnotation in the annotationGroup
 *              and copies the provided variantObj into the
 *              annotation.  returns a copy of the original annotationGroup
 *              containing the injected variantObj
 * 
 *              used to copy local state into redux state
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 14, 2023 16:35
 */
import { objectDeepCopy, objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

function variantObjInject(targetAnnotationId, revisedVariantObj, annotationGroup) {

    let annotationGroupCopy = objectDeepCopy(annotationGroup);

    if (objectExists(annotationGroupCopy)) {

        if (stringExists(targetAnnotationId)
            && objectExists(revisedVariantObj)) {

            // find the target annotationObj in the annotationGroup
            const targetAnnotationObj = annotationGroupCopy.annotationObjs.find((item) => {

                if (objectKeyExists(item, 'annotation') &&
                    objectExists(item.annotation)) {
                    return item.annotation._id === targetAnnotationId;
                } else {
                    return false;
                }

            });

            if (objectExists(targetAnnotationObj)) {

                // find the annotation in the targetAnnotationObj
                if (objectKeyExists(targetAnnotationObj, 'annotation') &&
                    objectExists(targetAnnotationObj.annotation)) {

                    let targetAnnotation = targetAnnotationObj.annotation;

                    // the target variantObj may exist: 
                    // 1.  as a single object ie, annotation.fields.variantObj
                    // 2.  as an element in annotation.fields.variantObjArray.variantObj

                    if (objectKeyExists(targetAnnotation, 'fields') &&
                        objectExists(targetAnnotation.fields)) {

                        // look for variantObj as an element in fields.variantObjArray
                        if (objectKeyExists(targetAnnotation.fields, 'variantObjArray')) {

                            const foundIndex = targetAnnotation.fields.variantObjArray.findIndex((item) => {
                                return item._id === revisedVariantObj._id;
                            });

                            if (foundIndex !== -1) {
                                targetAnnotation.fields.variantObjArray[foundIndex] = objectDeepCopy(revisedVariantObj);
                                targetAnnotationObj.dirty = true;
                            }

                        } else {
                            // look for variantObj as fields.variantObj
                            if (objectKeyExists(targetAnnotation.fields, 'variantObj')) {
                                targetAnnotation.fields.variantObj = objectDeepCopy(revisedVariantObj);
                                targetAnnotationObj.dirty = true;
                            }
                        }

                    }
                }
            }

        }
    }

    return annotationGroupCopy;
}

export default variantObjInject;