/**
 * @file AnnotationDistribCharts.ChartContainer.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 18, 2023 19:21
 */
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { objectExists } from 'src/@common/utilities';

import DistributionChart from './DistributionChart';

function ChartContainer() {

    // start: redux state --------------------------
    const globalState = useSelector((state) => {
        return state.global;
    });

    const { dataLoading } = globalState;

    const geneModelDbState = useSelector((state) => {
        return state.geneModelDb;
    });

    const { geneChartData, geneDiseaseChartData } = geneModelDbState;
    // end: redux state -----------------------------

    return (
        <Fragment>

            {!dataLoading && objectExists(geneChartData) && objectExists(geneDiseaseChartData) &&
                <>
                    <DistributionChart
                        chartConfig={geneChartData}
                    />
                    <DistributionChart
                        chartConfig={geneDiseaseChartData}
                    />
                </>
            }

        </Fragment>
    )
}

export default ChartContainer;