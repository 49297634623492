/**
 * @file dashboardRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Oct 29, 2023 04:03 PM
 */
import { all, fork } from 'redux-saga/effects';

import collectionCounts from './collectionCounts.saga';

export default function* rootSaga() {
    yield all([

        fork(collectionCounts),

    ]);
}
