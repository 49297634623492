/**
 * @file annotatorRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 01, 2022 13:22
 */
import { all, fork } from 'redux-saga/effects';

import createAnnotationPdfView from 'src/store/sagas/annotator/createAnnotationPdfView.saga';
import createAnnotationView from 'src/store/sagas/annotator/createAnnotationView.saga';

export default function* rootSaga() {
    yield all([
        fork(createAnnotationPdfView),
        fork(createAnnotationView),
    ]);
}
