/**
 * @file annotationPdfViewer.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 02, 2022 16:58
 */
// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const ANNOTATION_PDF_VIEWER_DIALOG_OPEN_SET = 'ANNOTATION_PDF_VIEWER_DIALOG_OPEN_SET';
export function openAnnotationPdfViewerDialogSet(value) {
    return {
        type: ANNOTATION_PDF_VIEWER_DIALOG_OPEN_SET,
        payload: value,
    };
}

export const ANNOTATION_PDF_VIEWER_ANNOTATION_ID_SET = 'ANNOTATION_PDF_VIEWER_ANNOTATION_ID_SET';
export function pdfViewerAnnotationIdSet(annotationId) {
    return {
        type: ANNOTATION_PDF_VIEWER_ANNOTATION_ID_SET,
        payload: annotationId,
    };
}

/// createAnnotationPdfView
export const ANNOTATION_PDF_VIEWER_CREATE_VIEW_REQUEST = 'ANNOTATION_PDF_VIEWER_CREATE_VIEW_REQUEST';
export function annotationCreatePdfViewRequest(annotationId) {
    return {
        type: ANNOTATION_PDF_VIEWER_CREATE_VIEW_REQUEST,
        payload: annotationId,
    };
}

export const ANNOTATION_PDF_VIEWER_CREATE_VIEW_SUCCESS = 'ANNOTATION_PDF_VIEWER_CREATE_VIEW_SUCCESS';
export function annotationCreatePdfViewSuccess(payload) {
    return {
        type: ANNOTATION_PDF_VIEWER_CREATE_VIEW_SUCCESS,
        payload,
    };
}

export const ANNOTATION_PDF_VIEWER_CREATE_VIEW_FAILURE = 'ANNOTATION_PDF_VIEWER_CREATE_VIEW_FAILURE';
export function annotationCreatePdfViewFailure(error) {
    return {
        type: ANNOTATION_PDF_VIEWER_CREATE_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

export const ANNOTATION_PDF_VIEWER_CREATE_VIEW_RESET = 'ANNOTATION_PDF_VIEWER_CREATE_VIEW_RESET';
export function annotationCreatePdfViewReset() {
    return {
        type: ANNOTATION_PDF_VIEWER_CREATE_VIEW_RESET,
    };
}
