/**
 * @file documentPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 09, 2022 15:22
 */
import * as Actions from 'src/store/actions/document/documentPages.actions';

const initialState = {

    // search 
    searchDocTypes: ['pub'],
    searchMethod: 'fuzzy',
    searchTerm: '',

    filterText: '',

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const documentPagesReducer = (state = initialState, action) => {
    switch (action.type) {

        // search
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_SEARCH_DOC_TYPES_SET:
            return {
                ...state,
                searchDocTypes: action.payload,
            };

        case Actions.DOCUMENT_SEARCH_METHOD_SET:
            return {
                ...state,
                searchMethod: action.payload,
            };

        case Actions.DOCUMENT_SEARCH_TERM_SET:
            return {
                ...state,
                searchTerm: action.payload,
            };


        case Actions.DOCUMENT_SEARCH_FILTER_TEXT_SET: {
            return {
                ...state,
                filterText: action.payload,
            };
        }

        /************* */
        default: {
            return state;
        }
    }
}

export default documentPagesReducer;