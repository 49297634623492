/**
 * @file documentAnnotatorPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 22, 2022 11:02
 */

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const DOCUMENT_ANNOTATOR_DOCID_SET = 'DOCUMENT_ANNOTATOR_DOCID_SET';
export function docIdSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_DOCID_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATOR_DOCTYPE_SET = 'DOCUMENT_ANNOTATOR_DOCTYPE_SET';
export function docTypeSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_DOCTYPE_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATORID_SET = 'DOCUMENT_ANNOTATOR_ANNOTATORID_SET';
export function annotatorIdSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATORID_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATOR_ANNOTATOR_TYPE_SET = 'DOCUMENT_ANNOTATOR_ANNOTATOR_TYPE_SET';
export function annotatorTypeSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_ANNOTATOR_TYPE_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATOR_PMID_SET = 'DOCUMENT_ANNOTATOR_PMID_SET';
export function pmIdSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_PMID_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATOR_PUBID_SET = 'DOCUMENT_ANNOTATOR_PUBID_SET';
export function pubIdSet(value) {
    return {
        type: DOCUMENT_ANNOTATOR_PUBID_SET,
        payload: value,
    };
}

export const DOCUMENT_ANNOTATE_ANNOTATORID_PUBID_SET = 'DOCUMENT_ANNOTATE_ANNOTATORID_PUBID_SET';
export function annotateAnnotatorIdPubIdSet(annotatorId, pubId) {
    return {
        type: DOCUMENT_ANNOTATE_ANNOTATORID_PUBID_SET,
        payload: {
            annotatorId,
            pubId,
        },
    };
}

export const DOCUMENT_ANNOTATE_ANNOTATORID_SUPID_SET = 'DOCUMENT_ANNOTATE_ANNOTATORID_SUPID_SET';
export function annotateAnnotatorIdSupIdSet(annotatorId, supId) {
    return {
        type: DOCUMENT_ANNOTATE_ANNOTATORID_SUPID_SET,
        payload: {
            annotatorId,
            supId,
        },
    };
}

// -------------------------------------------------------------------
// psPdfKit 
// -------------------------------------------------------------------
export const PSPDFKIT_INSTANCE_SET = 'PSPDFKIT_INSTANCE_SET';
export function annotationPsPdfKitInstanceSet(value) {
    return {
        type: PSPDFKIT_INSTANCE_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// newAnnotation
// -------------------------------------------------------------------
export const ANNOTATION_NEW_OPEN_DIALOG_SET = 'ANNOTATION_NEW_OPEN_DIALOG_SET';
export function annotationNewOpenDialogSet(value) {
    return {
        type: ANNOTATION_NEW_OPEN_DIALOG_SET,
        payload: value,
    };
}

