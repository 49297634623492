/**
 * @file annotationSearchDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 15, 2023 14:19
 */
import * as Actions from 'src/store/actions/annotationSearch/annotationSearchDb.actions';
import { objectDeepCopy } from 'src/@common/utilities';

const initialState = {
    // note: biocurations for the table + publication or supplement
    // are stored in the documentAnnatatorDb.reducer to
    // support editing annotations within the documentAnnotator
    // biocurations: {},

    // annotations for the current page
    annotations: [],

    dataLoaded: false,

    // received from server on firs call to get annotations
    // contains annotationIds for each page
    pageMap: {
        queryCnt: 0,
        pages: [],
    },

    pageSize: 8,

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const annotationSearchDbReducer = (state = initialState, action) => {

    let annotationId = null;
    let foundRow = null;
    let tmpTable = null;
    let value = null;

    switch (action.type) {

        // annotationSearch.annotations - requests initial page of data
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_INITIAL_QUERY_REQUEST:
            return {
                ...state,
                annotations: [],
                dataLoaded: false,
                pageMap: {},
            };

        case Actions.ANNOTATION_SEARCH_INITIAL_QUERY_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                pageMap: action.payload.pageMap,
            };

        case Actions.ANNOTATION_SEARCH_INITIAL_QUERY_FAILURE:

            return {
                ...state,
                error: action.payload,
            };

        // annotationSearch.annotations - requests subsequent pages of annotations
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_PAGE_QUERY_REQUEST:
            return {
                ...state,
                annotations: [],
                dataLoaded: false,
            };

        case Actions.ANNOTATION_SEARCH_PAGE_QUERY_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
            };

        case Actions.ANNOTATION_SEARCH_PAGE_QUERY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationSearch.annotationSearch.delete
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_DELETE_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                annotations: [],
            };

        case Actions.ANNOTATION_SEARCH_DELETE_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                pageMap: action.payload.pageMap,
            };

        case Actions.ANNOTATION_SEARCH_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationSearch.refreshAnnotations
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                // annotations: [],
                pageMap: {},
            };

        case Actions.ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                pageMap: action.payload.pageMap,
            };

        case Actions.ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationSearch.annotations.checked
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_CHECKED_ROW_SET:
            annotationId = action.payload.annotationId;
            value = action.payload.value;

            tmpTable = objectDeepCopy(state.annotations);

            foundRow = tmpTable.find((row) => {
                return row._id === annotationId;
            });

            if (foundRow) {
                foundRow.selected = value;
            }

            return {
                ...state,
                annotations: tmpTable,
            };

        case Actions.ANNOTATION_SEARCH_CHECKED_ALL_SET:
            value = action.payload;

            tmpTable = objectDeepCopy(state.annotations);

            tmpTable.forEach((row) => {
                row.selected = value;
            })

            return {
                ...state,
                annotations: tmpTable,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default annotationSearchDbReducer;