/**
 * @file annotationSearchDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 15, 2023 14:15
 */

// -------------------------------------------------------------------
// annotationSearch.setters - used mostly for development
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_ANNOTATIONS_SET = 'ANNOTATION_SEARCH_ANNOTATIONS_SET';
export function annotationsSet(annotations) {
    return {
        type: ANNOTATION_SEARCH_ANNOTATIONS_SET,
        payload: annotations,
    };
}

// -------------------------------------------------------------------
// annotationSearch.annotations - requests initial page of data
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_INITIAL_QUERY_REQUEST = 'ANNOTATION_SEARCH_INITIAL_QUERY_REQUEST';
export function annotationSearchInitialQueryRequest(searchMethod, searchTerm) {
    return {
        type: ANNOTATION_SEARCH_INITIAL_QUERY_REQUEST,
        payload: {
            searchMethod,
            searchTerm,
        }
    };
}

export const ANNOTATION_SEARCH_INITIAL_QUERY_SUCCESS = 'ANNOTATION_SEARCH_INITIAL_QUERY_SUCCESS';
export function annotationSearchInitialQuerySuccess(payload) {
    return {
        type: ANNOTATION_SEARCH_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const ANNOTATION_SEARCH_INITIAL_QUERY_FAILURE = 'ANNOTATION_SEARCH_INITIAL_QUERY_FAILURE';
export function annotationSearchInitialQueryFailure(error) {
    return {
        type: ANNOTATION_SEARCH_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotationSearch.annotations - requests subsequent pages of annotations
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_PAGE_QUERY_REQUEST = 'ANNOTATION_SEARCH_PAGE_QUERY_REQUEST';
export function annotationSearchPageQueryRequest(pageObj) {
    return {
        type: ANNOTATION_SEARCH_PAGE_QUERY_REQUEST,
        payload: pageObj,
    };
}

export const ANNOTATION_SEARCH_PAGE_QUERY_SUCCESS = 'ANNOTATION_SEARCH_PAGE_QUERY_SUCCESS';
export function annotationSearchPageQuerySuccess(payload) {
    return {
        type: ANNOTATION_SEARCH_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const ANNOTATION_SEARCH_PAGE_QUERY_FAILURE = 'ANNOTATION_SEARCH_PAGE_QUERY_FAILURE';
export function annotationSearchPageQueryFailure(error) {
    return {
        type: ANNOTATION_SEARCH_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotationSearch.annotationSearchDelete
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_DELETE_REQUEST = 'ANNOTATION_SEARCH_DELETE_REQUEST';
export function annotationSearchDeleteRequest(annotationIds, searchMethod, searchTerm) {
    return {
        type: ANNOTATION_SEARCH_DELETE_REQUEST,
        payload: {
            annotationIds,
            searchMethod,
            searchTerm,
        }
    };
}

export const ANNOTATION_SEARCH_DELETE_SUCCESS = 'ANNOTATION_SEARCH_DELETE_SUCCESS';
export function annotationSearchDeleteSuccess(payload) {
    return {
        type: ANNOTATION_SEARCH_DELETE_SUCCESS,
        payload,
    };
}

export const ANNOTATION_SEARCH_DELETE_FAILURE = 'ANNOTATION_SEARCH_DELETE_FAILURE';
export function annotationSearchDeleteFailure(error) {
    return {
        type: ANNOTATION_SEARCH_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotationSearch.annotations - requests initial page of data
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_REQUEST = 'ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_REQUEST';
export function annotationSearchRefreshAnnotationsRequest(annotatorId, docType, docId) {
    return {
        type: ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_REQUEST,
        payload: {
            annotatorId,
            docType,
            docId,
        }
    };
}

export const ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_SUCCESS = 'ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_SUCCESS';
export function annotationSearchRefreshAnnotationsSuccess(payload) {
    return {
        type: ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_SUCCESS,
        payload,
    };
}

export const ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_FAILURE = 'ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_FAILURE';
export function annotationSearchRefreshAnnotationsFailure(error) {
    return {
        type: ANNOTATION_SEARCH_REFRESH_ANNOTATIONS_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// annotationSearch.checked
// -------------------------------------------------------------------
export const ANNOTATION_SEARCH_CHECKED_ROW_SET = 'ANNOTATION_SEARCH_CHECKED_ROW_SET';
export function annotationSearchRowCheckSet(value, annotationId,) {
    return {
        type: ANNOTATION_SEARCH_CHECKED_ROW_SET,
        payload: {
            annotationId,
            value,
        }
    };
}

export const ANNOTATION_SEARCH_CHECKED_ALL_SET = 'ANNOTATION_SEARCH_CHECKED_ALL_SET';
export function annotationSearchRowCheckAllSet(value) {
    return {
        type: ANNOTATION_SEARCH_CHECKED_ALL_SET,
        payload: value,
    };
}

