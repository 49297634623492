/**
 * @file handleSagaErrors.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 06, 2023 14:19
 */
import { logout } from 'src/auth/jwtService';
import { objectExists, objectKeyExists } from 'src/@common/utilities';
import standardMessages from 'src/services/notifications/standardMessages';
import store from 'src/store';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

const handleSagaErrors = (error) => {

    if (objectExists(error) &&
        objectKeyExists(error, 'message')) {

        switch (error.message) {

            case 'Request failed with status code 401':
                store.dispatch(enqueueMessage(
                    standardMessages.notAuthorized.message,
                    standardMessages.notAuthorized.options)
                );
                logout();
                break;

            case 'Network Error':
                store.dispatch(enqueueMessage(
                    standardMessages.networkError.message,
                    standardMessages.networkError.options)
                );
                break;

            default:
                store.dispatch(enqueueMessage(
                    standardMessages.serverError.message,
                    standardMessages.serverError.options)
                );
                break;
        }
    }
}

export default handleSagaErrors;