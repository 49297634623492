/**
 * @file tableAnnotatorPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 03, 2022 19:00
 */

// -------------------------------------------------------------------
// tableAnnotator.reset
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_PAGES_RESET = 'TABLE_ANNOTATOR_PAGES_RESET';
export function tableAnnotatorPagesReset() {
    return {
        type: TABLE_ANNOTATOR_PAGES_RESET,
    };
}

export const TABLE_ANNOTATOR_PAGES_CLEAR = 'TABLE_ANNOTATOR_PAGES_CLEAR';
export function tableAnnotatorPagesClear() {
    return {
        type: TABLE_ANNOTATOR_PAGES_CLEAR,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.setupFields
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_SETUP_FIELDS_SET = 'TABLE_ANNOTATOR_SETUP_FIELDS_SET';
export function tableAnnotatorSetupFieldsSet(fieldName, value) {
    return {
        type: TABLE_ANNOTATOR_SETUP_FIELDS_SET,
        payload: {
            fieldName,
            value,
        },
    };
}

// -------------------------------------------------------------------
// tableAnnotator.annotatorEditor
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_ANNOTATION_EDITOR_DIALOG_OPEN = 'TABLE_ANNOTATOR_ANNOTATION_EDITOR_DIALOG_OPEN';
export function annotationEditorDialogOpenSet(value) {
    return {
        type: TABLE_ANNOTATOR_ANNOTATION_EDITOR_DIALOG_OPEN,
        payload: value,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.globalFields
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_GLOBAL_FIELDS_ADD_FIELD = 'TABLE_ANNOTATOR_GLOBAL_FIELDS_ADD_FIELD';
export function globalFieldsAddField(newField) {
    return {
        type: TABLE_ANNOTATOR_GLOBAL_FIELDS_ADD_FIELD,
        payload: newField,
    };
}

export const TABLE_ANNOTATOR_GLOBAL_FIELDS_UPDATE_FIELD = 'TABLE_ANNOTATOR_GLOBAL_FIELDS_UPDATE_FIELD';
export function globalFieldsUpdateField(fieldName, value) {
    return {
        type: TABLE_ANNOTATOR_GLOBAL_FIELDS_UPDATE_FIELD,
        payload: {
            fieldName,
            value,
        },
    };
}

export const TABLE_ANNOTATOR_GLOBAL_FIELDS_CREATE_FIELD = 'TABLE_ANNOTATOR_GLOBAL_FIELDS_CREATE_FIELD';
export function globalFieldsCreateField(newField) {
    return {
        type: TABLE_ANNOTATOR_GLOBAL_FIELDS_CREATE_FIELD,
        payload: newField,
    };
}

export const TABLE_ANNOTATOR_GLOBAL_FIELDS_DELETE_FIELD = 'TABLE_ANNOTATOR_GLOBAL_FIELDS_DELETE_FIELD';
export function globalFieldsDeleteField(fieldName) {
    return {
        type: TABLE_ANNOTATOR_GLOBAL_FIELDS_DELETE_FIELD,
        payload: fieldName,
    };
}

export const TABLE_ANNOTATOR_GLOBAL_NEW_FIELD_DIALOG_OPEN = 'TABLE_ANNOTATOR_GLOBAL_NEW_FIELD_DIALOG_OPEN';
export function globalNewFieldDialogOpenSet(value) {
    return {
        type: TABLE_ANNOTATOR_GLOBAL_NEW_FIELD_DIALOG_OPEN,
        payload: value,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.tableGrouping form
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_GROUPING_METHOD_SET = 'TABLE_ANNOTATOR_GROUPING_METHOD_SET';
export function groupingMethodSet(value) {
    return {
        type: TABLE_ANNOTATOR_GROUPING_METHOD_SET,
        payload: value,
    };
}
