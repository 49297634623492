/**
 * @file helpDocViewer.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 20, 2023 15:45
 */
import * as Actions from 'src/store/actions/helpDocViewer.actions';

const initialState = {
    url: '',
    applicationName: '',
    fileName: '',
    title: '',
    helpDocViewerOpenDialog: false,
};

const helpDocViewerReducer = (state = initialState, action) => {
    switch (action.type) {

        // helpDocViewer.openDialogSet
        // -------------------------------------------------------------------
        case Actions.HELP_DOC_VIEWER_OPEN_DIALOG_SET:
            return {
                ...state,
                helpDocViewerOpenDialog: action.payload,
            };

        // helpDocViewer.getUrlrequest
        // -------------------------------------------------------------------
        case Actions.HELP_DOC_VIEWER_GET_URL_REQUEST:
            return {
                ...state,
                url: '',
                applicationName: action.payload.applicationName,
                fileName: action.payload.fileName,
                title: action.payload.title,
            };

        case Actions.HELP_DOC_VIEWER_GET_URL_SUCCESS:
            return {
                ...state,
                url: action.payload.url,
            };

        case Actions.HELP_DOC_VIEWER_GET_URL_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default: {
            return state;
        }
    }
};

export default helpDocViewerReducer;
