/**
 * @file annotationPdfViewer.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 02, 2022 17:00
 */
import * as Actions from 'src/store/actions/annotator/annotationPdfViewer.actions';

const initialState = {

    pdfViewerInstance: null, // pointer to the psPdfKit instance
    annotation: null, // the selected annotation
    annotationId: null,  // the id of the annotation viewed in the annotationViewer
    annotationJson: null, // highlight regions from psPdfKit
    curationId: null,  // the pspdfKit annotation id
    documentUrl: null,  // url to the pdf viewed in the annotatiorViewer

    openViewerDialog: false,

    serverErrorExists: false,
    serverMessage: '', // message from server for display in toaster message
};

const annotationViewerReducer = (state = initialState, action) => {
    switch (action.type) {

        // -------------------------------------------------------------------
        // setters
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_PDF_VIEWER_ANNOTATION_ID_SET: {
            return {
                ...state,
                annotationId: action.payload,
            };
        }

        case Actions.ANNOTATION_PDF_VIEWER_DIALOG_OPEN_SET: {
            return {
                ...state,
                openViewerDialog: action.payload,
            };
        }

        // -------------------------------------------------------------------
        // reset state
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_PDF_VIEWER_CREATE_VIEW_RESET: {
            return {
                ...state,

                pdfViewerInstance: null,
                annotation: null,
                annotationId: null,
                annotationJson: null,
                curationId: null,
                documentUrl: null,

                openViewerDialog: false,

                serverErrorExists: false,
                serverMessage: '',
            };
        }

        // -------------------------------------------------------------------
        // createAnnotationPdfView
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_PDF_VIEWER_CREATE_VIEW_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.ANNOTATION_PDF_VIEWER_CREATE_VIEW_SUCCESS: {

            return {
                ...state,
                annotation: action.payload.annotation,
                annotationJson: action.payload.annotationJson,
                curationId: action.payload.curationId,
                documentUrl: action.payload.url,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.ANNOTATION_PDF_VIEWER_CREATE_VIEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        /** *********** */
        default: {
            return state;
        }
    }
};

export default annotationViewerReducer;
