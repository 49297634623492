/**
 * @file VeriomeListItemField.js
 * @description displays a list item (display only)
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jan 05, 2022 12:47
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { isNumber } from 'src/@common/validatorUtilities';

const LabelText = (props) => {
    const {
        label,
        color,
    } = props;

    return (
        <Typography
            sx={{ lineHeight: 1, m: 1, fontWeight: '400' }}
            variant="overline"
            display="block"
            color={color}
        >
            {`${label}: `}
        </Typography>
    );
}

LabelText.propTypes = {
    label: PropTypes.string.isRequired,
};

const ValueText = (props) => {
    const {
        value,
        color,
    } = props;

    return (
        <Typography
            sx={{ fontSize: 15, fontWeight: 'bold', marginLeft: '8px', marginTop: '10px' }}
            color={color}
        >
            {value}
        </Typography>
    );
}

ValueText.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
};

function VeriomeListItemField(props) {
    const {
        label,
        value,
        direction,
        labelColor,
        valueColor,
    } = props;

    const [valueStr, setValueStr] = useState('');

    useEffect(() => {
        if (isNumber(value)) {
            setValueStr(value.toString());
        } else {
            setValueStr(value);
        }
    }, [value]);

    return (
        <Stack
            ml={0}
            mb={'5px'}
            direction={direction}
            justifyContent="flex-start"
            alignItems="baseline"
            spacing={1}
        >
            <LabelText label={label} color={labelColor} />
            <ValueText value={valueStr} color={valueColor} />
        </Stack>
    );
}

VeriomeListItemField.defaultProps = {
    label: 'Field',
    value: '',
    direction: 'row',
    labelColor: 'text.primary',
    valueColor: 'text.primary',
};

VeriomeListItemField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    direction: PropTypes.string,
    labelColor: PropTypes.string,
    valueColor: PropTypes.string,
};

export default VeriomeListItemField;