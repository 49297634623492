/**
 * @file annotationObjDirtyReset.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 17, 2023 13:33
 */
import { arrayExists, objectDeepCopy, objectExists, objectKeyExists } from 'src/@common/utilities';

function annotationObjDirtyReset(annotationGroup, value = false) {

    let annotationGroupCopy = objectDeepCopy(annotationGroup);

    if (objectExists(annotationGroupCopy) &&
        objectKeyExists(annotationGroupCopy, 'annotationObjs') &&
        arrayExists(annotationGroupCopy.annotationObjs)) {

        annotationGroupCopy.annotationObjs.forEach((annotationObj) => {
            annotationObj.dirty = value;
        });
    }

    return annotationGroupCopy;
}

export default annotationObjDirtyReset;