/**
 * @file PasswordAuthentication.index.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since May 28, 2023 08:58
 */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getToken } from 'src/auth/authUtilities';

const Authenticated = (props) => {
    const { children } = props;
    const location = useLocation();
    const navigate = useNavigate();

    const [requestedLocation, setRequestedLocation] = useState(null);

    // start: redux state --------------------------
    const authState = useSelector((state) => {
        return state.auth;
    });

    const { authenticated } = authState;
    // end: redux state -----------------------------

    let authToken = getToken('auth');

    useEffect(() => {

        if (!authenticated && !authToken) {

            if (location.pathname !== requestedLocation) {
                setRequestedLocation(location.pathname);
            }

            const targetPath = `/auth/signin`;
            return navigate(targetPath);
        }

    }, [authenticated, authToken, location, navigate, requestedLocation]);

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

Authenticated.propTypes = {
    children: PropTypes.node
};

export default Authenticated;