/**
 * @file documentAnnotatorRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 23, 2022 10:33
 */
import { all, fork } from 'redux-saga/effects';

//####
import annotationDelete from './annotationDelete.saga';
import annotationFind from './annotationFind.saga';
import annotationSave from './annotationSave.saga';
import annotationTypeSubmit from './annotationTypeSubmit.saga';
import annotationJsonSave from './annotationJsonSave.saga';
import annotationsSave from './annotationsSave.saga';

import annotationGroupDelete from './annotationGroupDelete.saga';

import annotationGroupFind from './annotationGroupFind.saga';
import annotationNew from './annotationNew.saga';
import annotationGroupNew from './annotationGroupNew.saga';

import classificationCodeSubmit from './classificationCodeSubmit.saga';
import diseaseSubmit from './diseaseSubmit.saga';

import geneSymbolSubmit from './geneSymbolSubmit.saga';

import publicationAnnotate from './publicationAnnotate.saga';
import supplementAnnotate from './supplementAnnotate.saga';

import variantObjNew from './variantObjNew.saga';
import variantValidatorFind from './variantValidatorFind.saga';

export default function* rootSaga() {
    yield all([

        // annotateDocument
        fork(publicationAnnotate),
        fork(supplementAnnotate),

        // annotation
        fork(annotationDelete),
        fork(annotationFind),
        fork(annotationNew),
        fork(annotationSave),
        fork(annotationJsonSave),
        fork(annotationsSave),
        fork(annotationTypeSubmit),
        fork(annotationGroupDelete),
        fork(annotationGroupNew),
        fork(annotationGroupFind),
        fork(classificationCodeSubmit),
        fork(diseaseSubmit),
        fork(geneSymbolSubmit),

        fork(variantObjNew),
        fork(variantValidatorFind),

    ]);
}