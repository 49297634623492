/**
 * @file notFinishedMsg.js
 * @description displays a 'service not finished' message
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 28, 2023 18:59
 */
import standardMessages from 'src/services/notifications/standardMessages';
import store from 'src/store';
import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

const notFinishedMsg = () => {
    store.dispatch(enqueueMessage(
        "Feature not finished",
        standardMessages.generalInfoMessage.options)
    );
}

export default notFinishedMsg;