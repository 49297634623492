/**
 * @file RenderVariantObjFields.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 05, 2024 10:06
 */
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { objectExists } from 'src/@common/utilities';

import ListItemField from 'src/components/fieldComponents/VeriomeListItemField';

function RenderVariantObjFields(props) {
    const {
        variantObj,
    } = props;

    return (

        <div className="mt-0 print:mt-0">

            <Card variant="outlined" className="shadow-1"
                sx={{ marginLeft: '10px', marginTop: '5px' }}
            >
                <CardContent>

                    <Typography className="mb-8 text-14 font-semibold" >
                        Variant
                    </Typography>

                    <Divider sx={{ 'marginTop': '8px', }} />

                    <List dense >

                        {objectExists(variantObj.geneSymbol) &&
                            <ListItemField
                                label={variantObj.geneSymbol.label}
                                value={variantObj.geneSymbol.value}
                            />
                        }

                        {objectExists(variantObj.chromosome) &&
                            <ListItemField
                                label={variantObj.chromosome.label}
                                value={variantObj.chromosome.value}
                            />
                        }

                        {objectExists(variantObj.genomeBuild) &&
                            <ListItemField
                                label={variantObj.genomeBuild.label}
                                value={variantObj.genomeBuild.value}
                            />
                        }

                        {objectExists(variantObj.transcript) &&
                            <ListItemField
                                label={variantObj.transcript.label}
                                value={variantObj.transcript.value}
                            />
                        }

                        {objectExists(variantObj.cDot) &&
                            <ListItemField
                                label={variantObj.cDot.label}
                                value={variantObj.cDot.value}
                            />
                        }

                        {objectExists(variantObj.gDotObj) &&
                            objectExists(variantObj.gDotObj.hg19) &&
                            <ListItemField
                                label={variantObj.gDotObj.hg19.label}
                                value={variantObj.gDotObj.hg19.value}
                            />
                        }

                        {objectExists(variantObj.gDotObj) &&
                            objectExists(variantObj.gDotObj.hg38) &&
                            <ListItemField
                                label={variantObj.gDotObj.hg38.label}
                                value={variantObj.gDotObj.hg38.value}
                            />
                        }

                        {objectExists(variantObj.pDot) &&
                            <ListItemField
                                label={variantObj.pDot.label}
                                value={variantObj.pDot.value}
                            />
                        }

                        {objectExists(variantObj.dnaContig) &&
                            <ListItemField
                                label={variantObj.dnaContig.label}
                                value={variantObj.dnaContig.value}
                            />
                        }

                        {objectExists(variantObj.gDotCNV) &&
                            <ListItemField
                                label={variantObj.gDotCNV.label}
                                value={variantObj.gDotCNV.value}
                            />
                        }

                        {objectExists(variantObj.dbSNPId) &&
                            <ListItemField
                                label={variantObj.dbSNPId.label}
                                value={variantObj.dbSNPId.value}
                            />
                        }

                    </List>

                </CardContent>

            </Card >

        </div>
    );
}

RenderVariantObjFields.propTypes = {
    variantObj: PropTypes.object.isRequired,
};

RenderVariantObjFields.propTypes = {
    variantObj: PropTypes.object,
};

export default RenderVariantObjFields;