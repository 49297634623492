/**
 * @file tableAnnotatorPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 03, 2022 15:05
 */
import * as Actions from 'src/store/actions/tableAnnotator/tableAnnotatorPages.actions';
import * as dbActions from 'src/store/actions/tableAnnotator/tableAnnotatorDb.actions';

import { arrayDeepCopy, objectDeepCopy } from 'src/@common/utilities';

const initialState = {
    // grouping is offered if the classificationCode
    // has an variantObjArray field
    allowGrouping: false,
    groupingMethod: 'none', // none, row, col

    // annotationSetup form fields
    setupFormFields: {
        annotationType: '',
        classificationCode: '',
    },

    // identifies if the classificationCode fields
    // contain a variantObjArray, which requires 
    // special treatment
    variantObjArrayExists: false,

    // annotatorEditor
    annotatorEditorOpenDialog: false,

    // globalFields form
    newFieldOpenDialog: false,
    globalFields: {},

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const tableAnnotatorPagesReducer = (state = initialState, action) => {

    let allowGrouping = false;
    let tmpFields = null;
    let tmpGlobalFields = null;
    let fieldName = '';
    let value = null;

    switch (action.type) {

        // tableAnnotator.resets
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_PAGES_RESET:
            // resets everything
            return {
                ...state,
                annotatorId: null,
                tableId: null,
                allowGrouping: false,
                groupingMethod: 'none',
                setupFormFields: {
                    annotationType: '',
                    classificationCode: '',
                },
                variantObjArrayExists: false,
                annotatorEditorOpenDialog: false,
                newFieldOpenDialog: false,
                globalFields: {},
            };

        case Actions.TABLE_ANNOTATOR_PAGES_CLEAR:
            // resets just past generation session.
            // leaves table and annotator intact
            return {
                ...state,
                allowGrouping: false,
                genBatchId: null,
                groupingMethod: 'none',
                setupFormFields: {
                    annotationType: '',
                    classificationCode: '',
                },
                variantObjArrayExists: false,
                globalFields: {},
            };

        // tableAnnotator.annotatorEditor
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_ANNOTATION_EDITOR_DIALOG_OPEN:
            return {
                ...state,
                annotatorEditorOpenDialog: action.payload,
            };

        // annotationSetup form
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_SETUP_FIELDS_SET:
            tmpFields = objectDeepCopy(state.setupFormFields);
            fieldName = action.payload.fieldName;

            tmpFields[fieldName] = action.payload.value;

            return {
                ...state,
                setupFormFields: tmpFields,
            };

        // globalFields
        // -------------------------------------------------------------------

        // setters
        case Actions.TABLE_ANNOTATOR_GLOBAL_NEW_FIELD_DIALOG_OPEN:
            return {
                ...state,
                newFieldOpenDialog: action.payload,
            };

        case Actions.TABLE_ANNOTATOR_GLOBAL_FIELDS_CREATE_FIELD:
            fieldName = action.payload.fieldName;

            tmpGlobalFields = arrayDeepCopy(state.globalFields);
            tmpGlobalFields[fieldName] = action.payload.value;

            return {
                ...state,
                globalFields: tmpGlobalFields,
            };

        case Actions.TABLE_ANNOTATOR_GLOBAL_FIELDS_DELETE_FIELD:
            fieldName = action.payload;

            tmpGlobalFields = {};

            Object.keys(state.globalFields).map((tmpFieldName) => {
                if (tmpFieldName !== fieldName) {
                    tmpGlobalFields[tmpFieldName] = state.globalFields[tmpFieldName];
                }
            });

            return {
                ...state,
                globalFields: tmpGlobalFields,
            };

        case Actions.TABLE_ANNOTATOR_GLOBAL_FIELDS_UPDATE_FIELD:
            fieldName = action.payload.fieldName;
            value = action.payload.value;

            tmpGlobalFields = arrayDeepCopy(state.globalFields);
            tmpGlobalFields[fieldName] = value;

            return {
                ...state,
                globalFields: tmpGlobalFields,
            };

        case Actions.TABLE_ANNOTATOR_GROUPING_METHOD_SET:
            value = action.payload;

            return {
                ...state,
                groupingMethod: value,
            };

        // tableAnnotator.tableGrouping form
        // -------------------------------------------------------------------
        //export const TABLE_ANNOTATOR_GROUPING_METHOD_UPDATE_FIELD = 'TABLE_ANNOTATOR_GROUPING_METHOD_UPDATE_FIELD';

        /**
         * responding to annotatorTableDb actions
         */
        case dbActions.TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST:
            return {
                ...state,
                setupFormFields: {
                    annotationType: '',
                    classificationCode: '',
                    allowGrouping: false,
                    groupingMethod: 'none',
                },
                globalFields: {},
            };

        // tableAnnotator.annotateTable
        // -------------------------------------------------------------------
        case dbActions.TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS:
            return {
                ...state,
                annotations: action.payload.annotations,
                biocurations: action.payload.biocurations,
                table: action.payload.table,
                tableId: action.payload.table._id,
            };

        // tableAnnotator.submitClassificationCode
        // -------------------------------------------------------------------
        case dbActions.TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS:

            if (action.payload.variantObjArrayExists === true) {
                allowGrouping = true;
            }

            return {
                ...state,
                allowGrouping,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default tableAnnotatorPagesReducer;