/**
 * @file variantPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 12:36
 */
import { objectDeepCopy, stringExists } from 'src/@common/utilities';
import * as Actions from 'src/store/actions/variant/variantPages.actions';

const initialState = {

    classificationType: '',

    // search
    filterText: '',
    searchMethod: 'fuzzy',
    searchTerm: '',

    // dialog openers
    diseaseViewOpenDialog: false,

    geneDiseaseClassificationAnnotationsDialog: false,
    geneDiseaseAnnotationsDialog: false,

    variantDiseaseClassificationEvidenceDialog: false,
    variantDiseaseClassificationViewDialog: false,
    variantDiseaseNewDialog: false,

    variantNewDialog: false,
    variantViewDialog: false,

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const variantPagesReducer = (state = initialState, action) => {

    let fieldName = '';
    let fieldValue = '';

    switch (action.type) {

        // resets all variantPages state
        // -------------------------------------------------------------------
        case Actions.VARIANT_PAGES_RESET_STATE: {
            return objectDeepCopy(initialState);
        }

        // variant.search.session
        // -------------------------------------------------------------------
        case Actions.VARIANT_SEARCH_SESSION_SET:
            return {
                ...state,
                filterText: action.payload.filterText,
                searchMethod: action.payload.searchMethod,
                searchTerm: action.payload.searchTerm,
            };

        // geneModel.search.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.VARIANT_SEARCH_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {
                switch (fieldName) {
                    default:
                        return {
                            ...state,
                            [fieldName]: fieldValue,
                        };
                }
            }

            return {
                ...state,
            };
        }

        // variantDiseaseClassification.classificationType
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_TYPE_SET: {
            return {
                ...state,
                classificationType: action.payload,
            };
        }

        // variantDiseaseClassificationEvidence. dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DIALOG_SET: {
            return {
                ...state,
                variantDiseaseClassificationEvidenceDialog: action.payload,
            };
        }

        // variantDiseaseClassification.view dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_VIEW_DIALOG_SET: {
            return {
                ...state,
                variantDiseaseClassificationViewDialog: action.payload,
            };
        }

        // diseaseView dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_VIEW_DIALOG_SET: {
            return {
                ...state,
                diseaseViewOpenDialog: action.payload,
            };
        }

        // geneDisease.annotations dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_DISEASE_ANNOTATIONS_DIALOG_SET: {
            return {
                ...state,
                geneDiseaseAnnotationsDialog: action.payload,
            };
        }

        // geneDiseaseClassificationEvidence.dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_CLASSIFICATION_EVIDENCE_DIALOG_SET: {
            return {
                ...state,
                geneDiseaseClassificationAnnotationsDialog: action.payload,
            };
        }

        // variantDisease.new dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_NEW_DIALOG_SET: {
            return {
                ...state,
                variantDiseaseNewDialog: action.payload,
            };
        }

        // variant.new dialog
        // -------------------------------------------------------------------
        case Actions.VARIANT_NEW_DIALOG_SET: {
            return {
                ...state,
                variantNewDialog: action.payload,
            };
        }

        // variant.view
        // -------------------------------------------------------------------
        case Actions.VARIANT_VIEW_DIALOG_SET: {
            return {
                ...state,
                variantViewDialog: action.payload,
            };
        }

        /************* */
        default: {
            return state;
        }
    }
}

export default variantPagesReducer;