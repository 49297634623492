/**
 * @file documentDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 14, 2022 16:03
 */

// -------------------------------------------------------------------
// document.search
// -------------------------------------------------------------------

// search by searchText
export const DOCUMENT_SEARCH_REQUEST = 'DOCUMENT_SEARCH_REQUEST';
export function documentSearchRequest(searchDocTypes, searchMethod, searchTerm) {
    return {
        type: DOCUMENT_SEARCH_REQUEST,
        payload: {
            searchDocTypes,
            searchMethod,
            searchTerm,
        },
    };
}

export const DOCUMENT_SEARCH_SUCCESS = 'DOCUMENT_SEARCH_SUCCESS';
export function documentSearchSuccess(queryResults) {
    return {
        type: DOCUMENT_SEARCH_SUCCESS,
        payload: queryResults,
    };
}

export const DOCUMENT_SEARCH_FAILURE = 'DOCUMENT_SEARCH_FAILURE';
export function documentSearchFailure(error) {
    return {
        type: DOCUMENT_SEARCH_FAILURE,
        payload: JSON.stringify(error),
    };
}

export const DOCUMENT_SEARCH_RESET = 'DOCUMENT_SEARCH_RESET';
export function documentSearchReset() {
    return {
        type: DOCUMENT_SEARCH_RESET,
        payload: {
        },
    };
}

// -------------------------------------------------------------------
// document.updateSeachItem
// -------------------------------------------------------------------

// // search by searchText
export const DOCUMENT_UPDATE_SEARCH_ITEM_REQUEST = 'DOCUMENT_UPDATE_SEARCH_ITEM_REQUEST';
export function documentUpdateSearchItemRequest(docType, docId) {
    return {
        type: DOCUMENT_UPDATE_SEARCH_ITEM_REQUEST,
        payload: {
            docType,
            docId,
        },
    };
}

export const DOCUMENT_UPDATE_SEARCH_ITEM_SUCCESS = 'DOCUMENT_UPDATE_SEARCH_ITEM_SUCCESS';
export function documentUpdateSearchItemSuccess(payload) {
    return {
        type: DOCUMENT_UPDATE_SEARCH_ITEM_SUCCESS,
        payload,
    };
}

export const DOCUMENT_UPDATE_SEARCH_ITEM_FAILURE = 'DOCUMENT_UPDATE_SEARCH_ITEM_FAILURE';
export function documentUpdateSearchItemFailure(error) {
    return {
        type: DOCUMENT_UPDATE_SEARCH_ITEM_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.refresh
// -------------------------------------------------------------------
export const DOCUMENT_REFRESH_REQUEST = 'DOCUMENT_REFRESH_REQUEST';
export function documentRefreshRequest(docId, docType) {
    return {
        type: DOCUMENT_REFRESH_REQUEST,
        payload: {
            docId,
            docType
        },
    };
}

export const DOCUMENT_REFRESH_SUCCESS = 'DOCUMENT_REFRESH_SUCCESS';
export function documentRefreshSuccess(queryResults) {
    return {
        type: DOCUMENT_REFRESH_SUCCESS,
        payload: queryResults,
    };
}

export const DOCUMENT_REFRESH_FAILURE = 'DOCUMENT_REFRESH_FAILURE';
export function documentRefreshFailure(error) {
    return {
        type: DOCUMENT_REFRESH_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// document.table.delete
// -------------------------------------------------------------------
export const DOCUMENT_TABLE_DELETE_REQUEST = 'DOCUMENT_TABLE_DELETE_REQUEST ';
export function documentTableDeleteRequest(tableId) {
    return {
        type: DOCUMENT_TABLE_DELETE_REQUEST,
        payload: tableId,
    };
}

export const DOCUMENT_TABLE_DELETE_SUCCESS = 'DOCUMENT_TABLE_DELETE_SUCCESS';
export function documentTableDeleteSuccess(payload) {
    return {
        type: DOCUMENT_TABLE_DELETE_SUCCESS,
        payload,
    };
}

export const DOCUMENT_TABLE_DELETE_FAILURE = 'DOCUMENT_TABLE_DELETE_FAILURE';
export function documentTableDeleteFailure(error) {
    return {
        type: DOCUMENT_TABLE_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}