/**
 * @file documentAnnotatorDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 22, 2022 11:29
 */
import { arrayExists, objectDeepCopy, objectExists, stringExists } from 'src/@common/utilities';
import * as Actions from 'src/store/actions/documentAnnotator/documentAnnotatorDb.actions';

import { saveKey } from 'src/@common/sessionStorage';

// actions shared with annotationEditor
import * as AnnotationEditorActions from 'src/store/actions/annotationEditor.actions';

// actions shared with tableAnnotator
import * as TableAnnotatorActions from 'src/store/actions/tableAnnotator/tableAnnotatorDb.actions';

// helpers
import annotationObjDirtyReset from 'src/store/workers/annotationObjDirtyReset';
import serverAnnotationObjInject from 'src/store/workers/serverAnnotationObjInject';
import variantObjDelete from 'src/store/workers/variantObjDelete';
import variantObjInject from 'src/store/workers/variantObjInject';
import initFromVariantValidatorObj from 'src/store/workers/initFromVariantValidatorObj';

import deleteHighlightAnnotation from 'src/content/PdfAnnotator/workers/deleteHighlightAnnotation';

const initialState = {

    // document.annotate
    // group of annotations for a single annotationGroupId
    annotationGroup: {},

    // serves: annotatePublication, annotateSupplement, annotateTable
    biocurations: {},

    // serves: annotatePublication, annotateSupplement
    annotationJson: {},

    // must be in this reducer, so the annotationGroupDialog can 
    // be closed when all annotations are deleted
    annotationEditOpenDialog: false,

    // server status
    error: {},
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const documentAnnotatorDbReducer = (state = initialState, action) => {
    let annotation = null;
    let annotationGroup = null;
    let annotationGroupCopy = null;
    let annotationObj = null;

    let fieldName = null;
    let fieldValue = null;
    let newAnnotationObj = null;
    let existingAnnotationObjIndex = null;

    let openDialog = null;

    let targetAnnotation = null;
    let targetAnnotationId = null

    let variantObj = null;
    let targetVariantObjId = null;
    let variantValidatorObj = null;

    switch (action.type) {

        // editAnnotation
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_EDIT_OPEN_DIALOG_SET: {
            // set only if different
            if (action.payload !== state.annotationEditOpenDialog) {
                return {
                    ...state,
                    annotationEditOpenDialog: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        }

        // annotationGroup.set
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_SET:
            return {
                ...state,
                annotationGroup: action.payload.annotationGroup,
            };

        // biocurations.set
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_BIOCURATIONS_SET:
            return {
                ...state,
                biocurations: action.payload.biocurations,
            };

        // annotation.set
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_SET:
            return {
                ...state,
                annotation: action.payload.annotation,
            };

        // annotatePublication
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATE_PUBLICATION_REQUEST:
            // insert into sessionStorage to survive a page refresh
            saveKey('annotatorId', action.payload.annotatorId);
            saveKey('pubId', action.payload.pubId);

            return {
                ...state,
                annotationGroup: {},
                biocurations: {},
                annotationJson: {},
            };

        case Actions.DOCUMENT_ANNOTATE_PUBLICATION_SUCCESS:
            return {
                ...state,
                biocurations: action.payload.biocurations,
                annotationJson: action.payload.annotationJson,
            };

        case Actions.DOCUMENT_ANNOTATE_PUBLICATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotateSupplement
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST:
            // insert into sessionStorage to survive a page refresh
            saveKey('annotatorId', action.payload.annotatorId);
            saveKey('supId', action.payload.supId);

            return {
                ...state,
                annotationGroup: {},
                biocurations: {},
                annotationJson: {},
            };

        case Actions.DOCUMENT_ANNOTATE_SUPPLEMENT_SUCCESS:
            return {
                ...state,
                biocurations: action.payload.biocurations,
                annotationJson: action.payload.annotationJson,
            };

        case Actions.DOCUMENT_ANNOTATE_SUPPLEMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationGroup.delete
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_SUCCESS:
            return {
                ...state,
                annotationGroup: {},
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.find
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_FIND_REQUEST:
            return {
                ...state,
                annotationGroup: {},
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_FIND_SUCCESS:

            return {
                ...state,
                annotationGroup: action.payload.annotationGroup,
                biocurations: action.payload.biocurations,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationGroup.find
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_REQUEST:
            return {
                ...state,
                annotationGroup: {},
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_SUCCESS:
            return {
                ...state,
                annotationGroup: action.payload.annotationGroup,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationGroup.new
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_SUCCESS:
            return {
                ...state,
                annotationGroup: action.payload.annotationGroup,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_NEW_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationGroup.reset
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_GROUP_RESET:
            return {
                ...state,
                annotationGroup: {},
            };

        // annotation.updateField - updates redux from local state onBlur
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_UPDATE_FIELD: {
            targetAnnotationId = action.payload.annotationId;
            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(targetAnnotationId) &&
                stringExists(fieldName)) {

                annotationGroupCopy = objectDeepCopy(state.annotationGroup);

                existingAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
                    return item.annotation._id === targetAnnotationId;
                });

                if (existingAnnotationObjIndex !== -1) {

                    annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].annotation[fieldName] = fieldValue;

                    annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].dirty = true;

                    return {
                        ...state,
                        annotationGroup: annotationGroupCopy,
                    };

                } else {

                    return {
                        ...state,
                    };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // annotation.annotation update - updates redux from local state onBlur
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_OBJ_STATE_UPDATE: {
            annotation = action.payload;

            if (objectExists(annotation)) {

                targetAnnotationId = annotation._id;

                if (stringExists(targetAnnotationId)) {

                    annotationGroupCopy = objectDeepCopy(state.annotationGroup);

                    existingAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
                        return item.annotation._id === targetAnnotationId;
                    });

                    if (existingAnnotationObjIndex !== -1) {

                        annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].annotation = objectDeepCopy(annotation);
                        annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].dirty = true;

                        return {
                            ...state,
                            annotationGroup: annotationGroupCopy,
                        };

                    } else {

                        return {
                            ...state,
                        };
                    }

                } else {

                    return {
                        ...state,
                    };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // annotation.variantObj update - updates redux from local state onBlur
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_VARIANT_OBJ_STATE_UPDATE: {
            targetAnnotationId = action.payload.annotationId;
            variantObj = action.payload.variantObj;

            if (stringExists(targetAnnotationId) &&
                objectExists(variantObj)) {

                annotationGroupCopy = variantObjInject(targetAnnotationId, variantObj, state.annotationGroup);

                return {
                    ...state,
                    annotationGroup: annotationGroupCopy,
                };

            } else {
                return {
                    ...state,
                };
            }

        }

        // annotation.new
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_NEW_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_NEW_SUCCESS:
            // adds new annotation to end of the group
            newAnnotationObj = objectDeepCopy(action.payload.annotationObj);
            annotationGroupCopy = objectDeepCopy(state.annotationGroup);
            annotationGroupCopy.annotationObjs.push(newAnnotationObj);

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_NEW_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.delete
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_SUCCESS:
            annotationGroup = action.payload.annotationGroup;
            openDialog = true;

            if (objectExists(annotationGroup)) {
                if (!arrayExists(annotationGroup.annotationObjs)) {
                    // no annotations exist in the group, so ask
                    // the pdfViewer to delete the highlight from the instantJson
                    if (stringExists(annotationGroup.annotationGroupId)) {
                        const annotationGroupId = annotationGroup.annotationGroupId;
                        deleteHighlightAnnotation(annotationGroupId);
                        annotationGroup = null;
                        openDialog = false;
                    }
                }
            }

            // replaces the annotationGroup, closes the dialog if all annotations
            // in the group have been deleted
            return {
                ...state,
                annotationGroup,
                annotationEditOpenDialog: openDialog,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATION_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.submitAnnotationType
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS:
            // updates a single annotationObj in the group
            annotationObj = action.payload.annotationObj;

            annotationGroupCopy = serverAnnotationObjInject(annotationObj, state.annotationGroup);

            // the revised annotation was saved by the server, so it is not dirty
            annotationGroupCopy.dirty = false;

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.submitGeneSymbol
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_SUCCESS:
            // updates a single annotationObj in the group
            annotationObj = action.payload.annotationObj;

            annotationGroupCopy = serverAnnotationObjInject(annotationObj, state.annotationGroup);

            // the revised annotation was saved by the server, so it is not dirty
            annotationGroupCopy.dirty = false;

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_GENE_SYMBOL_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.submitDisease
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_SUCCESS:
            // updates a single annotationObj in the group
            annotationObj = action.payload.annotationObj;

            annotationGroupCopy = serverAnnotationObjInject(annotationObj, state.annotationGroup);

            // the revised annotation was saved by the server, so it is not dirty
            annotationGroupCopy.dirty = false;

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_DISEASE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.submitClassificationCode
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS:
            // updates a single annotationObj in the group
            annotationObj = action.payload.annotationObj;

            annotationGroupCopy = serverAnnotationObjInject(annotationObj, state.annotationGroup);

            // the revised annotation was saved by the server, so it is not dirty
            annotationGroupCopy.dirty = false;

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.deleteVariantObj - removes variantObj
        // only from redux (not from db)
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_REQUEST:
            targetAnnotationId = action.payload.annotationId;
            targetVariantObjId = action.payload.variantObjId;

            annotationGroupCopy = variantObjDelete(targetAnnotationId, targetVariantObjId, state.annotationGroup);
            annotationGroupCopy.dirty = true;

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_SUCCESS:
            // updates a single annotation in the annotationGroup.  only
            // the annotation of the annotationGroup is updated (not schemaClassifications or schemaFields)
            targetAnnotation = objectDeepCopy(action.payload.annotation);

            annotationGroupCopy = objectDeepCopy(state.annotationGroup);

            existingAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
                return item.annotation._id === targetAnnotation._id;
            });

            if (existingAnnotationObjIndex !== -1) {
                annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].annotation = targetAnnotation;
            }

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_DELETE_VARIANTOBJ_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.newVariantObj
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_SUCCESS:
            // updates a single annotation in the annotationGroup.  only
            // the annotation of the annotationGroup is updated (not schemaFields or schemaClassifications)
            targetAnnotation = objectDeepCopy(action.payload.annotation);

            annotationGroupCopy = objectDeepCopy(state.annotationGroup);

            existingAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
                return item.annotation._id === targetAnnotation._id;
            });

            if (existingAnnotationObjIndex !== -1) {
                annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].annotation = targetAnnotation;
            }

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_NEW_VARIANTOBJ_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotation.saveVariantObj
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_SUCCESS:
            // updates a single annotation in the annotationGroup.  only
            // the annotation of the annotationGroup is updated (not schemaFields or schemaClassifications)
            targetAnnotation = objectDeepCopy(action.payload.annotation);

            annotationGroupCopy = objectDeepCopy(state.annotationGroup);

            existingAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
                return item.annotation._id === targetAnnotation._id;
            });

            if (existingAnnotationObjIndex !== -1) {
                annotationGroupCopy.annotationObjs[existingAnnotationObjIndex].annotation = targetAnnotation;
            }

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_VARIANTOBJ_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationSave
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_SUCCESS:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationJsonSave
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_SUCCESS:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_SAVE_ANNOTATION_JSON_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // annotationsSave - 'big save' - saves all dirty annotationObj.annotation
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_SUCCESS:
            // reset 'dirty' flags
            annotationGroupCopy = annotationObjDirtyReset(state.annotationGroup, false);

            return {
                ...state,
                annotationGroup: annotationGroupCopy,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variantObj.variantValidatorFind 
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_SUCCESS:
            targetAnnotationId = action.payload.annotationId;
            targetVariantObjId = action.payload.variantId;
            variantValidatorObj = action.payload.variantValidatorObj;

            if (stringExists(targetAnnotationId) &&
                stringExists(targetVariantObjId) &&
                objectExists(variantValidatorObj)) {

                annotationGroupCopy = initFromVariantValidatorObj(
                    targetAnnotationId,
                    targetVariantObjId,
                    variantValidatorObj,
                    state.annotationGroup
                );

                return {
                    ...state,
                    annotationGroup: annotationGroupCopy,
                };

            } else {
                return {
                    ...state,
                };
            }

        case Actions.DOCUMENT_ANNOTATOR_VARIANT_VALIDATOR_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        /**** external actions ********************************************************/

        /*
          responding to tableAnnotator actions
        */
        case TableAnnotatorActions.TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS:
            // note: biocurations from the table are inserted here
            // to support editing table annotations within the
            // documentAnnotator
            return {
                ...state,
                biocurations: action.payload.biocurations,
            };

        /**
         *  responding to annotationEditor actions
         */
        case AnnotationEditorActions.ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS:
            // note: biocurations from the table are inserted here
            // to support editing table annotations within the
            // documentAnnotator
            return {
                ...state,
                annotationGroup: action.payload.annotationGroup,
                biocurations: action.payload.biocurations,
            };

        /************* */
        default: {
            return state;
        }
    }
    // eslint-enable no-case-declarations

};

export default documentAnnotatorDbReducer;