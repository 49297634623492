/**
 * @file sessionStorage.js
 * @description stores strings or objects
 *
 *  https://javascript.plainenglish.io/localstorage-vs-sessionstorage-c22164aff067
 * 
 *  see also (interesting alternative)
 *  https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
 * 
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 28, 2022 18:42
 */
import _ from 'lodash';

import { assertParam } from 'src/@common/asserts';

const knownPrefix = '~jgm~';

export function saveKey(keyName, value) {
    assertParam('keyName', keyName, 'string');
    assertParam('value', value);

    let storeValue = value;
    if (_.isObject(value)) {
        // stringify the object with a known prefix
        storeValue = `${knownPrefix}${JSON.stringify(value)}`;
    }

    if (keyName && value) {
        sessionStorage.setItem(keyName, storeValue);
    }
}

export function getKey(keyName) {
    assertParam('keyName', keyName, 'string');

    let storedValue = '';

    if (keyName) {

        try {
            const keyValue = sessionStorage.getItem(keyName);

            if (keyValue) {
                if (keyValue.includes(knownPrefix)) {
                    // value is a stringified object
                    storedValue = keyValue.replace(knownPrefix, '');
                    storedValue = JSON.parse(storedValue);
                } else {
                    storedValue = keyValue;
                }
            }

        } catch (err) {
            console.error(err);
        }
    }

    return storedValue;
}

export function clearKey(keyName) {
    assertParam('keyName', keyName, 'string');

    if (keyName) {
        sessionStorage.removeItem(keyName);
    }
}