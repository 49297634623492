/**
 * @file annotationEditor.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 08, 2023 14:19
 */
import * as Actions from 'src/store/actions/annotationEditor.actions';

const initialState = {
    annotationId: '',
    annotationViewData: {},
    dataLoaded: false,

    openAnnotationEditorDialog: false,
};

const annotationEditorReducer = (state = initialState, action) => {
    let licenseKey = null;

    switch (action.type) {

        // setters
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_EDITOR_ANNOTATION_ID_SET: {
            return {
                ...state,
                annotationId: action.payload,
            };
        }

        case Actions.ANNOTATION_EDITOR_DIALOG_OPEN_SET: {
            return {
                ...state,
                openAnnotationEditorDialog: action.payload,
            };
        }

        // annotationEditor
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_EDITOR_EDIT_ANNOTATION_REQUEST:
            return {
                ...state,
                annotationViewData: {},
                dataLoaded: false,
            };

        case Actions.ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS:

            licenseKey = import.meta.env.VITE_APP_PSPDFKIT_LICENSE_KEY && import.meta.env.VITE_APP_PSPDFKIT_LICENSE_KEY.trim();

            if (!licenseKey) {
                throw new Error('Invalid PSPDFKit license key.');
            }            // note: see the documentAnnotatorDb and documentAnnotatorPages
            // reducers, which respond to this action to incorporate
            // this data into its state to support annotation editing
            return {
                ...state,
                dataLoaded: true,
                openAnnotationEditorDialog: true,
            };

        case Actions.ANNOTATION_EDITOR_EDIT_ANNOTATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default: {
            return state;
        }
    }
};

export default annotationEditorReducer;
