/**
 * @file geneModelRoutes.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 09, 2023 17:08
 */
import { lazy } from 'react';
import Loader from './Loader';

const GeneModelEdit = Loader(
    lazy(() => import('src/content/GeneModel/edit/EditContainer'))
);

const GeneModelSearch = Loader(
    lazy(() => import('src/content/GeneModel/search/SearchContainer'))
);

const GeneDiseaseEdit = Loader(
    lazy(() => import('src/content/GeneModel/edit/Disease/DiseaseContainer'))
);

const geneModelRoutes = [
    {
        path: '',
        element: <GeneModelSearch />
    },
    {
        path: 'search',
        element: <GeneModelSearch />
    },
    {
        path: 'edit/:geneModelId/',
        element: <GeneModelEdit />
    },
    {
        path: 'edit/:geneModelId/geneDisease/:geneDiseaseId',
        element: <GeneDiseaseEdit />
    },
];

export default geneModelRoutes;