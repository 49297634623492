/**
 * @file shared.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 01, 2022 17:30
 */
import * as Actions from 'src/store/actions/shared.actions';

const initialState = {
    annotationId: '',
    annotationViewData: {},
    dataLoaded: false,

    // used by document, documentAnnotator, tableAnnotator
    refreshDocType: '',
    refreshDocId: '',

    openAnnotationViewerDialog: false,
};

const sharedReducer = (state = initialState, action) => {
    switch (action.type) {

        // setters
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_ID_SET: {
            return {
                ...state,
                annotationId: action.payload,
            };
        }

        case Actions.ANNOTATION_VIEW_DIALOG_OPEN_SET: {
            return {
                ...state,
                openAnnotationViewerDialog: action.payload,
            };
        }

        case Actions.SHARED_DOCTYPE_DOCID_SET: {
            return {
                ...state,
                docType: action.payload.docType,
                docId: action.payload.docId,
            };
        }

        // annotationView
        // -------------------------------------------------------------------
        // case Actions.ANNOTATION_VIEW_REQUEST:
        //     return {
        //         ...state,
        //         annotationViewData: {},
        //         dataLoaded: false,
        //     };

        // case Actions.ANNOTATION_VIEW_SUCCESS:
        //     return {
        //         ...state,
        //         annotationViewData: action.payload.annotationViewData,
        //         dataLoaded: true,
        //     };

        // case Actions.ANNOTATION_VIEW_FAILURE:
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };

        // case Actions.ANNOTATION_VIEW_RESET: {
        //     return {
        //         ...state,
        //         annotationId: '',
        //         annotationViewData: {},
        //         dataLoaded: false,
        //         openAnnotationViewerDialog: false,
        //     };
        // }

        default: {
            return state;
        }
    }
};

export default sharedReducer;
