/**
 * @file deleteHighlightAnnotation.js
 * @description  removes a highlight annotation from the publication,
 *               based on the annotationGroupId of the highlight
 * 
 *               this occurs when all associated anotations have been removed
 *               from the group.
 *
 * 
 * https://pspdfkit.com/guides/web/annotations/create-edit-and-remove/remove/
 * 
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since May 16, 2022 20:54
 */
import { assertParam } from 'src/@common/asserts';
import GlobalDataManager from 'src/services/GlobalDataManager';
import { objectExists, stringExists } from 'src/@common/utilities';

function deleteHighlightAnnotation(annotationGroupId) {
    // the psPdfKit annotationId to delete (our annotationGroupId)
    assertParam('annotationGroupId', annotationGroupId, 'string');

    let globalDataMgr = GlobalDataManager.getInstance();

    if (stringExists(annotationGroupId)) {

        const pdfViewerInstance = globalDataMgr.pdfViewerInstance;

        if (objectExists(pdfViewerInstance)) {

            pdfViewerInstance.delete(annotationGroupId)
                .then(() => {

                });

        } else {
            console.error('null pdfViewerInstance');
        }

    }
}

export default deleteHighlightAnnotation;


