/**
 * @file sharedRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 01, 2022 17:33
 */
import { all } from 'redux-saga/effects';

// annotation
// import annotationView from 'src/store/sagas/shared/annotationView.saga';

export default function* rootSaga() {
    yield all([

        // fork(annotationView),

    ]);
}
