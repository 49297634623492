/**
 * @file routes.jsx
 * @description application menu routes
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Nov 24, 2023 16:40
 */
import MarkerComponent from 'src/services/MarkerIo';

import PasswordAuthentication from 'src/components/PasswordAuthentication';
import TokenAuthentication from 'src/components/TokenAuthentication';

import AuthLayout from 'src/layouts/AuthLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

import baseRoutes from './baseRoutes';

import authRoutes from './authRoutes';
import annotationSearchRoutes from './annotationSearchRoutes';
import dashboardRoutes from './dashboardRoutes';
import documentRoutes from './documentRoutes';
import geneModelRoutes from './geneModelRoutes';
import variantRoutes from './variantRoutes';

// dev only
import developmentRoutes from './developmentRoutes';

function routes() {

    const nodeEnv = import.meta.env.VITE_APP_NODEENV;

    const appRoutes = [
        {
            path: 'auth',
            element: <AuthLayout />,
            children: authRoutes
        },
        {
            path: '',
            element: <BaseLayout />,
            children: baseRoutes
        },

        // Extended Sidebar Layout
        {
            path: '',
            element: (
                <TokenAuthentication>
                    <PasswordAuthentication>
                        <MarkerComponent />
                        <ExtendedSidebarLayout />
                    </PasswordAuthentication>
                </TokenAuthentication>
            ),
            children: [
                {
                    path: 'dashboard',
                    children: dashboardRoutes
                },
                {
                    path: 'annotations',
                    children: annotationSearchRoutes
                },
                {
                    path: 'document',
                    children: documentRoutes
                },
                {
                    path: 'geneModel',
                    children: geneModelRoutes
                },
                {
                    path: 'variant',
                    children: variantRoutes
                },
            ]
        }
    ];

    if (nodeEnv === 'development') {

        appRoutes[1].children.push(
            {
                path: 'development',
                children: developmentRoutes
            }
        );
    }

    return appRoutes;
}

export default routes;



