/**
 * @file variantRoutes.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 13:21
 */
import { lazy } from 'react';
import Loader from './Loader';

const VariantEdit = Loader(
    lazy(() => import('src/content/Variant/edit/EditContainer'))
);

const VariantSearch = Loader(
    lazy(() => import('src/content/Variant/search/SearchContainer'))
);

const VariantDiseaseEdit = Loader(
    lazy(() => import('src/content/Variant/edit/Disease/DiseaseContainer'))
);

const variantRoutes = [
    {
        path: '',
        element: <VariantSearch />
    },
    {
        path: 'search',
        element: <VariantSearch />
    },
    {
        path: 'edit/:variantId/',
        element: <VariantEdit />
    },
    {
        path: 'diseaseEdit/:variantDiseaseId/',
        element: <VariantDiseaseEdit />
    },
];

export default variantRoutes;