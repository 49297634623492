/**
 * @file geneModelPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 22, 2023 09:53
 */

// -------------------------------------------------------------------
// geneModel.search
// -------------------------------------------------------------------
export const GENE_MODEL_SEARCH_UPDATE_FIELD = 'GENE_MODEL_SEARCH_UPDATE_FIELD';
export function geneModelSearchUpdateField(fieldName, fieldValue) {
    return {
        type: GENE_MODEL_SEARCH_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

export const GENE_MODEL_SEARCH_SESSION_SET = 'GENE_MODEL_SEARCH_SESSION_SET';
export function geneModelSearchSessionSet(filterText, searchTerm) {
    return {
        type: GENE_MODEL_SEARCH_SESSION_SET,
        payload: {
            filterText,
            searchTerm,
        },
    };
}

// -------------------------------------------------------------------
// geneDisease.classification.annotationDistribCharts
// -------------------------------------------------------------------
export const ANNOTATION_DISTRIB_CHARTS_OPEN_DIALOG_SET = 'ANNOTATION_DISTRIB_CHARTS_OPEN_DIALOG_SET';
export function annotationDistribChartsDialogSet(value) {
    return {
        type: ANNOTATION_DISTRIB_CHARTS_OPEN_DIALOG_SET,
        payload: value,
    };
}

export const GENE_DISEASE_CLASSIFICATION_DIALOG_SET = 'GENE_DISEASE_CLASSIFICATION_DIALOG_SET';
export function geneDiseaseClassificationDialogSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_DIALOG_SET,
        payload: value,
    };
}

export const GENE_DISEASE_CLASSIFICATION_NEW_DIALOG_SET = 'GENE_DISEASE_CLASSIFICATION_NEW_DIALOG_SET';
export function geneDiseaseClassificationNewDialogSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_NEW_DIALOG_SET,
        payload: value,
    };
}

export const GENE_DISEASE_NEW_OPEN_DIALOG_SET = 'GENE_DISEASE_NEW_OPEN_DIALOG_SET';
export function geneDiseaseNewDialogSet(value) {
    return {
        type: GENE_DISEASE_NEW_OPEN_DIALOG_SET,
        payload: value,
    };
}

export const GENE_MODEL_NEW_OPEN_DIALOG_SET = 'GENE_MODEL_NEW_OPEN_DIALOG_SET';
export function geneModelNewOpenDialogSet(value) {
    return {
        type: GENE_MODEL_NEW_OPEN_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// viewGeneModel
// -------------------------------------------------------------------
export const GENE_MODEL_VIEW_OPEN_DIALOG_SET = 'GENE_MODEL_VIEW_OPEN_DIALOG_SET';
export function geneModelViewOpenDialogSet(value) {
    return {
        type: GENE_MODEL_VIEW_OPEN_DIALOG_SET,
        payload: value,
    };
}