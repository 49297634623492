/**
 * @file annotationSearchPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 15, 2023 14:49
 */
import * as Actions from 'src/store/actions/annotationSearch/annotationSearchPages.actions';

const initialState = {
    // annotation session parameters
    annotatorId: null,
    docId: null,
    docType: null,

    searchMethod: 'fuzzy',
    searchTerm: '',

    // annotatorEditor
    annotatorEditorOpenDialog: false,

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const annotationSearchPagesReducer = (state = initialState, action) => {

    switch (action.type) {

        // annotationSearch.setup
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_ANNOTATOR_ID_SET:
            return {
                ...state,
                annotatorId: action.payload,
            };

        case Actions.ANNOTATION_SEARCH_DOC_ID_SET:
            return {
                ...state,
                docId: action.payload,
            };

        case Actions.ANNOTATION_SEARCH_DOC_TYPE_SET:
            return {
                ...state,
                docType: action.payload,
            };

        case Actions.ANNOTATION_SEARCH_METHOD_SET:
            return {
                ...state,
                searchMethod: action.payload,
            };

        case Actions.ANNOTATION_SEARCH_TERM_SET:
            return {
                ...state,
                searchTerm: action.payload,
            };


        // annotationSearch.annotatorEditor
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_SEARCH_ANNOTATION_EDITOR_DIALOG_OPEN:
            return {
                ...state,
                annotatorEditorOpenDialog: action.payload,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default annotationSearchPagesReducer;