
// -------------------------------------------------------------------
// geneModels.summary
// -------------------------------------------------------------------
export const DASHBOARD_COLLECTION_COUNTS_REQUEST = 'DASHBOARD_COLLECTION_COUNTS_REQUEST';
export function collectionCountsRequest() {
    return {
        type: DASHBOARD_COLLECTION_COUNTS_REQUEST,
    };
}

export const DASHBOARD_COLLECTION_COUNTS_SUCCESS = 'DASHBOARD_COLLECTION_COUNTS_SUCCESS';
export function collectionCountsSuccess(results) {
    return {
        type: DASHBOARD_COLLECTION_COUNTS_SUCCESS,
        payload: results,
    };
}

export const DASHBOARD_COLLECTION_COUNTS_FAILURE = 'DASHBOARD_COLLECTION_COUNTS_FAILURE';
export function collectionCountsFailure(error) {
    return {
        type: DASHBOARD_COLLECTION_COUNTS_FAILURE,
        payload: JSON.stringify(error),
    };
}