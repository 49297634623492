/**
 * @file variantDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 28, 2023 10:43
 */
import { arrayDeepCopy, arrayExists, isDiff, objectDeepCopy, objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';
import * as Actions from 'src/store/actions/variant/variantDb.actions';

const initialState = {

    dataLoaded: false,

    geneDiseaseAnnotations: [], // supporting annotations for a single geneDisease
    geneDiseaseClassificationAnnotations: [], // supporting annotations for a single geneDiseaseClassification
    geneDiseaseClassificationView: {},
    geneDiseaseView: {},

    pathogenicityCategories: {}, // variantDisease classifications category summary

    variant: {},

    variantClassificationRules: [],

    variantDirty: false,

    variantDisease: {},

    variantDiseaseClassification: {},
    variantDiseaseClassificationDirty: false,

    // variantDiseaseClassifications (paginated) 
    variantDiseaseClassificationsObj: {
        pageNo: 1,
        pageObjects: [],
        pageMap: {},
        pageSize: 20,
        countObj: {},
        queryObj: {},
    },

    variantDiseaseClassificationsRefresh: false,

    // variantDiseaseClassificationEvidence (paginated) 
    variantDiseaseClassificationEvidenceObj: {
        countObj: {},
        pageNo: 1,
        pageObjects: null,  // array
        pageMap: {},
        pageSize: 20,
        queryObj: {},
    },

    variantDiseaseClassificationView: {},

    variantDiseaseDirty: false,

    // variantDiseases (paginated) 
    variantDiseasesObj: {
        pageNo: 1,
        pageObjects: [],
        pageMap: {},
        pageSize: 20,
        countObj: {},
        queryObj: {},
    },

    variantDiseaseView: {},

    variantFormMode: '',
    variantId: null,

    variantNewFormFields: {},
    variantNewFormDirty: false,

    variants: [],
    refreshVariants: false,

    variantView: {},

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const variantDbReducer = (state = initialState, action) => {

    let calculatedClassificationObj = null;
    let fieldName = null;
    let fieldValue = null;
    let formFields = null;
    let objCopy = null;
    let objId = null;
    let objIndex = null;
    let _id = null;
    let pathogenicityCategories = null;
    let tmpObj = {};
    let tmpPageMap = {};

    let tmpServerObj = {};
    let tmpStateObj = {};

    let variantClone = null;
    let variantDiseaseObj = null;

    switch (action.type) {

        // used for testing
        // -------------------------------------------------------------------
        case Actions.VARIANT_SET: {
            return {
                ...state,
                variant: action.payload,
            };
        }

        case Actions.VARIANT_DISEASE_SET: {
            return {
                ...state,
                variantDisease: action.payload,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_SET: {
            return {
                ...state,
                variantDiseaseClassification: action.payload,
            };
        }

        case Actions.VARIANT_PATHOGENICITY_CATEGORIES_SET: {
            return {
                ...state,
                pathogenicityCategories: action.payload,
            };
        }

        case Actions.VARIANT_CLASSIFICATION_RULES_SET: {
            return {
                ...state,
                variantClassificationRules: action.payload,
            };
        }

        // resets
        // -------------------------------------------------------------------

        // resets all variant state
        case Actions.VARIANT_DB_RESET_STATE: {
            return objectDeepCopy(initialState)
        }

        case Actions.VARIANT_RESET: {
            return {
                ...state,
                variant: {}
            };
        }

        case Actions.VARIANTS_RESET: {
            return {
                ...state,
                variants: {}
            };
        }

        case Actions.VARIANT_VIEW_RESET:
            return {
                ...state,
                variantView: {},
            };

        case Actions.VARIANT_DISEASE_RESET: {
            return {
                ...state,
                variantDisease: {},
                variantDiseaseId: null,
                variantDiseaseDirty: false,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_RESET: {
            let tmpObj = objectDeepCopy(initialState.variantDiseaseClassificationsObj);

            return {
                ...state,
                variantDiseaseClassificationsObj: tmpObj,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_RESET: {
            let tmpObj = objectDeepCopy(initialState.variantDiseaseClassificationEvidenceObj);

            return {
                ...state,
                variantDiseaseClassificationEvidenceObj: tmpObj,
            };
        }

        // setters
        // -------------------------------------------------------------------
        case Actions.VARIANT_ID_SET: {
            return {
                ...state,
                variantId: action.payload,
            };
        }

        // form mode indicators
        // -------------------------------------------------------------------
        case Actions.VARIANT_FORM_MODE_SET: {
            return {
                ...state,
                variantFormMode: action.payload,
            };
        }

        // variant.variantValidatorFind 
        // -------------------------------------------------------------------
        case Actions.VARIANT_VARIANT_VALIDATOR_FIND_REQUEST:
            return {
                ...state,
                referenceVariant: {},
            };

        case Actions.VARIANT_VARIANT_VALIDATOR_FIND_SUCCESS:

            variantClone = objectDeepCopy(state.variant);

            if (objectExists(action.payload.variantObj) &&
                objectExists(action.payload.variantObj.gDotObj)) {

                variantClone.gDotObj = objectDeepCopy(action.payload.variantObj.gDotObj);

                return {
                    ...state,
                    variant: variantClone,
                };

            } else {
                return {
                    ...state,
                };
            }

        case Actions.VARIANT_VARIANT_VALIDATOR_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variants.refresh
        // -------------------------------------------------------------------
        case Actions.VARIANTS_REFRESH_SET: {
            return {
                ...state,
                refreshVariants: action.payload,
            };
        }

        // variants.delete
        // -------------------------------------------------------------------
        case Actions.VARIANTS_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANTS_DELETE_SUCCESS: {
            return {
                ...state,
                variants: action.payload.variants,
            };
        }

        case Actions.VARIANTS_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variant.find
        // -------------------------------------------------------------------
        case Actions.VARIANT_FIND_REQUEST:
            return {
                ...state,
                variantDirty: false,
                variant: {},
                // variantDisease: {},
                // variantDiseases: [],
                // geneDiseases: [],
                // variantDiseaseClassifications: [],
                // geneDiseaseClassifications: [],
            };

        case Actions.VARIANT_FIND_SUCCESS:
            return {
                ...state,
                variant: action.payload.variant,
            };


        case Actions.VARIANT_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variant.search
        // -------------------------------------------------------------------
        case Actions.VARIANT_SEARCH_REQUEST:
            return {
                ...state,
                variants: [],
            };

        case Actions.VARIANT_SEARCH_SUCCESS:

            return {
                ...state,
                refreshVariants: false,
                variants: action.payload.variants,
            };

        case Actions.VARIANT_SEARCH_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variant.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.VARIANT_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                variantClone = objectDeepCopy(state.variant);

                switch (fieldName) {

                    case 'validationWarnings':

                        if (objectExists(variantClone) &&
                            objectKeyExists(variantClone, 'variantRef') &&
                            objectExists(variantClone.variantRef) &&
                            objectKeyExists(variantClone.variantRef, 'validationWarnings') &&
                            arrayExists(variantClone.variantRef.validationWarnings)) {

                            variantClone.variantRef.validationWarnings = arrayDeepCopy(fieldValue);

                            return {
                                ...state,
                                variant: variantClone,
                                variantDirty: true,
                            };

                        } else {

                            return {
                                ...state,
                            };
                        }

                    case 'gDotObj':

                        if (objectExists(variantClone) &&
                            objectKeyExists(variantClone, 'gDotObj')) {

                            variantClone.gDotObj = objectDeepCopy(fieldValue);

                            return {
                                ...state,
                                variant: variantClone,
                                variantDirty: true,
                            };

                        } else {

                            return {
                                ...state,
                            };
                        }

                    default:
                        variantClone[fieldName] = fieldValue;

                        return {
                            ...state,
                            variant: variantClone,
                            variantDirty: true,
                        };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // variant.new
        // -------------------------------------------------------------------
        case Actions.VARIANT_NEW_REQUEST:
            return {
                ...state,
                variant: {},
                variantDirty: false,
            };

        case Actions.VARIANT_NEW_SUCCESS:
            return {
                ...state,
                variantId: action.payload.variantId,
            };

        case Actions.VARIANT_NEW_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variant.new.updateFields - updates all formFields
        //    in the variantNew form
        // -------------------------------------------------------------------
        case Actions.VARIANT_NEW_UPDATE_FIELDS: {

            formFields = action.payload;

            if (objectExists(formFields)) {

                variantClone = objectDeepCopy(state.variant);

                switch (fieldName) {
                    default:
                        variantClone[fieldName] = fieldValue;

                        return {
                            ...state,
                            variantNewFormFields: objectDeepCopy(formFields),
                            variantNewFormDirty: true,
                        };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // variant.view
        // -------------------------------------------------------------------
        case Actions.VARIANT_VIEW_REQUEST:
            return {
                variantView: {},
                ...state,
            };

        case Actions.VARIANT_VIEW_SUCCESS:
            return {
                ...state,
                variantView: action.payload.variantView,
            };


        case Actions.VARIANT_VIEW_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // variant.variantUpdate
        // -------------------------------------------------------------------
        case Actions.VARIANT_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_UPDATE_SUCCESS: {
            return {
                ...state,
                variant: action.payload.variant,
                variantDirty: false,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.delete - deletes a single variantDisease
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_DELETE_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.variantDiseasesObj);
            tmpServerObj = objectDeepCopy(action.payload.diseaseObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved diseases
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                variantDiseasesObj: tmpStateObj,
            };
        }

        case Actions.VARIANT_DISEASE_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.find - finds a single variantDisease
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_FIND_REQUEST: {
            return {
                ...state,
                variantDisease: {},
                geneDiseaseView: {},
            };
        }

        case Actions.VARIANT_DISEASE_FIND_SUCCESS: {

            if (!objectExists(state.variant)) {
                // back-fill variant after a page refresh
                return {
                    ...state,
                    variant: action.payload.variant,
                    variantDisease: action.payload.variantDisease,
                    geneDiseaseView: action.payload.geneDiseaseView,

                    variantDiseaseDirty: false,

                    serverErrorExists: action.payload.errorExists,
                    serverMessage: action.payload.serverMessage,
                };
            } else {

                return {
                    ...state,
                    variantDisease: action.payload.variantDisease,
                    geneDiseaseView: action.payload.geneDiseaseView,

                    variantDiseaseDirty: false,

                    serverErrorExists: action.payload.errorExists,
                    serverMessage: action.payload.serverMessage,
                };
            }

        }

        case Actions.VARIANT_DISEASE_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.insert - creates and inserts a single variantDisease
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_INSERT_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_INSERT_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.variantDiseasesObj);
            tmpServerObj = objectDeepCopy(action.payload.diseaseObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved diseases
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                variantDiseasesObj: tmpStateObj,
            };
        }

        case Actions.VARIANT_DISEASE_INSERT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                objCopy = null;

                switch (fieldName) {

                    case 'directorClassification':
                        objCopy = objectDeepCopy(state.variantDisease);

                        fieldValue.timestamp = new Date(fieldValue.timestamp).toISOString();
                        objCopy[fieldName] = fieldValue;

                        return {
                            ...state,
                            variantDisease: objCopy,
                            variantDiseaseDirty: true,
                        };

                    default:
                        objCopy = objectDeepCopy(state.variantDisease);
                        objCopy[fieldName] = fieldValue;

                        return {
                            ...state,
                            variantDisease: objCopy,
                            variantDiseaseDirty: true,
                        };
                }

            } else {

                return {
                    ...state,
                };
            }
        }

        // variantDisease.update - returns updated variantDisease
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_UPDATE_SUCCESS: {
            return {
                ...state,
                variantDisease: action.payload.variantDisease,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
                variantDiseaseDirty: false,
            };
        }

        case Actions.VARIANT_DISEASE_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.classificationCalculate 
        // -------------------------------------------------------------------
        case Actions.VARIANT_CLASSIFICATION_CALCULATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_CLASSIFICATION_CALCULATE_SUCCESS: {
            calculatedClassificationObj = action.payload.calculatedClassificationObj;
            pathogenicityCategories = action.payload.pathogenicityCategories;

            objCopy = objectDeepCopy(state.variantDisease);
            objCopy.calculatedClassification = calculatedClassificationObj;

            return {
                ...state,
                variantDisease: objCopy,
                pathogenicityCategories,
                variantClassificationRules: action.payload.classificationRules,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,

                variantDiseaseDirty: true,
            };
        }

        case Actions.VARIANT_CLASSIFICATION_CALCULATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.delete - deletes a single variantDiseaseClassification
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_DELETE_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.variantDiseaseClassificationsObj);
            tmpServerObj = objectDeepCopy(action.payload.classificationObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            variantDiseaseObj = objectDeepCopy(state.variantDisease);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            // 4. update the variantDisease.calculatedClassification
            variantDiseaseObj.calculateClassification = objectDeepCopy(action.payload.calculatedClassification);

            return {
                ...state,
                variantDisease: variantDiseaseObj,
                variantDiseaseClassificationsObj: tmpStateObj,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.insert - inserts a single variantDiseaseClassification
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_INSERT_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_INSERT_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.variantDiseaseClassificationsObj);
            tmpServerObj = objectDeepCopy(action.payload.classificationObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            variantDiseaseObj = objectDeepCopy(state.variantDisease);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            // 4. update the variantDisease.calculatedClassification
            variantDiseaseObj.calculateClassification = objectDeepCopy(action.payload.calculatedClassification);

            return {
                ...state,
                variantDisease: variantDiseaseObj,
                variantDiseaseClassificationsObj: tmpStateObj,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_INSERT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.update - updates a single variantDiseaseClassification
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_UPDATE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_UPDATE_SUCCESS: {
            return {
                ...state,
                variantDiseaseClassificationsRefresh: true,  // must update the classifications page
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_UPDATE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.update.field - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {

                if (isDiff(state.variantDiseaseClassification[fieldName], fieldValue)) {

                    objCopy = null;

                    switch (fieldName) {

                        default:
                            objCopy = objectDeepCopy(state.variantDiseaseClassification);
                            objCopy[fieldName] = fieldValue;

                            return {
                                ...state,
                                variantDiseaseClassification: objCopy,
                                variantDiseaseClassificationDirty: true,
                            };
                    }

                } else {
                    return {
                        ...state,
                    };
                }

            } else {

                return {
                    ...state,
                };
            }

        }

        // variantDiseaseClassifications.initialQuery - finds variantDiseaseClassifications
        // and geneDiseaseClassifications for a variantDiseaseClassificationId
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_SUCCESS: {

            tmpObj = {
                pageNo: action.payload.pageNo,
                pageObjects: action.payload.pageObjects,
                pageMap: action.payload.pageMap,
                pageSize: action.payload.pageSize,
                queryObj: action.payload.queryObj,
                countObj: action.payload.countObj,
            };

            return {
                ...state,
                variantDiseaseClassificationsObj: tmpObj,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_INITIAL_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassifications.pageQuery  
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_SUCCESS: {

            tmpObj = objectDeepCopy(state.variantDiseaseClassificationsObj);

            tmpObj.pageNo = action.payload.pageNo;
            tmpObj.pageObjects = action.payload.pageObjects;

            return {
                ...state,
                variantDiseaseClassificationsObj: tmpObj,
                variantDiseaseClassificationsRefresh: false,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATIONS_PAGE_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.view
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_VIEW_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_VIEW_SUCCESS: {
            return {
                ...state,
                variantDiseaseClassificationView: action.payload.variantDiseaseClassificationView,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_VIEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.geneDiseaseAnnotationsFind
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_REQUEST: {
            return {
                ...state,
                geneDiseaseAnnotations: [],
                serverErrorExists: false,
                serverMessage: '',
            };
        }

        case Actions.VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_SUCCESS: {
            return {
                ...state,
                geneDiseaseAnnotations: action.payload.annotations,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_GENE_DISEASE_ANNOTATIONS_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDiseaseClassification.view
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_REQUEST: {
            return {
                ...state,
                geneDiseaseClassificationView: {},
            };
        }

        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_SUCCESS: {
            return {
                ...state,
                geneDiseaseClassificationView: action.payload.geneDiseaseClassificationView,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_VIEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseaseClassification.find
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_FIND_REQUEST: {
            return {
                ...state,
                variantDiseaseClassification: {},
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_FIND_SUCCESS: {
            return {
                ...state,
                variantDiseaseClassification: action.payload.variantDiseaseClassification,

                serverErrorExists: action.payload.errorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDisease.classificationEvidence.find
        // -------------------------------------------------------------------
        // case Actions.VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST: {
        //     return {
        //         ...state,
        //         evidenceAnnotations: [],
        //         qualifyingAnnotations: [],
        //     };
        // }

        // case Actions.VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS: {
        //     tmpArr1 = [];
        //     action.payload.evidenceAnnotations.forEach((item) => {
        //         item.type = 'evidenceAnnotation';
        //         tmpArr1.push(item);
        //     });

        //     tmpArr2 = [];
        //     action.payload.qualifyingAnnotations.forEach((item) => {
        //         item.type = 'qualifyingAnnotation';
        //         tmpArr2.push(item);
        //     });

        //     return {
        //         ...state,
        //         evidenceAnnotations: tmpArr1,
        //         qualifyingAnnotations: tmpArr2,

        //         serverErrorExists: action.payload.errorExits,
        //         serverMessage: action.payload.serverMessage,
        //     };
        // }

        // case Actions.VARIANT_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE: {
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };
        // }

        // variantDisease.geneDiseaseClassificationEvidence.find
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST: {
            return {
                ...state,
                geneDiseaseClassificationAnnotations: [],
            };
        }

        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS: {
            return {
                ...state,
                geneDiseaseClassificationAnnotations: action.payload.annotations,

                serverErrorExists: action.payload.errorExits,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseases.initialQuery - finds all variantDiseases and qualifying
        // geneDiseases for a single variant
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASES_INITIAL_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASES_INITIAL_QUERY_SUCCESS: {

            tmpObj = {
                pageNo: action.payload.pageNo,
                pageObjects: action.payload.pageObjects,
                pageMap: action.payload.pageMap,
                pageSize: action.payload.pageSize,
                queryObj: action.payload.queryObj,
                countObj: action.payload.countObj,
            };

            return {
                ...state,
                variantDiseasesObj: tmpObj,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };

        }

        case Actions.VARIANT_DISEASES_INITIAL_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseases.pageQuery 
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASES_PAGE_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASES_PAGE_QUERY_SUCCESS: {

            tmpObj = objectDeepCopy(state.variantDiseasesObj);

            tmpObj.pageNo = action.payload.pageNo;
            tmpObj.pageObjects = action.payload.pageObjects;

            return {
                ...state,
                variantDiseasesObj: tmpObj,
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };

        }

        case Actions.VARIANT_DISEASES_PAGE_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseases.delete
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASES_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASES_DELETE_SUCCESS: {
            return {
                ...state,
                variantDiseases: action.payload.variantDiseases,
            };
        }

        case Actions.VARIANT_DISEASES_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variantDiseases.updateField - updates arrays in the diseases kanban board
        // -------------------------------------------------------------------
        // case Actions.VARIANT_DISEASES_BOARD_UPDATE_FIELD: {

        //     fieldName = action.payload.fieldName;
        //     fieldValue = action.payload.fieldValue;

        //     if (stringExists(fieldName)) {

        //         switch (fieldName) {

        //             case 'variantDiseases':

        //                 return {
        //                     ...state,
        //                     variantDiseases: arrayDeepCopy(fieldValue),
        //                     variantDirty: true,
        //                 };

        //             case 'geneModelDiseases':

        //                 return {
        //                     ...state,
        //                     geneDiseases: arrayDeepCopy(fieldValue),
        //                 };


        //             default:
        //                 return {
        //                     ...state,
        //                 };
        //         }

        //     } else {

        //         return {
        //             ...state,
        //         };
        //     }

        // }

        case Actions.VARIANT_DISEASE_APPROVAL_SET: {
            fieldValue = action.payload.value;
            objId = action.payload.objId;

            objCopy = objectDeepCopy(state.variantDiseasesObj);

            // update the pageObject
            objIndex = objCopy.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                objCopy.pageObjects[objIndex].approved = fieldValue;
            }

            return {
                ...state,
                variantDiseasesObj: objCopy,
            };

        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_APPROVAL_SET: {
            fieldValue = action.payload.value;
            objId = action.payload.objId;

            objCopy = objectDeepCopy(state.variantDiseaseClassificationsObj);

            // update the pageObject
            objIndex = objCopy.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                objCopy.pageObjects[objIndex].approved = fieldValue;
            }

            return {
                ...state,
                variantDiseaseClassificationsObj: objCopy,
            };

        }

        // DEPRECATED
        // variantDiseaseClassifications.board.updateField - 
        // updates arrays in the classifications kanban board
        // -------------------------------------------------------------------
        // case Actions.VARIANT_CLASSIFICATIONS_BOARD_UPDATE_FIELD: {

        //     fieldName = action.payload.fieldName;
        //     fieldValue = action.payload.fieldValue;

        //     if (stringExists(fieldName)) {

        //         switch (fieldName) {

        //             case 'variantDiseaseClassifications':

        //                 return {
        //                     ...state,
        //                     variantDiseaseClassifications: arrayDeepCopy(fieldValue),
        //                     classificationsFormDirty: true,
        //                 };

        //             case 'geneDiseaseClassifications':

        //                 return {
        //                     ...state,
        //                     geneDiseaseClassifications: arrayDeepCopy(fieldValue),
        //                 };


        //             default:
        //                 console.error('unknown fieldName', fieldName)
        //                 return {
        //                     ...state,
        //                 };
        //         }

        //     } else {

        //         return {
        //             ...state,
        //         };
        //     }

        // }

        // variant.geneDiseaseView
        // -------------------------------------------------------------------
        case Actions.VARIANT_GENE_DISEASE_VIEW_REQUEST: {
            return {
                ...state,
                geneDiseaseView: {},
            };
        }

        case Actions.VARIANT_GENE_DISEASE_VIEW_SUCCESS: {
            return {
                ...state,
                geneDiseaseView: action.payload.geneDiseaseView,
            };
        }

        case Actions.VARIANT_GENE_DISEASE_VIEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // variant.variantDiseaseView
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_VIEW_REQUEST: {
            return {
                ...state,
                variantDiseaseView: {},
            };
        }

        case Actions.VARIANT_DISEASE_VIEW_SUCCESS: {
            return {
                ...state,
                variantDiseaseView: action.payload.variantDiseaseView,
            };
        }

        case Actions.VARIANT_DISEASE_VIEW_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classification.evidence.initialQuery
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS: {

            tmpObj = {
                pageNo: action.payload.pageNo,
                pageObjects: action.payload.pageObjects,
                pageMap: action.payload.pageMap,
                pageSize: action.payload.pageSize, // informational only. not changed here.
                queryObj: action.payload.queryObj,
                countObj: action.payload.countObj,
            };

            return {
                ...state,
                variantDiseaseClassificationEvidenceObj: objectDeepCopy(tmpObj),
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classification.evidence.pageQuery
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS: {

            tmpObj = objectDeepCopy(state.variantDiseaseClassificationEvidenceObj);

            tmpObj.pageNo = action.payload.pageNo;
            tmpObj.pageObjects = action.payload.pageObjects;

            return {
                ...state,
                variantDiseaseClassificationEvidenceObj: objectDeepCopy(tmpObj),
                serverErrorExists: action.payload.serverErrorExists,
                serverMessage: action.payload.serverMessage,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classificationEvidence.approval.set
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET: {

            fieldValue = action.payload.value;
            _id = action.payload._id;

            objCopy = objectDeepCopy(state.variantDiseaseClassificationEvidenceObj);

            objIndex = objCopy.pageObjects.findIndex((item) => {
                return item._id === _id;
            });

            if (objIndex !== -1) {
                objCopy.pageObjects[objIndex].approved = fieldValue;

                // if approved, move the _id as the new evidenceId
                if (fieldValue === true) {
                    objCopy.pageObjects[objIndex].evidenceId = objCopy.pageObjects[objIndex]._id;
                }

                return {
                    ...state,
                    variantDiseaseClassificationEvidenceObj: objCopy,
                };

            } else {

                return {
                    ...state,
                };
            }

        }

        // geneDisease.classificationEvidence.delete
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS: {

            tmpStateObj = objectDeepCopy(state.variantDiseaseClassificationEvidenceObj);
            tmpServerObj = objectDeepCopy(action.payload.evidenceObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                variantDiseaseClassificationEvidenceObj: tmpStateObj,
            };

        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        // geneDisease.classificationEvidence.insert
        // -------------------------------------------------------------------
        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST: {
            return {
                ...state,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS: {
            tmpStateObj = objectDeepCopy(state.variantDiseaseClassificationEvidenceObj);

            tmpServerObj = objectDeepCopy(action.payload.evidenceObj);
            tmpPageMap = objectDeepCopy(action.payload.pageMap);

            objId = tmpServerObj.objId;

            // 1. update the pageObject
            objIndex = tmpStateObj.pageObjects.findIndex((item) => {
                return item.objId === objId;
            });

            if (objIndex !== -1) {
                tmpStateObj.pageObjects[objIndex] = objectDeepCopy(tmpServerObj);
            }

            // 2.  update the pageMap
            tmpStateObj.pageMap = objectDeepCopy(tmpPageMap);

            // 3.  update the count of approved classifications
            tmpStateObj.countObj.approvedCnt = action.payload.approvedCnt;

            return {
                ...state,
                variantDiseaseClassificationEvidenceObj: tmpStateObj,
            };
        }

        case Actions.VARIANT_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        /************* */
        default: {
            return state;
        }
    }
}

export default variantDbReducer;