/**
 * @file helpDocViewer.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 20, 2023 15:43
 */

// -------------------------------------------------------------------
// helpDocViewer.
// -------------------------------------------------------------------
export const HELP_DOC_VIEWER_OPEN_DIALOG_SET = 'HELP_DOC_VIEWER_OPEN_DIALOG_SET';
export function helpDocViewerOpenDialogSet(value) {
    return {
        type: HELP_DOC_VIEWER_OPEN_DIALOG_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// helpDocViewer.getUrl
// -------------------------------------------------------------------
export const HELP_DOC_VIEWER_GET_URL_REQUEST = 'HELP_DOC_VIEWER_GET_URL_REQUEST';
export function getUrlRequest(applicationName, fileName, title) {
    return {
        type: HELP_DOC_VIEWER_GET_URL_REQUEST,
        payload: {
            applicationName,
            fileName,
            title,
        },
    };
}

export const HELP_DOC_VIEWER_GET_URL_SUCCESS = 'HELP_DOC_VIEWER_GET_URL_SUCCESS';
export function getUrlSuccess(payload) {
    return {
        type: HELP_DOC_VIEWER_GET_URL_SUCCESS,
        payload,
    };
}

export const HELP_DOC_VIEWER_GET_URL_FAILURE = 'HELP_DOC_VIEWER_GET_URL_FAILURE';
export function getUrlFailure(error) {
    return {
        type: HELP_DOC_VIEWER_GET_URL_FAILURE,
        payload: JSON.stringify(error),
    };
}
