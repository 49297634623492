/**
 * @file tableAnnotatorDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 03, 2022 19:01
 */

export const REDIRECT_TO_TABLE_ANNOTATOR_SET = 'REDIRECT_TO_TABLE_ANNOTATOR_SET';
export function redirectToTableAnnotatorSet(value) {
    return {
        type: REDIRECT_TO_TABLE_ANNOTATOR_SET,
        payload: value,
    };
}

export const TABLE_ANNOTATOR_GENBATCHID_SET = 'TABLE_ANNOTATOR_GENBATCHID_SET';
export function genBatchIdSet(value) {
    return {
        type: TABLE_ANNOTATOR_GENBATCHID_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.reset
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_DB_RESET = 'TABLE_ANNOTATOR_DB_RESET';
export function tableAnnotatorDbReset() {
    return {
        type: TABLE_ANNOTATOR_DB_RESET,
    };
}

export const TABLE_ANNOTATOR_DB_CLEAR = 'TABLE_ANNOTATOR_DB_CLEAR';
export function tableAnnotatorDbClear() {
    return {
        type: TABLE_ANNOTATOR_DB_CLEAR,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.setup
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_ANNOTATOR_ID_SET = 'TABLE_ANNOTATOR_ANNOTATOR_ID_SET';
export function tableAnnotatorAnnotatorIdSet(annotatorId) {
    return {
        type: TABLE_ANNOTATOR_ANNOTATOR_ID_SET,
        payload: annotatorId,
    };
}

export const TABLE_ANNOTATOR_TABLE_ID_SET = 'TABLE_ANNOTATOR_TABLE_ID_SET';
export function tableAnnotatorTableIdSet(tableId) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ID_SET,
        payload: tableId,
    };
}

// -------------------------------------------------------------------
// tableAnnotator.annotateTable - sets up annotation session
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST = 'TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST';
export function tableAnnotateRequest(annotatorId, tableId, navigate) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST,
        payload: {
            annotatorId,
            tableId,
            navigate,
        }
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS = 'TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS';
export function tableAnnotateSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATE_FAILURE = 'TABLE_ANNOTATOR_TABLE_ANNOTATE_FAILURE';
export function tableAnnotateFailure(error) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.curatedTable
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_CURATED_TABLE_CHECKED_ROW_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_CHECKED_ROW_SET';
export function curatedTableRowCheckedSet(value, rowIndex) {
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_CHECKED_ROW_SET,
        payload: {
            value,
            rowIndex,
        },

    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_DISABLED_OPTIONS_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_DISABLED_OPTIONS_SET';
export function curatedTableDisabledOptionsSet(fieldsRow, globalFields) {
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_DISABLED_OPTIONS_SET,
        payload: {
            fieldsRow,
            globalFields,
        },
    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_CHECK_ALL_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_CHECK_ALL_SET';
export function curatedTableCheckAllSet(value) {
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_CHECK_ALL_SET,
        payload: value,
    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_FIELD_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_FIELD_SET';
export function curatedTableFieldSet(value, colIndex) {
    // sets the value of a single fieldsRow cell
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_FIELD_SET,
        payload: {
            value,
            colIndex,
        },
    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_FIELDS_ROW_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_FIELDS_ROW_SET';
export function curatedTableFieldsRowSet(fieldsRow) {
    // sets values of all columns in the fieldsRow
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_FIELDS_ROW_SET,
        payload: fieldsRow,
    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_COLUMN_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_COLUMN_SET';
export function curatedTableGroupNoColumnSet(value, colIndex) {
    // sets the value of a single groupsRow cell
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_COLUMN_SET,
        payload: {
            value,
            colIndex,
        },
    };
}

export const TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_ROW_SET = 'TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_ROW_SET';
export function curatedTableGroupNoRowSet(value, rowIndex) {
    return {
        type: TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_ROW_SET,
        payload: {
            value,
            rowIndex,
        },

    };
}


// -------------------------------------------------------------------
// tableAnnotator.generateAnnotations
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST = 'TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST';
export function generateAnnotationsRequest(
    annotationType,
    classificationCode,
    dataRows,
    fieldsRow,
    globalFields,
    groupingMethod,
    groupsRow,
    tableId,
) {
    return {
        type: TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST,
        payload: {
            annotationType,
            classificationCode,
            dataRows,
            fieldsRow,
            globalFields,
            groupingMethod,
            groupsRow,
            tableId,
        }
    };
}

export const TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_SUCCESS = 'TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_SUCCESS';
export function generateAnnotationsSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_FAILURE = 'TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_FAILURE';
export function generateAnnotationsFailure(error) {
    return {
        type: TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.submitAnnotationType
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST = 'TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST';
export function submitAnnotationTypeRequest(annotationType) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST,
        payload: {
            annotationType,
        }
    };
}

export const TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS = 'TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS';
export function submitAnnotationTypeSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE = 'TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE';
export function submitAnnotationTypeFailure(error) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.submitClassificationCode
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST = 'TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST';
export function submitClassificationCodeRequest(annotationType, classificationCode) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST,
        payload: {
            annotationType,
            classificationCode,
        }
    };
}

export const TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS = 'TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS';
export function submitClassificationCodeSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE = 'TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE';
export function submitClassificationCodeFailure(error) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.submitTableSettings
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_REQUEST = 'TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_REQUEST';
export function submitTableSettingsRequest(tableId, title, rating,) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_REQUEST,
        payload: {
            tableId,
            title,
            rating,
        }
    };
}

export const TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_SUCCESS = 'TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_SUCCESS';
export function submitTableSettingsSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_FAILURE = 'TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_FAILURE';
export function submitTableSettingsFailure(error) {
    return {
        type: TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.tableAnnotationsFind
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_REQUEST = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_REQUEST';
export function tableAnnotationsFindRequest(genBatchId) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_REQUEST,
        payload: {
            genBatchId,
        }
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_SUCCESS = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_SUCCESS';
export function tableAnnotationsFindSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_FAILURE = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_FAILURE';
export function tableAnnotationsFindFailure(error) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.tableAnnotationsDelete
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_REQUEST = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_REQUEST';
export function deleteTableAnnotationsRequest(annotationIds, annotatorId, tableId) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_REQUEST,
        payload: {
            annotationIds,
            annotatorId,
            tableId,
        }
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_SUCCESS = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_SUCCESS';
export function deleteTableAnnotationsSuccess(payload) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_SUCCESS,
        payload,
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_FAILURE = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_FAILURE';
export function deleteTableAnnotationsFailure(error) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// tableAnnotator.tableAnnotations.checked
// -------------------------------------------------------------------
export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ROW_SET = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ROW_SET';
export function tableAnnotationsRowCheckSet(value, rowId,) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ROW_SET,
        payload: {
            rowId,
            value,
        }
    };
}

export const TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ALL_SET = 'TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ALL_SET';
export function tableAnnotationsRowCheckAllSet(value) {
    return {
        type: TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ALL_SET,
        payload: value,
    };
}