/**
 * @file variantObjDelete.js
 * @description deletes a variantObj from a variantObjArray in redux
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 17, 2023 15:21
 */
import { arrayDeepCopy, objectDeepCopy, objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

function variantObjDelete(targetAnnotationId, targetVariantObjId, annotationGroup) {

    let annotationGroupCopy = objectDeepCopy(annotationGroup);

    if (objectExists(annotationGroupCopy)) {

        if (stringExists(targetAnnotationId)
            && stringExists(targetVariantObjId)) {

            // find the target annotationObj in the annotationGroup
            const targetAnnotationObj = annotationGroupCopy.annotationObjs.find((item) => {

                if (objectKeyExists(item, 'annotation') &&
                    objectExists(item.annotation)) {
                    return item.annotation._id === targetAnnotationId;
                } else {
                    return false;
                }

            });

            if (objectExists(targetAnnotationObj)) {

                // find the annotation in the targetAnnotationObj
                if (objectKeyExists(targetAnnotationObj, 'annotation') &&
                    objectExists(targetAnnotationObj.annotation)) {

                    let targetAnnotation = targetAnnotationObj.annotation;

                    if (objectKeyExists(targetAnnotation, 'fields') &&
                        objectExists(targetAnnotation.fields) &&
                        objectKeyExists(targetAnnotation.fields, 'variantObjArray')) {

                        // filter to exclude the targetVariantObjId
                        if (objectKeyExists(targetAnnotation.fields, 'variantObjArray')) {

                            let filteredVariantObjs = targetAnnotation.fields.variantObjArray.filter((variantObj) => {
                                return variantObj._id !== targetVariantObjId;
                            });

                            if (filteredVariantObjs) {
                                targetAnnotation.fields.variantObjArray = arrayDeepCopy(filteredVariantObjs);
                                targetAnnotationObj.dirty = true;
                            }

                        }

                    }
                }
            }

        }
    }

    return annotationGroupCopy;
}

export default variantObjDelete;