/**
 * @file developmentRoutes.jsx
 * @description used only to isolate
 *              components during development
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 19, 2023 17:32
 */
import { lazy } from 'react';

import Loader from './Loader';

const ComponentDevelopmentContainer = Loader(
    lazy(() => import('src/componentDevelopment/ComponentDevelopmentContainer'))
);

const developmentRoutes = [
    {
        path: '',
        element: <ComponentDevelopmentContainer />
    },
];

export default developmentRoutes;