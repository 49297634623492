/**
 * @file sessionAnnotationsPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 13, 2023 18:18
 */
import * as Actions from 'src/store/actions/sessionAnnotations/sessionAnnotationsPages.actions';

const initialState = {
    annotatorEditorOpenDialog: false,

    sessionAnnotationsDialog: false,

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const sessionAnnotationsPagesReducer = (state = initialState, action) => {

    switch (action.type) {

        // sessionAnnotations.annotatorEditor
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_ANNOTATION_EDITOR_DIALOG_OPEN:
            return {
                ...state,
                annotatorEditorOpenDialog: action.payload,
            };

        // sessionAnnotations.dialog
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_DIALOG_SET:
            return {
                ...state,
                sessionAnnotationsDialog: action.payload,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default sessionAnnotationsPagesReducer;