import {
    Stack,
    styled,
} from '@mui/material';

import Typography from '@mui/material/Typography';

const Root = styled('div')(({ theme }) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge, & > .logo-text': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));

function Logo() {

    return (
        <Root className="flex items-center">
            <Stack
                direction="row"
                spacing={3}
            >
                <img width={48} src="/static/images/logo/vlogo.png" alt="logo" />
                <Typography sx={{
                    mb: 2,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                }}>
                    Veriome Services
                </Typography>

            </Stack>

        </Root>
    );
}

export default Logo;
