/**
 * @file ExternalResources.menu.index.jsx
 * @description external website links
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 26, 2023 07:40
 */
import { useRef, useState } from 'react';
import {
    Box,
    Button,
    useTheme,
    CardActionArea,
    Typography,
    MenuList,
    Grid,
    Divider,
    Card,
    MenuItem,
    ListItemText,
    alpha,
    Popover,
    Stack,
    styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import Text from 'src/components/Text';

import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

import notFinishedMsg from 'src/@common/notFinishedMsg';

const CardActionAreaWrapper = styled(CardActionArea)(
    ({ theme }) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.colors.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

const MenuListWrapperSecondary = styled(MenuList)(
    ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

function HeaderMenu() {
    const { t } = useTranslation();
    const theme = useTheme();

    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ref2 = useRef(null);
    const [isOpen2, setOpen2] = useState(false);

    const handleOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    return (
        <>
            <Box
                sx={{
                    display: { xs: 'none', md: 'inline-flex' }
                }}
            >
                <Button
                    ref={ref}
                    onClick={handleOpen}
                    endIcon={<KeyboardArrowDownTwoToneIcon />}
                    color="secondary"
                    size="small"
                    sx={{
                        mr: 1,
                        px: 2,
                        backgroundColor: `${theme.colors.secondary.lighter}`,
                        color: `${theme.colors.secondary.dark}`,

                        '.MuiSvgIcon-root': {
                            color: `${theme.colors.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`
                        },

                        '&:hover': {
                            backgroundColor: `${theme.colors.secondary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.colors.secondary.main
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.colors.secondary.main
                                )}`
                            }
                        }
                    }}
                >
                    {t('External Resources')}
                </Button>
                <Button
                    ref={ref2}
                    onClick={handleOpen2}
                    endIcon={<KeyboardArrowDownTwoToneIcon />}
                    color="secondary"
                    size="small"
                    sx={{
                        px: 2,
                        backgroundColor: `${theme.colors.secondary.lighter}`,
                        color: `${theme.colors.secondary.dark}`,

                        '.MuiSvgIcon-root': {
                            color: `${theme.colors.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`
                        },

                        '&:hover': {
                            backgroundColor: `${theme.colors.secondary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.colors.secondary.main
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.colors.secondary.main
                                )}`
                            }
                        }
                    }}
                >
                    {t('Apps')}
                </Button>
            </Box>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        background: alpha(theme.colors.alpha.black[100], 0.06)
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography
                            sx={{
                                pb: 0.5
                            }}
                            variant="h4"
                        >
                            {t('External Resources')}
                        </Typography>
                        <Typography noWrap variant="subtitle2">
                            {'Helpful external websites'}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="stretch"
                    alignItems="stretch"
                    spacing={0}
                >
                    <MenuListWrapperSecondary disablePadding>

                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('ClinGen')}
                                onClick={() => {
                                    window.open('https://clinicalgenome.org/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('ClinVar')}
                                onClick={() => {
                                    window.open('https://www.ncbi.nlm.nih.gov/clinvar/', '_blank');
                                }}
                            />
                        </MenuItem>

                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('HGMD')}
                                onClick={() => {
                                    window.open('https://www.hgmd.cf.ac.uk/ac/index.php', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('Gene Clinics')}
                                onClick={() => {
                                    window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC61429/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('gnomAD')}
                                onClick={() => {
                                    window.open('https://gnomad.broadinstitute.org/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('LitVar2')}
                                onClick={() => {
                                    window.open('https://www.ncbi.nlm.nih.gov/research/litvar2/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('MONDO-OLS')}
                                onClick={() => {

                                    window.open('https://www.ebi.ac.uk/ols4', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('PubMed')}
                                onClick={() => {
                                    window.open('https://pubmed.ncbi.nlm.nih.gov/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem >
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('VarChat')}
                                onClick={() => {
                                    window.open('https://varchat.engenome.com/', '_blank');
                                }}
                            />
                        </MenuItem>
                        <MenuItem >
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={t('Variant Validator')}
                                onClick={() => {
                                    window.open('https://variantvalidator.org/service/validate/', '_blank');
                                }}
                            />
                        </MenuItem>
                    </MenuListWrapperSecondary>

                </Stack>
            </Popover>
            <Popover
                disableScrollLock
                anchorEl={ref2.current}
                onClose={handleClose2}
                open={isOpen2}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        background: theme.colors.gradients.blue3
                    }
                }}
            >
                <Box
                    sx={{
                        maxWidth: 400
                    }}
                    p={3}
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                        textAlign="center"
                        sx={{
                            color: theme.colors.alpha.trueWhite[100],
                            fontSize: theme.typography.pxToRem(18)
                        }}
                    >
                        {t('Apps')}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={{
                            color: theme.colors.alpha.trueWhite[70]
                        }}
                    >
                        {t("(not finished)")}
                    </Typography>
                    <Grid container mt={1} spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionAreaWrapper
                                    sx={{
                                        p: 2
                                    }}
                                    onClick={() => {
                                        return notFinishedMsg();
                                    }}
                                >
                                    <Text color="warning">
                                        <AccountTreeTwoToneIcon
                                            sx={{
                                                mb: 1
                                            }}
                                        />
                                    </Text>
                                    <Typography variant="h4">{t('Tasks')}</Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionAreaWrapper
                                    sx={{
                                        p: 2
                                    }}
                                    onClick={() => {
                                        return notFinishedMsg();
                                    }}
                                >
                                    <Text color="success">
                                        <ContactSupportTwoToneIcon
                                            sx={{
                                                mb: 1
                                            }}
                                        />
                                    </Text>
                                    <Typography variant="h4">{t('Knowledge Base')}</Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionAreaWrapper
                                    sx={{
                                        p: 2
                                    }}
                                    onClick={() => {
                                        return notFinishedMsg();
                                    }}
                                >
                                    <Text color="primary">
                                        <DashboardCustomizeTwoToneIcon
                                            sx={{
                                                mb: 1
                                            }}
                                        />
                                    </Text>
                                    <Typography variant="h4">{t('Scheduler')}</Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionAreaWrapper
                                    sx={{
                                        p: 2
                                    }}
                                    onClick={() => {
                                        return notFinishedMsg();
                                    }}
                                >
                                    <Text color="error">
                                        <ArticleTwoToneIcon
                                            sx={{
                                                mb: 1
                                            }}
                                        />
                                    </Text>
                                    <Typography variant="h4">{t('Calendar')}</Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderMenu;
