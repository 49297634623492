/**
 * @file NotificationService.js
 * @description displays snackbar messages
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/App.js:877-1216
 * https://github.com/iamhosseindhv/notistack/issues/120
 * 
 * @author don michael
 * @since Dec 21, 2021 15:23
 */
import { useEffect, } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { objectDeepCopy } from 'src/@common/utilities';
import { dequeueMessage } from 'src/store/actions/global.actions';

let displayed = [];

function NotificationService(props) {
    const {
        children
    } = props;

    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // start: redux state --------------------------
    const globalState = useSelector((state) => {
        return state.global;
    });

    const { notifications } = globalState;
    // end: redux state -----------------------------

    const addDisplayed = (addKey) => {
        displayed = [...displayed, addKey];
    };

    const removeDisplayed = (deleteKey) => {
        displayed = displayed.filter((key) => {
            return key !== deleteKey;
        });
    };

    useEffect(() => {

        notifications.forEach(({ key, message, options = {} }) => {

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // can't mutate redux state!
            let displayOptions = objectDeepCopy(options);

            displayOptions.action = key => (
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => closeSnackbar(key)}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            );

            enqueueSnackbar(
                message, {
                key,
                ...displayOptions,
                onClose: (event, reason, myKey) => {
                    dispatch(dequeueMessage(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of displayed snackbars
            addDisplayed(key);
        });

    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return <>{children}</>
}

NotificationService.propTypes = {
    children: PropTypes.any,
};

export default NotificationService;