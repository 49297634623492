/**
 * @file Status404.index.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 06, 2023 09:38
 */
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import {
    Box,
    Typography,
    Container,
    Button,
    Grid,
    styled
} from '@mui/material';

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-top: 50px;
`
);

function Status500() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Status 500 - Error</title>
            </Helmet>

            <MainContent>
                <Grid
                    container
                    sx={{
                        height: '100%'
                    }}
                    alignItems="stretch"
                    spacing={0}
                >
                    <Grid
                        xs={12}
                        md={6}
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        item
                    >
                        <Container maxWidth="sm">
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    sx={{
                                        my: 2
                                    }}
                                >
                                    {t('There was an error. We have been notified. Please try again later.')}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="text.secondary"
                                    fontWeight="normal"
                                    sx={{
                                        mb: 4
                                    }}
                                >
                                    {t(
                                        'An internal error has occurred. We are not able to complete your request.'
                                    )}
                                </Typography>

                                <Button
                                    href="/"
                                    variant="contained"
                                    sx={{
                                        ml: 1
                                    }}
                                >
                                    {t('Go back')}
                                </Button>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </MainContent>
        </>
    );
}

export default Status500;
