/**
 * @file global.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jun 04, 2020 16:09
 */
import * as Actions from 'src/store/actions/global.actions';

import { arrayDeepCopy } from 'src/@common/utilities';

const initialState = {
    dataLoading: false,
    helpDocViewerOpen: false,
    pageLoading: false,
    notifications: [],
};

const globalReducer = (state = initialState, action) => {

    let deleteKey = null;
    let notifications = null;

    switch (action.type) {
        // -------------------------------------------------------------------
        // common to all forms
        // -------------------------------------------------------------------
        case Actions.DATA_LOADING_SET: {
            return {
                ...state,
                dataLoading: action.payload,
            };
        }

        case Actions.HELP_DOC_VIEWER_OPEN_SET: {
            return {
                ...state,
                helpDocViewerOpen: action.payload,
            };
        }

        case Actions.PAGE_LOADING_SET: {
            return {
                ...state,
                pageLoading: action.payload,
            };
        }

        // -------------------------------------------------------------------
        // snackbar notifications
        // -------------------------------------------------------------------
        case Actions.ENQUEUE_MESSAGE:

            notifications = arrayDeepCopy(state.notifications);

            notifications.push({
                key: action.payload.key,
                message: action.payload.message,
                options: action.payload.options,
            });

            return {
                ...state,
                notifications,
            };

        case Actions.DEQUEUE_MESSAGE:
            deleteKey = action.payload;

            notifications = state.notifications.filter((item) => {
                return item.key !== deleteKey;
            });

            return {
                ...state,
                notifications,
            };

        default: {
            return state;
        }
    }
};

export default globalReducer;
