/**
 * @file geneModelDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * https://stackoverflow.com/questions/73160582/in-redux-rtk-getting-non-serializable-value-was-detected-in-an-action-for-a-d
 * 
 * @author don michael
 * @since Jul 22, 2023 09:51
 */

// -------------------------------------------------------------------
// testing and development setters
// -------------------------------------------------------------------

export const GENE_MODEL_SET = 'GENE_MODEL_SET';
export function geneModelSet(value) {
    return {
        type: GENE_MODEL_SET,
        payload: value,
    };
}

export const GENE_DISEASE_SET = 'GENE_DISEASE_SET';
export function geneDiseaseSet(value) {
    return {
        type: GENE_DISEASE_SET,
        payload: value,
    };
}

export const GENE_DISEASE_CLASSIFICATION_SET = 'GENE_DISEASE_CLASSIFICATION_SET';
export function geneDiseaseClassificationSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_SET,
        payload: value,
    };
}

export const GENE_DISEASE_CLASSIFICATION_CODES_SET = 'GENE_DISEASE_CLASSIFICATION_CODES_SET';
export function geneDiseaseClassificationCodesSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_CODES_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// form mode indicators
// -------------------------------------------------------------------
export const GENE_MODEL_FORM_MODE_SET = 'GENE_MODEL_FORM_MODE_SET';
export function geneModelFormModeSet(value) {
    return {
        type: GENE_MODEL_FORM_MODE_SET,
        payload: value,
    };
}

export const GENE_DISEASE_FORM_MODE_SET = 'GENE_DISEASE_FORM_MODE_SET';
export function geneDiseaseFormModeSet(value) {
    return {
        type: GENE_DISEASE_FORM_MODE_SET,
        payload: value,
    };
}

export const GENE_DISEASE_CLASSIFICATION_FORM_MODE_SET = 'GENE_DISEASE_CLASSIFICATION_FORM_MODE_SET';
export function geneDiseaseClassificationFormModeSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_FORM_MODE_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// geneModel.search
// -------------------------------------------------------------------
export const GENE_MODEL_FIND_REQUEST = 'GENE_MODEL_FIND_REQUEST';
export function geneModelFindRequest(geneModelId) {
    return {
        type: GENE_MODEL_FIND_REQUEST,
        payload: {
            geneModelId,
        },
    };
}

export const GENE_MODEL_FIND_SUCCESS = 'GENE_MODEL_FIND_SUCCESS';
export function geneModelFindSuccess(results) {
    return {
        type: GENE_MODEL_FIND_SUCCESS,
        payload: results,
    };
}

export const GENE_MODEL_FIND_FAILURE = 'GENE_MODEL_FIND_FAILURE';
export function geneModelFindFailure(error) {
    return {
        type: GENE_MODEL_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const GENE_MODEL_ID_SET = 'GENE_MODEL_ID_SET';
export function geneModelIdSet(value) {
    return {
        type: GENE_MODEL_ID_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// geneModel.reset
// -------------------------------------------------------------------
export const GENE_MODEL_GENE_SYMBOL_RESET = 'GENE_MODEL_GENE_SYMBOL_RESET';
export function geneSymbolReset() {
    return {
        type: GENE_MODEL_GENE_SYMBOL_RESET,
    };
}

export const GENE_MODEL_GENE_SYMBOL_UPDATE_FIELD = 'GENE_MODEL_GENE_SYMBOL_UPDATE_FIELD';
export function geneSymbolUpdateField(value) {
    return {
        type: GENE_MODEL_GENE_SYMBOL_UPDATE_FIELD,
        payload: value
    };
}

// geneModel.new
export const GENE_MODEL_NEW_REQUEST = 'GENE_MODEL_NEW_REQUEST';
export function geneModelNewRequest(geneSymbol, navigate) {
    return {
        type: GENE_MODEL_NEW_REQUEST,
        payload: {
            geneSymbol,
            navigate,
        },
    };
}

export const GENE_MODEL_NEW_SUCCESS = 'GENE_MODEL_NEW_SUCCESS';
export function geneModelNewSuccess(payload) {
    return {
        type: GENE_MODEL_NEW_SUCCESS,
        payload,
    };
}

export const GENE_MODEL_NEW_FAILURE = 'GENE_MODEL_NEW_FAILURE';
export function geneModelNewFailure(error) {
    return {
        type: GENE_MODEL_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneModels.delete
// -------------------------------------------------------------------
export const GENE_MODELS_DELETE_REQUEST = 'GENE_MODELS_DELETE_REQUEST';
export function geneModelsDeleteRequest(searchTerm, geneModelIds) {
    return {
        type: GENE_MODELS_DELETE_REQUEST,
        payload: {
            searchTerm,
            geneModelIds,
        },
    };
}

export const GENE_MODELS_DELETE_SUCCESS = 'GENE_MODELS_DELETE_SUCCESS';
export function geneModelsDeleteSuccess(payload) {
    return {
        type: GENE_MODELS_DELETE_SUCCESS,
        payload,
    };
}

export const GENE_MODELS_DELETE_FAILURE = 'GENE_MODELS_DELETE_FAILURE';
export function geneModelsDeleteFailure(error) {
    return {
        type: GENE_MODELS_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneModel.search
// -------------------------------------------------------------------
export const GENE_MODEL_SEARCH_REQUEST = 'GENE_MODEL_SEARCH_REQUEST';
export function geneModelSearchRequest(searchTerm) {
    return {
        type: GENE_MODEL_SEARCH_REQUEST,
        payload: {
            searchTerm,
        },
    };
}

export const GENE_MODEL_SEARCH_SUCCESS = 'GENE_MODEL_SEARCH_SUCCESS';
export function geneModelSearchSuccess(payload) {
    return {
        type: GENE_MODEL_SEARCH_SUCCESS,
        payload,
    };
}

export const GENE_MODEL_SEARCH_FAILURE = 'GENE_MODEL_SEARCH_FAILURE';
export function geneModelSearchFailure(error) {
    return {
        type: GENE_MODEL_SEARCH_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneModel.geneModelUpdate
// -------------------------------------------------------------------
export const GENE_MODEL_UPDATE_REQUEST = 'GENE_MODEL_UPDATE_REQUEST';
export function geneModelUpdateRequest(geneModel, notify) {
    return {
        type: GENE_MODEL_UPDATE_REQUEST,
        payload: {
            geneModel,
            notify,
        },
    };
}

export const GENE_MODEL_UPDATE_SUCCESS = 'GENE_MODEL_UPDATE_SUCCESS';
export function geneModelUpdateSuccess(payload) {
    return {
        type: GENE_MODEL_UPDATE_SUCCESS,
        payload,
    };
}

export const GENE_MODEL_UPDATE_FAILURE = 'GENE_MODEL_UPDATE_FAILURE';
export function geneModelUpdateFailure(error) {
    return {
        type: GENE_MODEL_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneModel.updateField
// -------------------------------------------------------------------
export const GENE_MODEL_UPDATE_FIELD = 'GENE_MODEL_UPDATE_FIELD';
export function geneModelUpdateField(fieldName, fieldValue) {
    return {
        type: GENE_MODEL_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// geneModelView
// -------------------------------------------------------------------
export const GENE_MODEL_VIEW_REQUEST = 'GENE_MODEL_VIEW_REQUEST';
export function geneModelViewRequest(geneModelId) {
    return {
        type: GENE_MODEL_VIEW_REQUEST,
        payload: {
            geneModelId,
        },
    };
}

export const GENE_MODEL_VIEW_SUCCESS = 'GENE_MODEL_VIEW_SUCCESS';
export function geneModelViewSuccess(geneModelView) {
    return {
        type: GENE_MODEL_VIEW_SUCCESS,
        payload: geneModelView,
    };
}

export const GENE_MODEL_VIEW_FAILURE = 'GENE_MODEL_VIEW_FAILURE';
export function geneModelViewFailure(error) {
    return {
        type: GENE_MODEL_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationAnnotations.find
// -------------------------------------------------------------------
// export const GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST = 'GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST';
// export function classificationAnnotationsFindRequest(geneSymbol, diseaseId, classificationCode) {
//     return {
//         type: GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_REQUEST,
//         payload: {
//             geneSymbol,
//             diseaseId,
//             classificationCode,
//         }
//     };
// }

// export const GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS';
// export function classificationAnnotationsFindSuccess(payload) {
//     return {
//         type: GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_SUCCESS,
//         payload,
//     };
// }

// export const GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE = 'GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE';
// export function classificationAnnotationsFindFailure(error) {
//     return {
//         type: GENE_DISEASE_CLASSIFICATION_ANNOTATIONS_FIND_FAILURE,
//         payload: JSON.stringify(error),
//     };
// }

// -------------------------------------------------------------------
// geneDisease.reset - resets geneDisease
// -------------------------------------------------------------------
export const GENE_DISEASE_RESET = 'GENE_DISEASE_RESET';
export function geneDiseaseReset() {
    return {
        type: GENE_DISEASE_RESET,
    };
}

// -------------------------------------------------------------------
// geneDisease.new - create a new geneDisesase
// -------------------------------------------------------------------
export const GENE_DISEASE_NEW_REQUEST = 'GENE_DISEASE_NEW_REQUEST';
export function geneDiseaseNewRequest(geneModelId, disease, navigate) {
    return {
        type: GENE_DISEASE_NEW_REQUEST,
        payload: {
            geneModelId,
            disease,
            navigate,
        },
    };
}

export const GENE_DISEASE_NEW_SUCCESS = 'GENE_DISEASE_NEW_SUCCESS';
export function geneDiseaseNewSuccess(payload) {
    return {
        type: GENE_DISEASE_NEW_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_NEW_FAILURE = 'GENE_DISEASE_NEW_FAILURE';
export function geneDiseaseNewFailure(error) {
    return {
        type: GENE_DISEASE_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.new.updateField
// -------------------------------------------------------------------
export const GENE_DISEASE_NEW_UPDATE_FIELD = 'GENE_DISEASE_NEW_UPDATE_FIELD';
export function geneDiseaseNewUpdateField(fieldValue) {
    return {
        type: GENE_DISEASE_NEW_UPDATE_FIELD,
        payload: {
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// geneDisease.updateField
// -------------------------------------------------------------------
export const GENE_DISEASE_UPDATE_FIELD = 'GENE_DISEASE_UPDATE_FIELD';
export function geneDiseaseUpdateField(fieldName, fieldValue) {
    return {
        type: GENE_DISEASE_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// geneDisease.find - finds single geneDisease
// -------------------------------------------------------------------
export const GENE_DISEASE_FIND_REQUEST = 'GENE_DISEASE_FIND_REQUEST';
export function geneDiseaseFindRequest(geneDiseaseId) {
    return {
        type: GENE_DISEASE_FIND_REQUEST,
        payload: {
            geneDiseaseId,
        },
    };
}

export const GENE_DISEASE_FIND_SUCCESS = 'GENE_DISEASE_FIND_SUCCESS';
export function geneDiseaseFindSuccess(payload) {
    return {
        type: GENE_DISEASE_FIND_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_FIND_FAILURE = 'GENE_DISEASE_FIND_FAILURE';
export function geneDiseaseFindFailure(error) {
    return {
        type: GENE_DISEASE_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.update
// -------------------------------------------------------------------
export const GENE_DISEASE_UPDATE_REQUEST = 'GENE_DISEASE_UPDATE_REQUEST';
export function geneDiseaseUpdateRequest(geneDisease, notify) {
    return {
        type: GENE_DISEASE_UPDATE_REQUEST,
        payload: {
            geneDisease,
            notify,
        },
    };
}

export const GENE_DISEASE_UPDATE_SUCCESS = 'GENE_DISEASE_UPDATE_SUCCESS';
export function geneDiseaseUpdateSuccess(payload) {
    return {
        type: GENE_DISEASE_UPDATE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_UPDATE_FAILURE = 'GENE_DISEASE_UPDATE_FAILURE';
export function geneDiseaseUpdateFailure(error) {
    return {
        type: GENE_DISEASE_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseases.find - gets all geneDiseases for a single geneModel
// -------------------------------------------------------------------
export const GENE_DISEASES_FIND_REQUEST = 'GENE_DISEASES_FIND_REQUEST';
export function geneDiseasesFindRequest(geneModelId) {
    return {
        type: GENE_DISEASES_FIND_REQUEST,
        payload: {
            geneModelId,
        },
    };
}

export const GENE_DISEASES_FIND_SUCCESS = 'GENE_DISEASES_FIND_SUCCESS';
export function geneDiseasesFindSuccess(payload) {
    return {
        type: GENE_DISEASES_FIND_SUCCESS,
        payload,
    };
}

export const GENE_DISEASES_FIND_FAILURE = 'GENE_DISEASES_FIND_FAILURE';
export function geneDiseasesFindFailure(error) {
    return {
        type: GENE_DISEASES_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseases.delete - deletes selected diseases from a geneModel
// -------------------------------------------------------------------
export const GENE_DISEASES_DELETE_REQUEST = 'GENE_DISEASES_DELETE_REQUEST';
export function geneDiseasesDeleteRequest(geneModelId, geneDiseaseIds) {
    return {
        type: GENE_DISEASES_DELETE_REQUEST,
        payload: {
            geneModelId,
            geneDiseaseIds,
        },
    };
}

export const GENE_DISEASES_DELETE_SUCCESS = 'GENE_DISEASES_DELETE_SUCCESS';
export function geneDiseasesDeleteSuccess(payload) {
    return {
        type: GENE_DISEASES_DELETE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASES_DELETE_FAILURE = 'GENE_DISEASES_DELETE_FAILURE';
export function geneDiseasesDeleteFailure(error) {
    return {
        type: GENE_DISEASES_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassifications.delete - deletes classifications from a geneDisease
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATIONS_DELETE_REQUEST = 'GENE_DISEASE_CLASSIFICATIONS_DELETE_REQUEST';
export function geneDiseaseClassificationsDeleteRequest(geneModelId, geneDiseaseId, classificationIds) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_DELETE_REQUEST,
        payload: {
            geneModelId,
            geneDiseaseId,
            classificationIds
        },
    };
}

export const GENE_DISEASE_CLASSIFICATIONS_DELETE_SUCCESS = 'GENE_DISEASE_CLASSIFICATIONS_DELETE_SUCCESS';
export function geneDiseaseClassificationsDeleteSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_DELETE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATIONS_DELETE_FAILURE = 'GENE_DISEASE_CLASSIFICATIONS_DELETE_FAILURE';
export function geneDiseaseClassificationsDeleteFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassifications.find
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATIONS_FIND_REQUEST = 'GENE_DISEASE_CLASSIFICATIONS_FIND_REQUEST';
export function geneDiseaseClassificationsFindRequest(geneDiseaseId) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_FIND_REQUEST,
        payload: {
            geneDiseaseId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATIONS_FIND_SUCCESS = 'GENE_DISEASE_CLASSIFICATIONS_FIND_SUCCESS';
export function geneDiseaseClassificationsFindSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_FIND_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATIONS_FIND_FAILURE = 'GENE_DISEASE_CLASSIFICATIONS_FIND_FAILURE';
export function geneDiseaseClassificationsFindFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

export const GENE_DISEASE_CLASSIFICATIONS_REFRESH_SET = 'GENE_DISEASE_CLASSIFICATIONS_REFRESH_SET';
export function geneDiseaseClassificationsRefreshSet(value) {
    return {
        type: GENE_DISEASE_CLASSIFICATIONS_REFRESH_SET,
        payload: value,
    };
}
// -------------------------------------------------------------------
// geneDiseaseClassification.reset
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_RESET = 'GENE_DISEASE_CLASSIFICATION_RESET';
export function geneDiseaseClassificationReset() {
    return {
        type: GENE_DISEASE_CLASSIFICATION_RESET,
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassification.delete
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_DELETE_REQUEST = 'GENE_DISEASE_CLASSIFICATION_DELETE_REQUEST';
export function geneDiseaseClassificationDeleteRequest(geneDiseaseClassificationId) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_DELETE_REQUEST,
        payload: {
            geneDiseaseClassificationId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_DELETE_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_DELETE_SUCCESS';
export function geneDiseaseClassificationDeleteSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_DELETE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_DELETE_FAILURE = 'GENE_DISEASE_CLASSIFICATION_DELETE_FAILURE';
export function geneDiseaseClassificationDeleteFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassification.find
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_FIND_REQUEST = 'GENE_DISEASE_CLASSIFICATION_FIND_REQUEST';
export function geneDiseaseClassificationFindRequest(geneDiseaseClassificationId) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_FIND_REQUEST,
        payload: {
            geneDiseaseClassificationId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_FIND_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_FIND_SUCCESS';
export function geneDiseaseClassificationFindSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_FIND_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_FIND_FAILURE = 'GENE_DISEASE_CLASSIFICATION_FIND_FAILURE';
export function geneDiseaseClassificationFindFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_FIND_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classification.new
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_NEW_REQUEST = 'GENE_DISEASE_CLASSIFICATION_NEW_REQUEST';
export function geneDiseaseClassificationNewRequest(geneModelId, geneDiseaseId) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_NEW_REQUEST,
        payload: {
            geneModelId,
            geneDiseaseId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_NEW_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_NEW_SUCCESS';
export function geneDiseaseClassificationNewSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_NEW_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_NEW_FAILURE = 'GENE_DISEASE_CLASSIFICATION_NEW_FAILURE';
export function geneDiseaseClassificationNewFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_NEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassification.insert
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_INSERT_REQUEST = 'GENE_DISEASE_CLASSIFICATION_INSERT_REQUEST';
export function geneDiseaseClassificationInsertRequest(geneDiseaseClassification, notify) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_INSERT_REQUEST,
        payload: {
            geneDiseaseClassification,
            notify,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_INSERT_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_INSERT_SUCCESS';
export function geneDiseaseClassificationInsertSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_INSERT_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_INSERT_FAILURE = 'GENE_DISEASE_CLASSIFICATION_INSERT_FAILURE';
export function geneDiseaseClassificationInsertFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDiseaseClassification.update
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_UPDATE_REQUEST = 'GENE_DISEASE_CLASSIFICATION_UPDATE_REQUEST';
export function geneDiseaseClassificationUpdateRequest(geneDiseaseClassification, notify) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_UPDATE_REQUEST,
        payload: {
            geneDiseaseClassification,
            notify,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_UPDATE_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_UPDATE_SUCCESS';
export function geneDiseaseClassificationUpdateSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_UPDATE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_UPDATE_FAILURE = 'GENE_DISEASE_CLASSIFICATION_UPDATE_FAILURE';
export function geneDiseaseClassificationUpdateFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_UPDATE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classification.annotationDistribCharts
// -------------------------------------------------------------------
export const ANNOTATION_DISTRIB_CHARTS_REQUEST = 'ANNOTATION_DISTRIB_CHARTS_REQUEST';
export function annotationDistribChartsRequest(geneDiseaseId, disease) {
    return {
        type: ANNOTATION_DISTRIB_CHARTS_REQUEST,
        payload: {
            geneDiseaseId,
            disease,
        }
    };
}

export const ANNOTATION_DISTRIB_CHARTS_SUCCESS = 'ANNOTATION_DISTRIB_CHARTS_SUCCESS';
export function annotationDistribChartsSuccess(payload) {
    return {
        type: ANNOTATION_DISTRIB_CHARTS_SUCCESS,
        payload,
    };
}

export const ANNOTATION_DISTRIB_CHARTS_FAILURE = 'ANNOTATION_DISTRIB_CHARTS_FAILURE';
export function annotationDistribChartsFailure(error) {
    return {
        type: ANNOTATION_DISTRIB_CHARTS_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classification.updateField
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_UPDATE_FIELD = 'GENE_DISEASE_CLASSIFICATION_UPDATE_FIELD';
export function geneDiseaseClassificationUpdateField(fieldName, fieldValue) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_UPDATE_FIELD,
        payload: {
            fieldName,
            fieldValue,
        },
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.initialQuery
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST';
export function geneDiseaseClassificationEvidenceInitialQueryRequest(
    geneDiseaseClassificationId,
    pageSize,
) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_REQUEST,
        payload: {
            geneDiseaseClassificationId,
            pageSize,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS';
export function geneDiseaseClassificationEvidenceInitialQuerySuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE';
export function geneDiseaseClassificationEvidenceInitialQueryFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.pageQuery
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST';
export function geneDiseaseClassificationEvidencePageQueryRequest(
    pageMap,
    pageNo,
    queryObj,
) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_REQUEST,
        payload: {
            pageMap,
            pageNo,
            queryObj,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS';
export function geneDiseaseClassificationEvidencePageQuerySuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE';
export function geneDiseaseClassificationEvidencePageQueryFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.approval.set
// sets the approval field of an evidenceObj
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET';
export function geneDiseaseClassificationEvidenceApprovalSet(_id, value) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_APPROVAL_SET,
        payload: {
            _id,
            value,
        }
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.delete
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST';
export function geneDiseaseClassificationEvidenceDeleteRequest(
    objId,
    pageMap,
    evidenceId,
) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_REQUEST,
        payload: {
            objId,
            pageMap,
            evidenceId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS';
export function geneDiseaseClassificationEvidenceDeleteSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE';
export function geneDiseaseClassificationEvidenceDeleteFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.deleteAll
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_REQUEST = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_REQUEST';
export function geneDiseaseClassificationEvidenceDeleteAllRequest(
    geneDiseaseClassificationId
) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_REQUEST,
        payload: {
            geneDiseaseClassificationId,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_SUCCESS';
export function geneDiseaseClassificationEvidenceDeleteAllSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_FAILURE = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_FAILURE';
export function geneDiseaseClassificationEvidenceDeleteAllFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_DELETE_ALL_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.insert
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST';
export function geneDiseaseClassificationEvidenceInsertRequest(
    objId,
    pageMap,
    queryObj,
) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_REQUEST,
        payload: {
            objId,
            pageMap,
            queryObj,
        }
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS';
export function geneDiseaseClassificationEvidenceInsertSuccess(payload) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_SUCCESS,
        payload,
    };
}

export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE';
export function geneDiseaseClassificationEvidenceInsertFailure(error) {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_INSERT_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// geneDisease.classificationEvidence.reset
// sets the approval field of an evidenceObj
// -------------------------------------------------------------------
export const GENE_DISEASE_CLASSIFICATION_EVIDENCE_RESET = 'GENE_DISEASE_CLASSIFICATION_EVIDENCE_RESET';
export function geneDiseaseClassificationEvidenceReset() {
    return {
        type: GENE_DISEASE_CLASSIFICATION_EVIDENCE_RESET,
        payload: {
        }
    };
}
