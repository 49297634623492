/**
 * @file documentPages.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 09, 2022 14:51
 */

// -------------------------------------------------------------------
// document.search
// -------------------------------------------------------------------
export const DOCUMENT_SEARCH_DOC_TYPES_SET = 'DOCUMENT_SEARCH_DOC_TYPES_SET';
export function searchDocTypesSet(searchDocTypes) {
    return {
        type: DOCUMENT_SEARCH_DOC_TYPES_SET,
        payload: searchDocTypes,
    };
}

export const DOCUMENT_SEARCH_METHOD_SET = 'DOCUMENT_SEARCH_METHOD_SET';
export function searchMethodSet(searchMethod) {
    return {
        type: DOCUMENT_SEARCH_METHOD_SET,
        payload: searchMethod,
    };
}

export const DOCUMENT_SEARCH_TERM_SET = 'DOCUMENT_SEARCH_TERM_SET';
export function searchTermSet(searchTerm) {
    return {
        type: DOCUMENT_SEARCH_TERM_SET,
        payload: searchTerm,
    };
}

export const DOCUMENT_SEARCH_FILTER_TEXT_SET = 'DOCUMENT_SEARCH_FILTER_TEXT_SET';
export function searchFilterTextSet(filter) {
    return {
        type: DOCUMENT_SEARCH_FILTER_TEXT_SET,
        payload: filter,
    };
}

export const DOCUMENT_SEARCH_ORDER_ASCENDING_SET = 'DOCUMENT_SEARCH_ORDER_ASCENDING_SET';
export function orderAscendingSet(isAscending) {
    return {
        type: DOCUMENT_SEARCH_ORDER_ASCENDING_SET,
        payload: isAscending,
    };
}

export const DOCUMENT_SEARCH_ORDERBY_SET = 'DOCUMENT_SEARCH_ORDERBY_SET';
export function orderBySet(orderBy) {
    return {
        type: DOCUMENT_SEARCH_ORDERBY_SET,
        payload: orderBy,
    };
}