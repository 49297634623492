
// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const ANNOTATION_EDITOR_ANNOTATION_ID_SET = 'ANNOTATION_EDITOR_ANNOTATION_ID_SET';
export function annotationEditorAnnotationIdSet(annotationId) {
    return {
        type: ANNOTATION_EDITOR_ANNOTATION_ID_SET,
        payload: annotationId,
    };
}

export const ANNOTATION_EDITOR_DIALOG_OPEN_SET = 'ANNOTATION_EDITOR_DIALOG_OPEN_SET';
export function openAnnotationEditorDialogSet(value) {
    return {
        type: ANNOTATION_EDITOR_DIALOG_OPEN_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// annotationView.editAnnotation.request
// -------------------------------------------------------------------
export const ANNOTATION_EDITOR_EDIT_ANNOTATION_REQUEST = 'ANNOTATION_EDITOR_EDIT_ANNOTATION_REQUEST';
export function editAnnotationRequest(annotationId) {
    return {
        type: ANNOTATION_EDITOR_EDIT_ANNOTATION_REQUEST,
        payload: annotationId,
    };
}

export const ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS = 'ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS';
export function editAnnotationSuccess(payload) {
    return {
        type: ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS,
        payload,
    };
}

export const ANNOTATION_EDITOR_EDIT_ANNOTATION_FAILURE = 'ANNOTATION_EDITOR_EDIT_ANNOTATION_FAILURE';
export function editAnnotationFailure(error) {
    return {
        type: ANNOTATION_EDITOR_EDIT_ANNOTATION_FAILURE,
        payload: JSON.stringify(error),
    };
}

