/**
 * @file documentDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 14, 2022 12:56
 */
import { arrayDeepCopy } from 'src/@common/utilities';

import * as Actions from 'src/store/actions/document/documentDb.actions';

const initialState = {
    documents: [],

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const documentDbReducer = (state = initialState, action) => {

    let docId = null;
    let foundIndex = null;
    let tmpDocuments = null;

    switch (action.type) {

        // document.search
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_SEARCH_REQUEST:
            return {
                ...state,
                documents: [],
            };

        case Actions.DOCUMENT_SEARCH_SUCCESS:
            return {
                ...state,
                documents: action.payload.queryResults,
            };

        case Actions.DOCUMENT_SEARCH_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // document.updateSearchItem
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_UPDATE_SEARCH_ITEM_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_UPDATE_SEARCH_ITEM_SUCCESS:
            docId = action.payload.docId;

            tmpDocuments = arrayDeepCopy(state.documents);

            foundIndex = tmpDocuments.findIndex((item) => {
                return item._id === docId;
            });

            if (foundIndex !== -1) {
                tmpDocuments[foundIndex] = action.payload.document;
            }

            return {
                ...state,
                documents: tmpDocuments,
            };

        case Actions.DOCUMENT_UPDATE_SEARCH_ITEM_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // document.table.delete
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_TABLE_DELETE_REQUEST:
            return {
                ...state,
            };

        case Actions.DOCUMENT_TABLE_DELETE_SUCCESS:
            docId = action.payload.tableId;

            tmpDocuments = state.documents.filter((item) => {
                return item._id !== docId;
            });

            return {
                ...state,
                documents: tmpDocuments,
            };

        case Actions.DOCUMENT_TABLE_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // document.search.reset
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_SEARCH_RESET:
            return {
                ...state,
                documents: [],
            };

        /************* */
        default: {
            return state;
        }
    }
}

export default documentDbReducer;