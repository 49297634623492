/**
 * @file documentRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 12, 2022 19:08
 */
import { all, fork } from 'redux-saga/effects';

import deleteTable from './deleteTable.saga';
import searchDocument from './searchDocument.saga';
import updateSearchItem from './updateSearchItem.saga';

export default function* rootSaga() {
    yield all([

        fork(deleteTable),

        // search
        fork(searchDocument),
        fork(updateSearchItem),

    ]);
}
