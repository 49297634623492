/**
 * @file tableAnnotatorDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 03, 2022 15:06
 */
import * as Actions from 'src/store/actions/tableAnnotator/tableAnnotatorDb.actions';
import { arrayDeepCopy, arrayExists, objectDeepCopy } from 'src/@common/utilities';

import { clearKey, saveKey } from 'src/@common/sessionStorage';

const initialState = {
    // annotation session parameters
    annotatorId: null,
    tableId: null,

    // redirections
    redirectToTableAnnotator: false,

    // annotations for the current annotatorId + tableId
    annotations: [],

    // note: biocurations for the table + publication or supplement
    // are stored in the documentAnnatatorDb.reducer to 
    // support editing annotations within the documentAnnotator
    // biocurations: {},

    dataLoaded: false,

    // batch id of the latest generation operation
    genBatchId: null,

    // array subset of schema.supportedFields
    // containing fields designated for this classificationCode
    schemaSupportedFields: [],

    schemaClassifications: [],

    // fields for a single classificationCode
    // formated for use as options in an autoCompleteField
    schemaFieldOptions: [],

    // table being annotated; contains table rows
    table: {},

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const tableAnnotatorDbReducer = (state = initialState, action) => {

    let colIndex = null;
    let curatedTableCheckedColIndex = null;
    let curatedTableGroupNoColIndex = null;
    let fieldsRow = null;
    let foundIndex = null;
    let foundRow = null;
    let globalFields = null;
    let groupsRow = null;
    let rowId = null;
    let rowIndex = null;
    let tableRows = null;
    let tmpOptions = null;
    let tmpTable = null;
    let value = null;

    switch (action.type) {

        case Actions.TABLE_ANNOTATOR_GENBATCHID_SET: {
            return {
                ...state,
                genBatchId: action.payload,
            };
        }

        case Actions.REDIRECT_TO_TABLE_ANNOTATOR_SET: {
            return {
                ...state,
                redirectToTableAnnotator: action.payload,
            };
        }

        // tableAnnotator.setup
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_ANNOTATOR_ID_SET:
            return {
                ...state,
                annotatorId: action.payload,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ID_SET:
            return {
                ...state,
                tableId: action.payload,
            };

        // tableAnnotator.resets
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_DB_RESET:
            // resets everything
            clearKey('genBatchId');

            return {
                ...state,
                redirectToTableAnnotator: false,
                annotations: [],
                dataLoaded: false,
                schemaSupportedFields: [],
                schemaClassifications: [],
                schemaFieldOptions: [],
                genBatchId: null,
                table: {},
                error: null,
                serverErrorExists: false,
                serverFieldErrors: {},
                serverMessage: '',
            };

        case Actions.TABLE_ANNOTATOR_DB_CLEAR:
            // resets just past generation session.
            // leaves table and annotator intact
            clearKey('genBatchId');

            tmpTable = objectDeepCopy(state.table);

            for (let index = 0; index < tmpTable.rowsObj.fieldsRow.length; index++) {
                tmpTable.rowsObj.fieldsRow[index] = [];
            }

            for (let index = 0; index < tmpTable.rowsObj.groupsRow.length; index++) {
                tmpTable.rowsObj.groupsRow[index] = '';
            }

            for (let index = 0; index < tmpTable.rowsObj.dataRows.length; index++) {
                tmpTable.rowsObj.dataRows[index][tmpTable.rowsObj.selectedColIndex] = false;
            }

            return {
                ...state,
                annotations: [],
                schemaSupportedFields: [],
                schemaClassifications: [],
                schemaFieldOptions: [],
                table: tmpTable,
                genBatchId: null,
                error: null,
                serverErrorExists: false,
                serverFieldErrors: {},
                serverMessage: '',
            };

        // tableAnnotator.annotateTable
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST:
            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                tableId: action.payload.tableId,
                dataLoaded: false,
                annotations: [],
                table: {},
                redirectToTableAnnotator: true,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS:
            // note: biocurations are captured by the
            // documentAnnotatorDb.reducer for use in 
            // editing annotations
            return {
                ...state,
                dataLoaded: true,
                table: action.payload.table,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.curatedTable
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_CHECKED_ROW_SET:
            curatedTableCheckedColIndex = state.table.rowsObj.selectedColIndex;
            value = action.payload.value;
            rowIndex = action.payload.rowIndex;

            tmpTable = objectDeepCopy(state.table);
            tableRows = tmpTable.rowsObj.dataRows;
            tableRows[rowIndex][curatedTableCheckedColIndex] = value;

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_CHECK_ALL_SET:
            curatedTableCheckedColIndex = state.table.rowsObj.selectedColIndex;
            value = action.payload;

            tmpTable = objectDeepCopy(state.table);
            tableRows = tmpTable.rowsObj.dataRows;

            // skip the first row, which is usually the column labels
            for (let itor = 1; itor < tableRows.length; itor++) {
                tableRows[itor][curatedTableCheckedColIndex] = value;
            }

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_ROW_SET:
            curatedTableGroupNoColIndex = state.table.rowsObj.groupNoColIndex;
            value = action.payload.value;
            rowIndex = action.payload.rowIndex;

            tmpTable = objectDeepCopy(state.table);
            tableRows = tmpTable.rowsObj.dataRows;
            tableRows[rowIndex][curatedTableGroupNoColIndex] = value;

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_FIELD_SET:
            // sets value of a single fields header column
            colIndex = action.payload.colIndex;
            value = action.payload.value;

            tmpTable = objectDeepCopy(state.table);
            fieldsRow = tmpTable.rowsObj.fieldsRow;

            fieldsRow[colIndex] = value;

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_FIELDS_ROW_SET:
            // sets values for all columns in the fieldsRow
            tmpTable = objectDeepCopy(state.table);
            tmpTable.rowsObj.fieldsRow = action.payload;

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_GROUPNO_COLUMN_SET:
            // sets value of single groupNo header column
            colIndex = action.payload.colIndex;
            value = action.payload.value;

            tmpTable = objectDeepCopy(state.table);
            groupsRow = tmpTable.rowsObj.groupsRow;

            groupsRow[colIndex] = value;

            return {
                ...state,
                table: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_CURATED_TABLE_DISABLED_OPTIONS_SET:
            // finds all fieldNames that have been used in fieldsRow
            // and globalFields and marks those schemaFieldOptions as 'disabled',
            // except for the internalNote field, which can be reused
            tmpOptions = arrayDeepCopy(state.schemaFieldOptions);

            fieldsRow = action.payload.fieldsRow;
            globalFields = action.payload.globalFields;

            // reset
            tmpOptions.forEach((option) => {
                option.disabled = false;
            });

            if (arrayExists(tmpOptions)) {

                if (fieldsRow) {
                    fieldsRow.forEach((col) => {

                        if (arrayExists(col)) {

                            col.forEach((colField) => {

                                if (colField !== 'internalField') {
                                    foundIndex = tmpOptions.findIndex((item) => {
                                        return item.value === colField;
                                    });

                                    if (foundIndex !== -1) {
                                        tmpOptions[foundIndex].disabled = true;
                                    }
                                }

                            });
                        }

                    });
                }

                if (globalFields) {
                    for (let key in globalFields) {
                        foundIndex = tmpOptions.findIndex((item) => {
                            return item.value === key;
                        });

                        if (foundIndex !== -1) {
                            tmpOptions[foundIndex].disabled = true;
                        }
                    }
                }
            }

            return {
                ...state,
                schemaFieldOptions: tmpOptions,
            };

        // tableAnnotator.generateAnnotations
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_REQUEST:
            clearKey('genBatchId');

            return {
                ...state,
                genBatchId: null,
                annotations: [],
            };

        case Actions.TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_SUCCESS:
            // insert into sessionStorage to survive a page refresh
            saveKey('genBatchId', action.payload.genBatchId);

            return {
                ...state,
                annotations: action.payload.annotations,
                genBatchId: action.payload.genBatchId,
            };

        case Actions.TABLE_ANNOTATOR_GENERATE_ANNOTATIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.submitAnnotationType
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_REQUEST:
            return {
                ...state,
                schemaClassifications: [],
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_SUCCESS:
            return {
                ...state,
                schemaClassifications: action.payload.schemaClassifications,
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_ANNOTATION_TYPE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.submitClassificationCode
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_REQUEST:
            return {
                ...state,
                schemaFieldOptions: [],
                schemaSupportedFields: [],
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_SUCCESS:
            return {
                ...state,
                schemaFieldOptions: action.payload.schemaFieldOptions,
                schemaSupportedFields: action.payload.schemaSupportedFields,
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_CLASSIFICATION_CODE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.submitTableSettings
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_REQUEST:
            return {
                ...state,
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_SUCCESS:
            return {
                ...state,
            };

        case Actions.TABLE_ANNOTATOR_SUBMIT_TABLE_SETTINGS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.tableAnnotations.find
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_REQUEST:
            return {
                ...state,
                dataLoaded: false,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_FIND_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.tableAnnotations.delete
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                annotations: [],
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // tableAnnotator.tableAnnotations.checked
        // -------------------------------------------------------------------
        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ROW_SET:
            rowId = action.payload.rowId;
            value = action.payload.value;

            tmpTable = objectDeepCopy(state.annotations);

            foundRow = tmpTable.find((row) => {
                return row._id === rowId;
            });

            if (foundRow) {
                foundRow.selected = value;
            }

            return {
                ...state,
                annotations: tmpTable,
            };

        case Actions.TABLE_ANNOTATOR_TABLE_ANNOTATIONS_CHECKED_ALL_SET:
            value = action.payload;

            tmpTable = objectDeepCopy(state.annotations);

            tmpTable.forEach((row) => {
                row.selected = value;
            })

            return {
                ...state,
                annotations: tmpTable,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default tableAnnotatorDbReducer;