/**
 * @file AnnotationDistribCharts.ChartDialog.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 18, 2023 19:22
 */
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ChartContainer from './ChartContainer';

import {
    annotationDistribChartsDialogSet,
} from 'src/store/actions/geneModel/geneModelPages.actions';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        minHeight: '55vh',
        maxHeight: '55vh',
    },
}));

function ChartDialog() {

    const dispatch = useDispatch();
    const classes = useStyles();

    // start: redux state --------------------------
    const geneModelPagesState = useSelector((state) => {
        return state.geneModelPages;
    });

    const { annotationDistribChartsOpenDialog } = geneModelPagesState;
    // end: redux state -----------------------------

    const handleCloseDialog = () => {
        dispatch(annotationDistribChartsDialogSet(false));
    }

    return (
        <Dialog hideBackdrop open={annotationDistribChartsOpenDialog}
            classes={{ paper: classes.dialogPaper }}
            onClose={handleCloseDialog}
            fullWidth maxWidth="md"
        >
            <DialogActions sx={{ marginBottom: '0px' }}>
                <Button variant="outlined" size="small" onClick={handleCloseDialog}>Close</Button>
            </DialogActions>

            <DialogTitle sx={{ paddingTop: '0px', paddingBottom: '0px', marginBottom: '5px' }}>
                <Typography variant="h4" gutterBottom>
                    {'Annotation Distribution'}
                </Typography>
            </DialogTitle>

            <Divider variant="middle" />

            <DialogContent sx={{ paddingTop: '0px' }}>
                <ChartContainer />
            </DialogContent>

            <Divider variant="middle" />

            <DialogActions sx={{ marginBottom: '0px' }}>
            </DialogActions>

        </Dialog>
    )
}

export default ChartDialog;