/**
 * @file annotationEditorRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 09, 2023 06:47
 */
import { all, fork } from 'redux-saga/effects';

// annotation
import editAnnotation from './editAnnotation.saga';

export default function* rootSaga() {
    yield all([

        fork(editAnnotation),

    ]);
}
