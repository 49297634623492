/**
 * @file annotationsSave.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 16, 2023 19:10
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import { getToken } from 'src/auth/authUtilities';
import { stringExists } from 'src/@common/utilities';

import {
    DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST,
    annotationsSaveSuccess,
    annotationsSaveFailure,
} from 'src/store/actions/documentAnnotator/documentAnnotatorDb.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/documentAnnotator/annotationsSave';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            annotations: payload.annotations,
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* createRequestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            if (stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.generalInfoMessage.options)
                );
            }

            yield put(annotationsSaveSuccess(resp.payload));

            if (action.payload.notify === true) {
                yield put(enqueueMessage(
                    standardMessages.successfulSave.message,
                    standardMessages.successfulSave.options)
                );
            }

        } catch (error) {
            yield put(annotationsSaveFailure(error));
            handleSagaErrors(error);
        } finally {
            yield put(dataLoadingSet(false));
        }
    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* createRequestMonitor() {
    yield takeLatest(DOCUMENT_ANNOTATOR_ANNOTATIONS_SAVE_REQUEST, createRequestSaga);
}
