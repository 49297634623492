/**
 * @file otpSetup.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 11, 2023 11:44
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import { getToken } from 'src/auth/authUtilities';
import standardMessages from 'src/services/notifications/standardMessages';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import {
    AUTH_OTP_SETUP_REQUEST,
    otpSetupSuccess,
    otpSetupFailure,
} from 'src/store/actions/auth.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(identityToken) {
    const url = '/auth/otpSetup';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            identityToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga() {
    const identityToken = getToken('identity');

    if (identityToken) {

        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, identityToken);
            yield put(dataLoadingSet(false));
            yield put(otpSetupSuccess(resp.payload));

        } catch (error) {

            yield put(dataLoadingSet(false));
            yield put(otpSetupFailure(error));

            handleSagaErrors(error);

            // const targetPath = `/auth/signin`;
            // return action.payload.navigate(targetPath);

        }

    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }

}

export default function* requestMonitor() {
    yield takeLatest(AUTH_OTP_SETUP_REQUEST, requestSaga);
}
