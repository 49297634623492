/**
 * @file documentAnnotatorPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 22, 2022 11:11
 */
import * as Actions from 'src/store/actions/documentAnnotator/documentAnnotatorPages.actions';

import * as dbActions from 'src/store/actions/documentAnnotator/documentAnnotatorDb.actions';

// actions shared with annotationEditor
import * as AnnotationEditorActions from 'src/store/actions/annotationEditor.actions';

// actions shared with tableAnnotator
import * as TableAnnotatorActions from 'src/store/actions/tableAnnotator/tableAnnotatorDb.actions';

import { objectExists, objectKeyExists } from 'src/@common/utilities';

const initialState = {

    // annotatorType
    // indicates if the current annotation originates
    // from the documentAnnotator (ie, publications, supplements)
    // or the tableAnnotator (tables).
    // values are:  documentAnnotator, tableAnnotator
    annotatorType: 'documentAnnotator',

    // annotation dialogs
    annotationNewOpenDialog: false,

    // annotatePublication and annotateSupplement and annotateTable
    annotatorId: '',

    docType: '',  // pub or sup or table
    docId: '', // _id of the publication or supplement or table

    pmId: '',
    pubId: '',
    url: '',

    // pointer to the psPdfKit instance
    psPdfKitInstance: null,

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const documentAnnotatorPagesReducer = (state = initialState, action) => {

    let annotatorId = '';
    let annotatorType = '';
    let docId = '';
    let docType = '';
    let pmId = '';
    let pubId = '';

    switch (action.type) {

        // setters: shared by documentAnnotator, annotationEditor

        case Actions.DOCUMENT_ANNOTATOR_DOCID_SET:
            return {
                ...state,
                docId: action.payload,
            };

        case Actions.DOCUMENT_ANNOTATOR_DOCTYPE_SET:
            return {
                ...state,
                docType: action.payload,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATORID_SET:
            return {
                ...state,
                annotatorId: action.payload,
            };

        case Actions.DOCUMENT_ANNOTATOR_PMID_SET:
            return {
                ...state,
                pmId: action.payload,
            };

        case Actions.DOCUMENT_ANNOTATOR_PUBID_SET:
            return {
                ...state,
                pubId: action.payload,
            };

        case Actions.DOCUMENT_ANNOTATOR_ANNOTATOR_TYPE_SET:
            return {
                ...state,
                annotatorType: action.payload,
            };

        // psPdfKit - the pdf viewer
        // -------------------------------------------------------------------
        case Actions.PSPDFKIT_INSTANCE_SET:
            return {
                ...state,
                psPdfKitInstance: action.payload,
            };

        // newAnnotation
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_NEW_OPEN_DIALOG_SET: {
            return {
                ...state,
                annotationNewOpenDialog: action.payload,
            };
        }

        // annotatePublication
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATE_ANNOTATORID_PUBID_SET: {
            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                pubId: action.payload.pubId,
            };
        }

        // annotateSupplement
        // -------------------------------------------------------------------
        case Actions.DOCUMENT_ANNOTATE_ANNOTATORID_SUPID_SET: {
            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                supId: action.payload.supId,
            };
        }

        /**
         * responding to documentAnnotatorDb.actions
         */
        // annotatePublication
        // -------------------------------------------------------------------
        case dbActions.DOCUMENT_ANNOTATE_PUBLICATION_REQUEST:
            return {
                ...state,
                annotatorType: 'documentAnnotator',
            };

        case dbActions.DOCUMENT_ANNOTATE_SUPPLEMENT_REQUEST:
            return {
                ...state,
                annotatorType: 'documentAnnotator',
            };

        case dbActions.DOCUMENT_ANNOTATE_PUBLICATION_SUCCESS:
            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                docType: action.payload.docType,
                docId: action.payload.docId,
                pmId: action.payload.pmId,
                pubId: action.payload.pubId,
                url: action.payload.url,
            };

        case dbActions.DOCUMENT_ANNOTATE_SUPPLEMENT_SUCCESS:
            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                docType: action.payload.docType,
                docId: action.payload.docId,
                pmId: action.payload.pmId,
                pubId: action.payload.pubId,
                url: action.payload.url,
            };

        case dbActions.DOCUMENT_ANNOTATOR_ANNOTATION_FIND_SUCCESS:

            return {
                ...state,
                annotatorId: action.payload.annotatorId,
                annotatorType: action.payload.annotatorType,
                docType: action.payload.docType,
                docId: action.payload.docId,
                pmId: action.payload.pmId,
                pubId: action.payload.pubId,
            };

        /*
        * responding to tableAnnotator actions
        */
        case TableAnnotatorActions.TABLE_ANNOTATOR_TABLE_ANNOTATE_REQUEST:
            return {
                ...state,
                annotatorType: 'tableAnnotator',
            };

        case TableAnnotatorActions.TABLE_ANNOTATOR_TABLE_ANNOTATE_SUCCESS:
            // note: these parameters from the tableAnnotator are inserted here to 
            // supported opening and editing annotations originating
            // from tables
            if (objectKeyExists(action.payload, 'annotatorId')) {
                annotatorId = action.payload.annotatorId;
            }

            if (objectExists(action.payload.table)) {
                if (objectKeyExists(action.payload.table, 'parentDocType')) {
                    docType = action.payload.table.parentDocType;
                }
                if (objectKeyExists(action.payload.table, 'parentId')) {
                    docId = action.payload.table.parentId;
                }
                if (objectKeyExists(action.payload.table, 'pmId')) {
                    pmId = action.payload.table.pmId;
                }
                if (objectKeyExists(action.payload.table, 'pubId')) {
                    pubId = action.payload.table.pubId;
                }
            }

            return {
                ...state,
                annotatorId,
                docType,
                docId,
                pmId,
                pubId,
            };

        /**
        *  responding to annotationEditor actions
        */
        case AnnotationEditorActions.ANNOTATION_EDITOR_EDIT_ANNOTATION_SUCCESS:
            // note: these parameters are inserted here to 
            // supported opening and editing annotations originating
            // from the annotationEditor
            if (objectKeyExists(action.payload, 'annotatorId')) {
                annotatorId = action.payload.annotatorId;
            }

            if (objectKeyExists(action.payload, 'annotatorType')) {
                annotatorType = action.payload.annotatorType;
            }

            if (objectKeyExists(action.payload, 'docId')) {
                docId = action.payload.docId;
            }

            if (objectKeyExists(action.payload, 'docType')) {
                docType = action.payload.docType;
            }

            if (objectKeyExists(action.payload, 'pmId')) {
                pmId = action.payload.pmId;
            }

            if (objectKeyExists(action.payload, 'pubId')) {
                pubId = action.payload.pubId;
            }

            return {
                ...state,
                annotatorId,
                annotatorType,
                docId,
                docType,
                pmId,
                pubId,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default documentAnnotatorPagesReducer;