/**
 * @file jwtService.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 21, 2021 11:44
 */
import { removeToken } from 'src/auth/authUtilities';
import store from 'src/store';

import {
    authLogout,
} from 'src/store/actions/auth.actions';

export const logout = () => {
    return new Promise((resolve) => {
        store.dispatch(authLogout());
        removeToken('auth');

        if (window.Marker) {
            window.Marker.hide();
        }

        resolve();
    });
};