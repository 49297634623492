/**
 * @file variantRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 25, 2023 20:21
 */
import { all, fork } from 'redux-saga/effects';

import variantFind from './variant/variantFind.saga';
import variantNew from './variant/variantNew.saga';
import variantUpdate from './variant/variantUpdate.saga';
import variantsDelete from './variant/variantsDelete.saga';
import variantsFind from './variant/variantsFind.saga';
import variantValidatorFind from './variant/variantValidatorFind.saga';
import variantView from './variant/variantView.saga';

import variantDiseaseDelete from './variantDisease/variantDiseaseDelete.saga';
import variantDiseaseFind from './variantDisease/variantDiseaseFind.saga';
import variantDiseaseInsert from './variantDisease/variantDiseaseInsert.saga';
import variantDiseaseUpdate from './variantDisease/variantDiseaseUpdate.saga';
import variantDiseaseView from './variantDisease/variantDiseaseView.saga';
import variantDiseasesDelete from './variantDisease/variantDiseasesDelete.saga';
import variantDiseasesInitialQuery from './variantDisease/variantDiseasesInitialQuery.saga';
import variantDiseasesPageQuery from './variantDisease/variantDiseasesPageQuery.saga';
import geneDiseaseView from './variantDisease/geneDiseaseView.saga';

import variantDiseaseClassificationCalculate from './variantDiseaseClassification/variantDiseaseClassificationCalculate.saga';
import variantDiseaseClassificationDelete from './variantDiseaseClassification/variantDiseaseClassificationDelete.saga';
import variantDiseaseClassificationFind from './variantDiseaseClassification/variantDiseaseClassificationFind.saga';
import variantDiseaseClassificationInsert from './variantDiseaseClassification/variantDiseaseClassificationInsert.saga';
import variantDiseaseClassificationUpdate from './variantDiseaseClassification/variantDiseaseClassificationUpdate.saga';
import variantDiseaseClassificationView from './variantDiseaseClassification/variantDiseaseClassificationView.saga';
import variantDiseaseClassificationsInitialQuery from './variantDiseaseClassification/variantDiseaseClassificationsInitialQuery.saga';
import variantDiseaseClassificationsPageQuery from './variantDiseaseClassification/variantDiseaseClassificationsPageQuery.saga';

import geneDiseaseAnnotationsFind from './variantDisease/geneDiseaseAnnotationsFind.saga';
import geneDiseaseClassificationView from './geneDiseaseClassification/geneDiseaseClassificationView.saga';
import geneDiseaseClassificationAnnotationsFind from './geneDiseaseClassification/geneDiseaseClassificationAnnotationsFind.saga';

// import variantDiseaseClassificationAnnotationsFind from 'src/store/sagas/variant/variantDiseaseClassificationAnnotationsFind.saga';

import evidenceInitialQuery from 'src/store/sagas/variant/variantDiseaseClassificationEvidence/evidenceInitialQuery.saga';
import evidenceInsert from 'src/store/sagas/variant/variantDiseaseClassificationEvidence/evidenceInsert.saga';
import evidenceDelete from 'src/store/sagas/variant/variantDiseaseClassificationEvidence/evidenceDelete.saga';
import evidencePageQuery from 'src/store/sagas/variant/variantDiseaseClassificationEvidence/evidencePageQuery.saga';

export default function* rootSaga() {
    yield all([

        // view
        fork(geneDiseaseView),
        fork(variantDiseaseView),

        fork(variantFind),
        fork(variantValidatorFind),
        fork(variantNew),
        fork(variantUpdate),
        fork(variantsDelete),
        fork(variantsFind),
        fork(variantView),

        fork(variantDiseaseDelete),
        fork(variantDiseaseFind),
        fork(variantDiseaseInsert),
        fork(variantDiseaseUpdate),
        fork(variantDiseaseClassificationCalculate),

        fork(variantDiseasesInitialQuery),
        fork(variantDiseasesPageQuery),
        fork(variantDiseasesDelete),

        fork(variantDiseaseClassificationsInitialQuery),
        fork(variantDiseaseClassificationsPageQuery),
        fork(variantDiseaseClassificationDelete),
        fork(variantDiseaseClassificationFind),
        fork(variantDiseaseClassificationInsert),
        fork(variantDiseaseClassificationUpdate),
        fork(variantDiseaseClassificationView),

        fork(geneDiseaseAnnotationsFind),
        fork(geneDiseaseClassificationView),
        fork(geneDiseaseClassificationAnnotationsFind),

        fork(evidenceInitialQuery),
        fork(evidenceDelete),
        fork(evidenceInsert),
        fork(evidencePageQuery),

    ]);
}
