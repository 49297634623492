/**
 * @file geneModelPages.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Mar 02, 2022 15:39
 */
import * as Actions from 'src/store/actions/geneModel/geneModelPages.actions';

import { stringExists } from 'src/@common/utilities';

const initialState = {

    // geneModel.search
    searchTerm: '',
    filterText: '',

    annotationDistribChartsOpenDialog: false,

    geneDiseaseClassificationDialog: false,
    geneDiseaseClassificationNewDialog: false,

    geneDiseaseNewOpenDialog: false,
    geneModelNewOpenDialog: false,
    geneModelViewOpenDialog: false,

    // server status
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const geneModelPagesReducer = (state = initialState, action) => {

    let fieldName = null;
    let fieldValue = null;

    switch (action.type) {

        // geneModel.search.updateField - updates redux from local storage onBlur
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_SEARCH_UPDATE_FIELD: {

            fieldName = action.payload.fieldName;
            fieldValue = action.payload.fieldValue;

            if (stringExists(fieldName)) {
                switch (fieldName) {
                    default:
                        return {
                            ...state,
                            [fieldName]: fieldValue,
                        };
                }
            }

            return {
                ...state,
            };
        }

        // geneModel.search.session
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_SEARCH_SESSION_SET:
            return {
                ...state,
                filterText: action.payload.filterText,
                searchTerm: action.payload.searchTerm,
            };

        case Actions.ANNOTATION_DISTRIB_CHARTS_OPEN_DIALOG_SET: {
            return {
                ...state,
                annotationDistribChartsOpenDialog: action.payload,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_DIALOG_SET: {
            return {
                ...state,
                geneDiseaseClassificationDialog: action.payload,
            };
        }

        case Actions.GENE_DISEASE_CLASSIFICATION_NEW_DIALOG_SET: {
            return {
                ...state,
                geneDiseaseClassificationNewDialog: action.payload,
            };
        }

        case Actions.GENE_DISEASE_NEW_OPEN_DIALOG_SET: {
            return {
                ...state,
                geneDiseaseNewOpenDialog: action.payload,
            };
        }

        // geneModel.new dialog
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_NEW_OPEN_DIALOG_SET: {
            return {
                ...state,
                geneModelNewOpenDialog: action.payload,
            };
        }

        // -------------------------------------------------------------------
        // viewGeneModel
        // -------------------------------------------------------------------
        case Actions.GENE_MODEL_VIEW_OPEN_DIALOG_SET: {
            return {
                ...state,
                geneModelViewOpenDialog: action.payload,
            };
        }

        /************* */
        default: {
            return state;
        }
    }

};

export default geneModelPagesReducer;