/**
 * @file geneModel.root.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 15, 2021 17:28
 */
import { all, fork } from 'redux-saga/effects';

import annotationDistributionChart from 'src/store/sagas/geneModel/annotationDistributionChart.saga';

import geneModelFind from 'src/store/sagas/geneModel/geneModel/geneModelFind.saga';
import geneModelNew from 'src/store/sagas/geneModel/geneModel/geneModelNew.saga';
import geneModelSearch from 'src/store/sagas/geneModel/geneModel/geneModelSearch.saga';
import geneModelUpdate from 'src/store/sagas/geneModel/geneModel/geneModelUpdate.saga';
import geneModelView from 'src/store/sagas/geneModel/geneModel/geneModelView.saga';
import geneModelsDelete from 'src/store/sagas/geneModel/geneModel/geneModelsDelete.saga';

import geneDiseasesDelete from 'src/store/sagas/geneModel/geneDisease/geneDiseasesDelete.saga';
import geneDiseasesFind from 'src/store/sagas/geneModel/geneDisease/geneDiseasesFind.saga';

import geneDiseaseFind from 'src/store/sagas/geneModel/geneDisease/geneDiseaseFind.saga';
import geneDiseaseNew from 'src/store/sagas/geneModel/geneDisease/geneDiseaseNew.saga';
import geneDiseaseUpdate from 'src/store/sagas/geneModel/geneDisease/geneDiseaseUpdate.saga';

import geneDiseaseClassificationDelete from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationDelete.saga';
import geneDiseaseClassificationFind from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationFind.saga';
import geneDiseaseClassificationNew from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationNew.saga';
import geneDiseaseClassificationInsert from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationInsert.saga';
import geneDiseaseClassificationUpdate from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationUpdate.saga';

import geneDiseaseClassificationsDelete from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationsDelete.saga';
import geneDiseaseClassificationsFind from 'src/store/sagas/geneModel/geneDiseaseClassification/geneDiseaseClassificationsFind.saga';

import geneDiseaseClassificationEvidenceInitialQuery from 'src/store/sagas/geneModel/geneDiseaseClassificationEvidence/geneDiseaseClassificationEvidenceInitialQuery.saga';
import geneDiseaseClassificationEvidenceInsert from 'src/store/sagas/geneModel/geneDiseaseClassificationEvidence/geneDiseaseClassificationEvidenceInsert.saga';
import geneDiseaseClassificationEvidenceDelete from 'src/store/sagas/geneModel/geneDiseaseClassificationEvidence/geneDiseaseClassificationEvidenceDelete.saga';
import geneDiseaseClassificationEvidenceDeleteAll from 'src/store/sagas/geneModel/geneDiseaseClassificationEvidence/geneDiseaseClassificationEvidenceDeleteAll.saga';
import geneDiseaseClassificationEvidencePageQuery from 'src/store/sagas/geneModel/geneDiseaseClassificationEvidence/geneDiseaseClassificationEvidencePageQuery.saga';

export default function* rootSaga() {
    yield all([

        fork(annotationDistributionChart),

        fork(geneModelFind),
        fork(geneModelNew),
        fork(geneModelSearch),
        fork(geneModelUpdate),
        fork(geneModelView),

        fork(geneModelsDelete),

        fork(geneDiseasesDelete),
        fork(geneDiseasesFind),
        fork(geneDiseaseFind),
        fork(geneDiseaseNew),
        fork(geneDiseaseUpdate),

        fork(geneDiseaseClassificationDelete),
        fork(geneDiseaseClassificationFind),
        fork(geneDiseaseClassificationNew),
        fork(geneDiseaseClassificationInsert),
        fork(geneDiseaseClassificationUpdate),

        fork(geneDiseaseClassificationsDelete),
        fork(geneDiseaseClassificationsFind),

        fork(geneDiseaseClassificationEvidenceInitialQuery),
        fork(geneDiseaseClassificationEvidenceDelete),
        fork(geneDiseaseClassificationEvidenceDeleteAll),
        fork(geneDiseaseClassificationEvidenceInsert),
        fork(geneDiseaseClassificationEvidencePageQuery),

    ]);
}
