/**
 * @file annotationView.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 08, 2023 10:15
 */
import * as Actions from 'src/store/actions/annotationView.actions';

const initialState = {
    annotationId: '',
    annotationViewData: {},
    dataLoaded: false,

    annotationViewOpenDialog: false,
};

const annotationViewReducer = (state = initialState, action) => {
    switch (action.type) {

        // for testing
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_VIEW_DATA_SET: {
            return {
                ...state,
                annotationViewData: action.payload,
            };
        }


        // setters
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_VIEW_ANNOTATION_ID_SET: {
            return {
                ...state,
                annotationId: action.payload,
            };
        }

        case Actions.ANNOTATION_VIEW_DIALOG_OPEN_SET: {
            return {
                ...state,
                annotationViewOpenDialog: action.payload,
            };
        }

        // annotationView
        // -------------------------------------------------------------------
        case Actions.ANNOTATION_VIEW_CREATE_REQUEST:
            return {
                ...state,
                annotationViewData: {},
                dataLoaded: false,
            };

        case Actions.ANNOTATION_VIEW_CREATE_SUCCESS:
            return {
                ...state,
                annotationViewData: action.payload.annotationViewData,
                dataLoaded: true,
            };

        case Actions.ANNOTATION_VIEW_CREATE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default: {
            return state;
        }
    }
};

export default annotationViewReducer;
