/**
 * @file App.jsx
 * @description application entry point
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jul 13, 2023 18:53
 */
import { useRoutes } from 'react-router-dom';
import router from 'src/router/routes';

import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { CssBaseline } from '@mui/material';
// import ThemeProvider from './theme/ThemeProvider';

import ThemeProvider from './theme/ThemeProvider';
import CssBaseline from "@mui/material/CssBaseline"

import AppInit from './components/AppInit';
import NotificationService from 'src/services/notifications/NotificationService';

// components that should occur only once in the app
import AnnotationViewDialog from 'src/content/AnnotationView/AnnotationViewDialog';
import AnnotationDistribChartDialog from 'src/content/GeneModel/edit/Disease/AnnotationDistribCharts/ChartDialog';
import SuspenseLoader from 'src/components/SuspenseLoader';

function App() {
    const routes = router();
    const content = useRoutes(routes);

    const auth = {
        isInitialized: true
    };

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <CssBaseline />

                    <NotificationService>
                        {auth.isInitialized ? content : <AppInit />}
                        <AnnotationViewDialog />
                        <AnnotationDistribChartDialog />
                        <SuspenseLoader />
                    </NotificationService>

                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;