/**
 * @file AnnotationViewDialog.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 02, 2022 13:05
 */
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AnnotationViewContainer from 'src/content/AnnotationView/AnnotationViewContainer';

import {
    annotationViewOpenDialogSet,
} from 'src/store/actions/annotationView.actions';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        minHeight: '15vh',
        maxHeight: '25vh',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function AnnotationViewDialog() {
    const classes = useStyles();
    const dispatch = useDispatch();

    // start: redux state --------------------------
    const annotationViewState = useSelector((state) => {
        return state.annotationView;
    });

    const { annotationViewOpenDialog } = annotationViewState;
    // end: redux state -----------------------------

    const handleClose = () => {
        dispatch(annotationViewOpenDialogSet(false));
    }

    return (
        <Dialog
            hideBackdrop
            open={annotationViewOpenDialog}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="draggable-dialog-title"
            scroll={'paper'}
            fullWidth maxWidth="sm"
        >
            <DialogActions sx={{ marginBottom: '0px' }}>
                <Button variant="outlined" size="small" onClick={handleClose}>Close</Button>
            </DialogActions>

            <DialogTitle sx={{ paddingTop: '0px', paddingBottom: '0px', marginBottom: '5px' }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    {'Annotation'}
                </Typography>
            </DialogTitle>

            <Divider sx={{ 'marginBottom': '0px', 'marginTop': '5px' }} />

            <DialogContent sx={{ paddingTop: '0px' }}>
                <AnnotationViewContainer />
            </DialogContent>

        </Dialog>
    )
}

export default AnnotationViewDialog;