import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// auth pages
const LoginPassword = Loader(lazy(() => import('src/content/Auth/LoginPassword/LoginPasswordContainer')));
const LoginOtp = Loader(lazy(() => import('src/content/Auth/LoginOtp/LoginOtpContainer')));
const OtpSetup = Loader(lazy(() => import('src/content/Auth/OtpSetup/OtpSetupContainer')));

const authRoutes = [
    {
        path: '',
        element: <Navigate to="signin" replace />
    },
    {
        path: 'signin',
        element: <LoginPassword />
    },
    {
        path: 'otpLogin',
        element: <LoginOtp />
    },
    {
        path: 'otpSetup',
        element: <OtpSetup />
    },
];

export default authRoutes;
