/**
 * @file variantNew.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 28, 2023 12:03
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import { getToken } from 'src/auth/authUtilities';
import { objectExists, objectKeyExists, stringExists } from 'src/@common/utilities';

import {
    VARIANT_NEW_REQUEST,
    variantFindRequest,
    variantNewSuccess,
    variantNewFailure,
} from '../../../actions/variant/variantDb.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/variant/variant/new';

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            cDot: payload.cDot,
            genomeBuild: payload.genomeBuild,
            transcript: payload.transcript,
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* requestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            if (resp.payload.errorExists && stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.generalInfoMessage.options)
                );
            }

            yield put(variantNewSuccess(resp.payload));

            if (objectExists(resp.payload) &&
                objectKeyExists(resp.payload, 'variantId') &&
                stringExists(resp.payload.variantId)) {

                const variantId = resp.payload.variantId;
                yield put(variantFindRequest(variantId, action.payload.navigate));

                if (action.payload.navigate) {
                    // navigate to the edit page
                    const variantId = resp.payload.variantId;
                    const targetPath = `/variant/edit/${variantId}`;

                    return action.payload.navigate(targetPath);
                }

            }

        } catch (error) {
            yield put(variantNewFailure(error));
            handleSagaErrors(error);
        } finally {
            yield put(dataLoadingSet(false));
        }

    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* requestMonitor() {
    yield takeLatest(VARIANT_NEW_REQUEST, requestSaga);
}
