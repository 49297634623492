/**
 * @file SuspenseLoader.index.jsx
 * @description circularProgress driven by redux.global.dataLoading
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 03, 2023 19:54
 */
import { useSelector } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';

function SuspenseLoader() {

    // start: redux state --------------------------
    const globalState = useSelector((state) => {
        return state.global;
    });

    const {
        dataLoading,
    } = globalState;
    // end: redux state -----------------------------

    if (dataLoading) {

        return (
            <Box
                sx={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 'tooltip'
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress size={64} disableShrink thickness={3} />
            </Box>
        );

    } else {
        return null;
    }


}

export default SuspenseLoader;
