/**
 * @file ErrorBoundry.js
 * @description react errorBoundry which pushes logs errors to server
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 * 
 * @author don michael
 * @since Sep 29, 2019 16:22
 */
import { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Typography,
    Container,
    Button,
    Grid,
    styled
} from '@mui/material';

import logger from 'src/services/logger';

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-top: 50px;
`
);

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logger('ErrorBoundry Error Catch', { error, errorInfo });
    }

    render() {
        if (this.state.hasError) {

            // You can render any custom fallback UI
            return (<>

                <MainContent>
                    <Grid
                        container
                        sx={{
                            height: '100%'
                        }}
                        alignItems="stretch"
                        spacing={0}
                    >
                        <Grid
                            xs={12}
                            md={6}
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                            item
                        >
                            <Container maxWidth="sm">
                                <Box textAlign="center">
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            my: 2
                                        }}
                                    >
                                        {'There was an error. We have been notified. Please try again later.'}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="text.secondary"
                                        fontWeight="normal"
                                        sx={{
                                            mb: 4
                                        }}
                                    >
                                        {
                                            'An internal error has occurred. We are not able to complete your request.'
                                        }
                                    </Typography>

                                    <Button
                                        href="/"
                                        variant="contained"
                                        sx={{
                                            ml: 1
                                        }}
                                    >
                                        {'Go back'}
                                    </Button>
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                </MainContent>
            </>)
        }

        // when there's not an error, render children untouched
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
