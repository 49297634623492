/**
 * @file geneModelNew.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * https://stackoverflow.com/questions/70881320/redirect-to-route-from-saga-using-react-router-v6
 * 
 * @author don michael
 * @since Nov 29, 2021 14:30
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'axios';

import standardMessages from 'src/services/notifications/standardMessages';

import {
    enqueueMessage,
} from 'src/store/actions/global.actions';

import { getToken } from 'src/auth/authUtilities';
import { stringExists } from 'src/@common/utilities';

import {
    GENE_MODEL_NEW_REQUEST,
    geneModelNewSuccess,
    geneModelNewFailure,
} from 'src/store/actions/geneModel/geneModelDb.actions';

import {
    geneModelNewOpenDialogSet,
} from 'src/store/actions/geneModel/geneModelPages.actions';

import { dataLoadingSet } from 'src/store/actions/global.actions';
import handleSagaErrors from 'src/store/sagas/handleSagaErrors';

function callApi(authToken, payload) {
    const url = '/geneModel/geneModelNew';


    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    const config = {
        method: 'post',
        baseURL: baseUrl,
        url,
        data: {
            geneSymbol: payload.geneSymbol,
        },
        headers: {
            Authorization: authToken,
        },
    };

    return new Promise((resolve, reject) => {
        request(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function* createRequestSaga(action) {
    const authToken = getToken('auth');

    if (authToken) {
        try {
            yield put(dataLoadingSet(true));

            const resp = yield call(callApi, authToken, action.payload);

            yield put(geneModelNewSuccess(resp.payload));

            if (stringExists(resp.payload.serverMessage)) {
                yield put(enqueueMessage(
                    resp.payload.serverMessage,
                    standardMessages.generalInfoMessage.options)
                );
            }

            // close the dialog and route to the edit page
            yield put(geneModelNewOpenDialogSet(false));
            const geneModelId = resp.payload.geneModelId;
            const targetPath = `/geneModel/edit/${geneModelId}`;
            return action.payload.navigate(targetPath);

        } catch (error) {
            yield put(geneModelNewFailure(error));
            handleSagaErrors(error);

        } finally {
            yield put(dataLoadingSet(false));
        }

    } else {
        yield put(enqueueMessage(
            standardMessages.notAuthorized.message,
            standardMessages.networkError.options)
        );
    }
}

export default function* createRequestMonitor() {
    yield takeLatest(GENE_MODEL_NEW_REQUEST, createRequestSaga);
}
