/**
 * @file sessionAnnotationsDb.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 13, 2023 18:02
 */

// -------------------------------------------------------------------
// sessionAnnotations.setters - used mostly for development
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_ANNOTATIONS_SET = 'SESSION_ANNOTATIONS_ANNOTATIONS_SET';
export function annotationsSet(annotations) {
    return {
        type: SESSION_ANNOTATIONS_ANNOTATIONS_SET,
        payload: annotations,
    };
}

export const SESSION_ANNOTATIONS_METADATA_SET = 'SESSION_ANNOTATIONS_METADATA_SET';
export function metadataSet(value) {
    return {
        type: SESSION_ANNOTATIONS_METADATA_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.page redirection
// -------------------------------------------------------------------
export const REDIRECT_TO_SESSION_ANNOTATIONS_SET = 'REDIRECT_TO_SESSION_ANNOTATIONS_SET';
export function redirectToTableAnnotationsSet(value) {
    return {
        type: REDIRECT_TO_SESSION_ANNOTATIONS_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.annotations - requests initial page of data
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_INITIAL_QUERY_REQUEST = 'SESSION_ANNOTATIONS_INITIAL_QUERY_REQUEST';
export function sessionAnnotationsInitialQueryRequest(annotatorId, docType, docId) {
    return {
        type: SESSION_ANNOTATIONS_INITIAL_QUERY_REQUEST,
        payload: {
            annotatorId,
            docType,
            docId,
        }
    };
}

export const SESSION_ANNOTATIONS_INITIAL_QUERY_SUCCESS = 'SESSION_ANNOTATIONS_INITIAL_QUERY_SUCCESS';
export function sessionAnnotationsInitialQuerySuccess(payload) {
    return {
        type: SESSION_ANNOTATIONS_INITIAL_QUERY_SUCCESS,
        payload,
    };
}

export const SESSION_ANNOTATIONS_INITIAL_QUERY_FAILURE = 'SESSION_ANNOTATIONS_INITIAL_QUERY_FAILURE';
export function sessionAnnotationsInitialQueryFailure(error) {
    return {
        type: SESSION_ANNOTATIONS_INITIAL_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.annotations - requests subsequent pages of annotations
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_PAGE_QUERY_REQUEST = 'SESSION_ANNOTATIONS_PAGE_QUERY_REQUEST';
export function sessionAnnotationsPageQueryRequest(pageObj) {
    return {
        type: SESSION_ANNOTATIONS_PAGE_QUERY_REQUEST,
        payload: pageObj,
    };
}

export const SESSION_ANNOTATIONS_PAGE_QUERY_SUCCESS = 'SESSION_ANNOTATIONS_PAGE_QUERY_SUCCESS';
export function sessionAnnotationsPageQuerySuccess(payload) {
    return {
        type: SESSION_ANNOTATIONS_PAGE_QUERY_SUCCESS,
        payload,
    };
}

export const SESSION_ANNOTATIONS_PAGE_QUERY_FAILURE = 'SESSION_ANNOTATIONS_PAGE_QUERY_FAILURE';
export function sessionAnnotationsPageQueryFailure(error) {
    return {
        type: SESSION_ANNOTATIONS_PAGE_QUERY_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.sessionAnnotationsDelete
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_DELETE_REQUEST = 'SESSION_ANNOTATIONS_DELETE_REQUEST';
export function sessionAnnotationsDeleteRequest(annotatorId, annotationIds, docId, docType) {
    return {
        type: SESSION_ANNOTATIONS_DELETE_REQUEST,
        payload: {
            annotatorId,
            annotationIds,
            docId,
            docType,
        }
    };
}

export const SESSION_ANNOTATIONS_DELETE_SUCCESS = 'SESSION_ANNOTATIONS_DELETE_SUCCESS';
export function sessionAnnotationsDeleteSuccess(payload) {
    return {
        type: SESSION_ANNOTATIONS_DELETE_SUCCESS,
        payload,
    };
}

export const SESSION_ANNOTATIONS_DELETE_FAILURE = 'SESSION_ANNOTATIONS_DELETE_FAILURE';
export function sessionAnnotationsDeleteFailure(error) {
    return {
        type: SESSION_ANNOTATIONS_DELETE_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.annotations - requests initial page of data
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_REQUEST = 'SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_REQUEST';
export function sessionAnnotationsRefreshAnnotationsRequest(annotatorId, docType, docId) {
    return {
        type: SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_REQUEST,
        payload: {
            annotatorId,
            docType,
            docId,
        }
    };
}

export const SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_SUCCESS = 'SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_SUCCESS';
export function sessionAnnotationsRefreshAnnotationsSuccess(payload) {
    return {
        type: SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_SUCCESS,
        payload,
    };
}

export const SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_FAILURE = 'SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_FAILURE';
export function sessionAnnotationsRefreshAnnotationsFailure(error) {
    return {
        type: SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_FAILURE,
        payload: JSON.stringify(error),
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.checked
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_CHECKED_ROW_SET = 'SESSION_ANNOTATIONS_CHECKED_ROW_SET';
export function sessionAnnotationsRowCheckSet(value, annotationId,) {
    return {
        type: SESSION_ANNOTATIONS_CHECKED_ROW_SET,
        payload: {
            annotationId,
            value,
        }
    };
}

export const SESSION_ANNOTATIONS_CHECKED_ALL_SET = 'SESSION_ANNOTATIONS_CHECKED_ALL_SET';
export function sessionAnnotationsRowCheckAllSet(value) {
    return {
        type: SESSION_ANNOTATIONS_CHECKED_ALL_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// sessionAnnotations.table.metadata
// -------------------------------------------------------------------
export const SESSION_ANNOTATIONS_METADATA_REQUEST = 'SESSION_ANNOTATIONS_METADATA_REQUEST';
export function sessionAnnotationsMetadataRequest(annotatorId, tableId) {
    return {
        type: SESSION_ANNOTATIONS_METADATA_REQUEST,
        payload: {
            annotatorId,
            tableId,
        }
    };
}

export const SESSION_ANNOTATIONS_METADATA_SUCCESS = 'SESSION_ANNOTATIONS_METADATA_SUCCESS';
export function sessionAnnotationsMetadataSuccess(payload) {
    return {
        type: SESSION_ANNOTATIONS_METADATA_SUCCESS,
        payload,
    };
}

export const SESSION_ANNOTATIONS_METADATA_FAILURE = 'SESSION_ANNOTATIONS_METADATA_FAILURE';
export function sessionAnnotationsMetadataFailure(error) {
    return {
        type: SESSION_ANNOTATIONS_METADATA_FAILURE,
        payload: JSON.stringify(error),
    };
}
