/**
 * @file dashboard.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Oct 30, 2023 10:41 AM
 */
import * as Actions from 'src/store/actions/dashboard.actions';

const initialState = {
    collectionCountObj: {},
};

const dashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        // collectionCounts
        // -------------------------------------------------------------------
        case Actions.DASHBOARD_COLLECTION_COUNTS_REQUEST:
            return {
                ...state,
            };

        case Actions.DASHBOARD_COLLECTION_COUNTS_SUCCESS:
            return {
                ...state,
                collectionCountObj: action.payload.collectionCountObj,
            };

        case Actions.DASHBOARD_COLLECTION_COUNTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default: {
            return state;
        }
    }
};

export default dashboardReducer;
