/**
 * @file baseRoutes.jsx
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Dec 06, 2023 18:48
 */
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from './Loader';

import Status500 from 'src/content/pages/Status/Status500';

// Status
const Status404 = Loader(
    lazy(() => import('src/content/pages/Status/Status404'))
);

const StatusComingSoon = Loader(
    lazy(() => import('src/content/pages/Status/ComingSoon'))
);

const StatusMaintenance = Loader(
    lazy(() => import('src/content/pages/Status/Maintenance'))
);

const baseRoutes = [
    {
        path: '/',
        element: <Navigate to="/dashboard" replace />
    },
    {
        path: '*',
        element: <Status404 />
    },
    {
        path: 'status',
        children: [
            {
                path: '500',
                element: <Status500 />
            },
            {
                path: 'maintenance',
                element: <StatusMaintenance />
            },
            {
                path: 'coming-soon',
                element: <StatusComingSoon />
            }
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export default baseRoutes;
