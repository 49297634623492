/**
 * @file annotationSearchRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 15, 2023 10:48
 */
import { all, fork } from 'redux-saga/effects';

import annotationSearchDelete from 'src/store/sagas/annotationSearch/annotationSearchDelete.saga';
import annotationSearchInitialQuery from 'src/store/sagas/annotationSearch/annotationSearchInitialQuery.saga';
import annotationSearchPageQuery from 'src/store/sagas/annotationSearch/annotationSearchPageQuery.saga';
import annotationSearchRefresh from 'src/store/sagas/annotationSearch/annotationSearchRefresh.saga';

export default function* rootSaga() {
    yield all([

        fork(annotationSearchDelete),
        fork(annotationSearchInitialQuery),
        fork(annotationSearchPageQuery),
        fork(annotationSearchRefresh),

    ]);
}
