/**
 * @file helpDocViewerRoot.saga.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 20, 2023 15:59
 */
import { all, fork } from 'redux-saga/effects';

import getUrl from 'src/store/sagas/helpDocViewer/getUrl.saga';

export default function* rootSaga() {
    yield all([

        fork(getUrl),

    ]);
}
