/**
 * @file AnnotationViewForm.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 01, 2022 11:42
 */
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { objectKeyExists } from 'src/@common/utilities';
import ListItemField from 'src/components/fieldComponents/VeriomeListItemField';

import RenderFields from './RenderFields';

function AnnotationViewForm(props) {
    const {
        annotationViewData,
    } = props;

    return (

        <div className="mx-auto print:w-full print:shadow-none rounded-none sm:rounded-20">

            <div className="print:p-0">

                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>

                    <ListItem>
                        <ListItemField
                            label={annotationViewData.annotationType.label}
                            value={annotationViewData.annotationType.value}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemField
                            label={annotationViewData.pmId.label}
                            value={annotationViewData.pmId.value}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemField
                            label={annotationViewData.parentDocType.label}
                            value={annotationViewData.parentDocType.value}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemField
                            label={'Title'}
                            value={annotationViewData.publicationTitle.value}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemField
                            label={annotationViewData.curatedText.label}
                            value={annotationViewData.curatedText.value}
                        />
                    </ListItem>

                    {objectKeyExists(annotationViewData, 'geneSymbol') &&
                        <ListItem >
                            <ListItemField
                                label={annotationViewData.geneSymbol.label}
                                value={annotationViewData.geneSymbol.value}
                            />
                        </ListItem>
                    }

                    {objectKeyExists(annotationViewData, 'disease') &&
                        <ListItem >
                            <ListItemField
                                label={annotationViewData.disease.label}
                                value={annotationViewData.disease.value}
                            />
                        </ListItem>
                    }

                </List>

                <div className="mt-0 print:mt-0">

                    <List dense >

                        <RenderFields
                            fields={annotationViewData.fields}
                        />

                    </List>

                    <Typography
                        sx={{ marginLeft: '12px' }}
                        component="div" variant="captionText"
                        color="text.secondary">
                        {annotationViewData.createdBy.value}
                    </Typography>

                </div>

            </div>

        </div >
    );
}

AnnotationViewForm.propTypes = {
    annotationViewData: PropTypes.object.isRequired,
};

export default AnnotationViewForm;
