/**
 * @file sessionAnnotationsDb.reducer.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 13, 2023 18:09
 */
import * as Actions from 'src/store/actions/sessionAnnotations/sessionAnnotationsDb.actions';
import { objectDeepCopy } from 'src/@common/utilities';

const initialState = {
    // note: biocurations for the table + publication or supplement
    // are stored in the documentAnnatatorDb.reducer to
    // support editing annotations within the documentAnnotator
    // biocurations: {},

    // annotation session parameters
    annotatorId: null,
    docId: null,
    docType: null,

    // redirections
    redirectToTableAnnotations: false,

    // annotations for the current page
    annotations: [],

    // metadata about the documents
    metadata: {},

    dataLoaded: false,

    // received from server on firs call to get annotations
    // contains annotationIds for each page
    pageMap: {
        queryCnt: 0,
        pages: [],
    },

    pageSize: 8,

    // server status
    error: null,
    serverErrorExists: false,
    serverFieldErrors: {},  // field errors from the server, passed into the form for display
    serverMessage: '', // message from server for display in toaster message
};

const sessionAnnotationsDbReducer = (state = initialState, action) => {

    let annotationId = null;
    let foundRow = null;
    let tmpTable = null;
    let value = null;

    switch (action.type) {

        // sessionAnnotations.setters - used mostly for development
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_ANNOTATIONS_SET: {
            return {
                ...state,
                annotations: action.payload,
            };
        }

        case Actions.SESSION_ANNOTATIONS_METADATA_SET: {
            return {
                ...state,
                metadata: action.payload,
            };
        }

        case Actions.REDIRECT_TO_SESSION_ANNOTATIONS_SET: {
            return {
                ...state,
                redirectToTableAnnotations: action.payload,
            };
        }

        // sessionAnnotations.annotations - requests initial page of data
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_INITIAL_QUERY_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                metadata: {},
                pageMap: {},
                annotatorId: action.payload.annotatorId,
                docId: action.payload.docId,
                docType: action.payload.docType,
            };

        case Actions.SESSION_ANNOTATIONS_INITIAL_QUERY_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                metadata: action.payload.metadata,
                pageMap: action.payload.pageMap,
            };

        case Actions.SESSION_ANNOTATIONS_INITIAL_QUERY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // sessionAnnotations.annotations - requests subsequent pages of annotations
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_PAGE_QUERY_REQUEST:
            return {
                ...state,
                annotations: [],
                dataLoaded: false,
            };

        case Actions.SESSION_ANNOTATIONS_PAGE_QUERY_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
            };

        case Actions.SESSION_ANNOTATIONS_PAGE_QUERY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // sessionAnnotations.sessionAnnotations.delete
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_DELETE_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                annotations: [],
            };

        case Actions.SESSION_ANNOTATIONS_DELETE_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                pageMap: action.payload.pageMap,
            };

        case Actions.SESSION_ANNOTATIONS_DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // sessionAnnotations.refreshAnnotations
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                // annotations: [],
                pageMap: {},
            };

        case Actions.SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                annotations: action.payload.annotations,
                pageMap: action.payload.pageMap,
            };

        case Actions.SESSION_ANNOTATIONS_REFRESH_ANNOTATIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        // sessionAnnotations.annotations.checked
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_CHECKED_ROW_SET:
            annotationId = action.payload.annotationId;
            value = action.payload.value;

            tmpTable = objectDeepCopy(state.annotations);

            foundRow = tmpTable.find((row) => {
                return row._id === annotationId;
            });

            if (foundRow) {
                foundRow.selected = value;
            }

            return {
                ...state,
                annotations: tmpTable,
            };

        case Actions.SESSION_ANNOTATIONS_CHECKED_ALL_SET:
            value = action.payload;

            tmpTable = objectDeepCopy(state.annotations);

            tmpTable.forEach((row) => {
                row.selected = value;
            })

            return {
                ...state,
                annotations: tmpTable,
            };

        // sessionAnnotations.table.metadata
        // -------------------------------------------------------------------
        case Actions.SESSION_ANNOTATIONS_METADATA_REQUEST:
            return {
                ...state,
                dataLoaded: false,
                metadata: {},
            };

        case Actions.SESSION_ANNOTATIONS_METADATA_SUCCESS:
            return {
                ...state,
                dataLoaded: true,
                metadata: action.payload.metadata,
            };

        case Actions.SESSION_ANNOTATIONS_METADATA_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        /************* */
        default: {
            return state;
        }
    }

};

export default sessionAnnotationsDbReducer;