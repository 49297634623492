/**
 * @file annotationViewer.actions.js
 * @description
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 01, 2022 11:51
 */

// -------------------------------------------------------------------
// setters
// -------------------------------------------------------------------
export const ANNOTATION_VIEW_DIALOG_OPEN_SET = 'ANNOTATION_VIEW_DIALOG_OPEN_SET';
export function openAnnotationViewerDialogSet(value) {
    return {
        type: ANNOTATION_VIEW_DIALOG_OPEN_SET,
        payload: value,
    };
}

export const ANNOTATION_VIEW_ANNOTATION_ID_SET = 'ANNOTATION_VIEW_ANNOTATION_ID_SET';
export function annotationIdSet(annotationId) {
    return {
        type: ANNOTATION_VIEW_ANNOTATION_ID_SET,
        payload: annotationId,
    };
}

/// annotationViewCreate
export const ANNOTATION_VIEW_CREATE_VIEW_REQUEST = 'ANNOTATION_VIEW_CREATE_VIEW_REQUEST';
export function annotationCreateViewRequest(annotationId) {
    return {
        type: ANNOTATION_VIEW_CREATE_VIEW_REQUEST,
        payload: annotationId,
    };
}

export const ANNOTATION_VIEW_CREATE_VIEW_SUCCESS = 'ANNOTATION_VIEW_CREATE_VIEW_SUCCESS';
export function annotationCreateViewSuccess(payload) {
    return {
        type: ANNOTATION_VIEW_CREATE_VIEW_SUCCESS,
        payload,
    };
}

export const ANNOTATION_VIEW_CREATE_VIEW_FAILURE = 'ANNOTATION_VIEW_CREATE_VIEW_FAILURE';
export function annotationCreateViewFailure(error) {
    return {
        type: ANNOTATION_VIEW_CREATE_VIEW_FAILURE,
        payload: JSON.stringify(error),
    };
}

export const ANNOTATION_VIEW_CREATE_VIEW_RESET = 'ANNOTATION_VIEW_CREATE_VIEW_RESET';
export function annotationCreateViewReset() {
    return {
        type: ANNOTATION_VIEW_CREATE_VIEW_RESET,
    };
}
