/**
 * @file GlobalDataManager.js
 * @description singleton that holds global data that can't
 *              be stored in redux (because it can't be serialized)
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since May 16, 2022 20:39
 */
let instance = null;

class GlobalDataManager {
    constructor() {
        // psPdfKit instance
        this._pdfViewerInstance = null;
    }

    set pdfViewerInstance(instance) {
        this._pdfViewerInstance = instance;
    }

    get pdfViewerInstance() {
        return this._pdfViewerInstance;
    }

    // singleton
    static getInstance() {
        if (!instance) {
            instance = new GlobalDataManager();
        }

        return instance;
    }
}

export default GlobalDataManager;