/**
 * @file TokenAuthentication.js
 * @description uses existing token to authenticated
 *              upon page refresh
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Feb 06, 2022 17:23
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { useDispatch } from 'src/store';

import { getToken } from 'src/auth/authUtilities';
import { stringExists } from 'src/@common/utilities';

import {
    loginTokenRequest,
    authLogout,
} from 'src/store/actions/auth.actions';

const TokenAuthentication = (props) => {
    const { children } = props;
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // start: redux state --------------------------
    const authState = useSelector((state) => {
        return state.auth;
    });

    const { authenticated } = authState;
    // end: redux state -----------------------------

    const { pathname } = location;

    useEffect(() => {
        if (!authenticated) {

            let authToken = null;

            switch (pathname) {
                case '/401':
                case '/403':
                case '/404':
                    break;

                default:
                    authToken = getToken('auth');

                    if (stringExists(authToken)) {
                        dispatch(loginTokenRequest(authToken, navigate));
                    } else {
                        dispatch(authLogout());
                    }

                    break;
            }
        }

    }, [authenticated, dispatch, navigate, pathname]);

    return <>{children}</>
}

TokenAuthentication.propTypes = {
    children: PropTypes.object.isRequired,
};

export default TokenAuthentication;