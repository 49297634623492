/**
 * @file serverAnnotationObjInject.js
 * @description injects an annotationObj received from the server
 *              back into redux.annotationGroup
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Sep 17, 2023 10:15
 */
import { arrayExists, objectDeepCopy, objectExists, objectKeyExists } from 'src/@common/utilities';

function serverAnnotationObjInject(revisedAnnotationObj, annotationGroup) {

    let annotationGroupCopy = objectDeepCopy(annotationGroup);

    if (objectExists(annotationGroupCopy) &&
        objectKeyExists(annotationGroupCopy, 'annotationObjs') &&
        arrayExists(annotationGroupCopy.annotationObjs) &&
        objectExists(revisedAnnotationObj)) {

        const targetAnnotationId = revisedAnnotationObj.annotation._id;

        // find the target annotation in the annotationObjs array
        let revisedAnnotationObjIndex = annotationGroupCopy.annotationObjs.findIndex((item) => {
            return item.annotation._id === targetAnnotationId;
        });

        if (revisedAnnotationObjIndex !== -1) {
            annotationGroupCopy.annotationObjs[revisedAnnotationObjIndex] = objectDeepCopy(revisedAnnotationObj);
        }

    }

    return annotationGroupCopy;
}

export default serverAnnotationObjInject;