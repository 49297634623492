import { lazy } from 'react';

import Loader from './Loader';

const Annotations = Loader(
    lazy(() => import('src/content/AnnotationSearch/SearchContainer'))
);

const annotationsRoutes = [
    {
        path: '',
        element: <Annotations />
    },
];

export default annotationsRoutes;
