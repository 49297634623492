/**
 * @file global.actions.js
 * @description general actions
 *
 * @copyright veriome labs, llc.  - all rights reserved.
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.md', which is part of this source code package.
 *
 * @author don michael
 * @since Jun 04, 2020 16:08
 */
import { v4 as uuidv4 } from 'uuid';

// -------------------------------------------------------------------
// common to all forms
// -------------------------------------------------------------------
export const DATA_LOADING_SET = 'DATA_LOADING_SET';
export function dataLoadingSet(value) {
    return {
        type: DATA_LOADING_SET,
        payload: value,
    };
}


export const HELP_DOC_VIEWER_OPEN_SET = 'HELP_DOC_VIEWER_OPEN_SET';
export function helpDocViewerOpenSet(value) {
    return {
        type: HELP_DOC_VIEWER_OPEN_SET,
        payload: value,
    };
}

export const PAGE_LOADING_SET = 'PAGE_LOADING_SET';
export function pageLoadingSet(value) {
    return {
        type: PAGE_LOADING_SET,
        payload: value,
    };
}

// -------------------------------------------------------------------
// snackbar notifications
// -------------------------------------------------------------------
export const DEQUEUE_MESSAGE = 'DEQUEUE_MESSAGE';
export function dequeueMessage(key) {
    return {
        type: DEQUEUE_MESSAGE,
        payload: key,
    };
}

export const ENQUEUE_MESSAGE = 'ENQUEUE_MESSAGE';
export function enqueueMessage(message, options) {
    const key = uuidv4();

    if (!options) {
        options = {};
    }

    return {
        type: ENQUEUE_MESSAGE,
        payload: {
            key,
            message,
            options,
        },
    };
}